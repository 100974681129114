import type { HTMLAttributes } from 'react';

export enum LabelArrangementOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export type SelectionControlProps = {
  /**
   * Unique identifier for the control.
   */
  id?: HTMLAttributes<HTMLInputElement>['id'];
  /**
   * Label to be displayed next to the control.
   */
  label: React.ReactElement | string;
  /**
   * Sublabel to be displayed label can be a string or a ReactNode.
   */
  subLabel?: React.ReactElement | string;
  /**
   * The right custom actionable element. Only available on web.
   */
  actionableElement?: React.ReactNode;
  /**
   * Determines whether the sublabel is displayed below (vertical) or beside (horizontal) the label.
   */
  variant?: `${LabelArrangementOrientation}`;
  /**
   * Function to be called when the value of the control changes.
   */
  onChange: HTMLAttributes<HTMLInputElement>['onChange'];
} & Pick<HTMLInputElement, 'name' | 'value' | 'checked'> &
  Partial<Pick<HTMLInputElement, 'disabled' | 'required'>>;
