import { AlertBanner, AlertStatus } from '@canalplus/dive';
import { castToEnum } from '@canalplus/mycanal-commons';
import { Binder, useActiveLayer } from '@canalplus/one-navigation';
import { ApiV2MeNotifications } from '@dce-front/hodor-types';
import { MouseEventHandler } from 'react';
import { LAYER_DIALOG_MODAL } from '../../helpers/oneNavigation/layers';
import I18n from '../../lang';
import styles from './AlertTv.css';

type AlertTvProps = {
  alert: NonNullable<ApiV2MeNotifications['alert']>;
  isOpen: boolean;
  handleClick: MouseEventHandler<HTMLElement>;
  handleClose: MouseEventHandler<HTMLElement>;
};
export default function AlertTv({
  alert,
  isOpen,
  handleClick,
  handleClose,
}: AlertTvProps): JSX.Element {
  const { t } = I18n.useTranslation();

  useActiveLayer(LAYER_DIALOG_MODAL, isOpen);

  const renderWrapperBinder = ({
    children,
    className,
  }: {
    children: React.ReactNode;
    className?: string;
  }): JSX.Element => (
    <Binder className={className} layer={LAYER_DIALOG_MODAL} forceFocusOnMount>
      {children}
    </Binder>
  );
  return (
    <AlertBanner
      overlayClassName={styles.alertBannerOverlay}
      childrenWrapper={renderWrapperBinder}
      isOpen={isOpen}
      status={castToEnum(AlertStatus)(alert.state)}
      title={alert.title}
      description={alert.description || ''}
      primaryButtonAriaLabel={alert.button?.ariaLabel}
      primaryButtonLabel={alert.button?.label || ''}
      secondaryButtonLabel={t('Alert.ignore')}
      secondaryButtonAriaLabel={t('Alert.ignore')}
      onPrimaryClick={handleClick}
      onSecondaryClick={handleClose}
    />
  );
}
