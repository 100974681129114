import {
  HeaderNavItem,
  HeaderUserDropdownListItem,
  HeaderUserDropdownProfileItem,
  MediaImage,
} from '@canalplus/dive';
import { IContentSections } from '@canalplus/mycanal-sdk';
import { Template } from '@canalplus/sdk-hodor';
import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import Linker from '../../components/Linker/Linker';
import { UseProfileSelector } from '../../components/Profiles/ProfileSelectorMyCanal/hooks/useProfileSelector';
import {
  NavigationItemOnClickTemporary,
  computeApiV2OnClickFromNavigationItemOnClick,
} from '../hodor/computeApiV2OnClickFromNavigationItemOnClick';

const getOnClickByPageId = (
  navigation: ApiV2NavigationItem[],
  id: string
): ApiV2NavigationItem | undefined => {
  const pageId = id.replace(/\//g, '');
  return navigation.find((nav) => {
    const path = nav.onClick?.path?.replace(/\//g, '');
    return path === pageId;
  });
};

export const getURLPageByPathname = (
  navigation: ApiV2NavigationItem[],
  pathname: string
): string | undefined => {
  const nav = getOnClickByPageId(navigation, pathname);
  return nav?.onClick?.URLPage;
};

type Link = React.ReactNode;
type LinksColumn = Link[];

export type ColumnContent = {
  links: LinksColumn[];
  title?: string;
};

const getLinkElement = (onClick?: NavigationItemOnClickTemporary): Link => {
  if (!onClick) {
    return null;
  }

  const { URLWebsite, displayTemplate, serviceID, path, displayName, BOName } =
    onClick;
  const externalSite = displayTemplate === Template.ExternalSite;
  const isBlank = serviceID !== 'internal';

  if (URLWebsite && externalSite) {
    return (
      <a href={URLWebsite} target={isBlank ? '_blank' : ''} rel="noreferrer">
        {displayName || BOName}
      </a>
    );
  }

  return (
    <Linker
      data={{
        mainOnClick: computeApiV2OnClickFromNavigationItemOnClick(onClick),
      }}
      href={path}
      title={displayName}
    >
      <span>{displayName || BOName}</span>
    </Linker>
  );
};

export const getColumns = (items: any[]): any[][] => {
  // create two sub-columns of links if there is more than 4 links
  if (items.length > 4) {
    const half = Math.ceil(items.length / 2);
    return [items.slice(0, half), items.slice(half, items.length)];
  }
  return [items];
};

export const getMainColumnContent = (
  tree: ApiV2NavigationItem[]
): ColumnContent[] => {
  if (tree[0]?.children) {
    return tree.map((leaf) => ({
      title: leaf.displayName,
      links: leaf.children?.length
        ? getColumns(leaf.children.map((link) => getLinkElement(link.onClick)))
        : [[]],
    }));
  }

  // Supports V4 data display. TODO: remove when V5 will be definitive
  const columns = getColumns(tree);
  return columns.map((col) => ({
    links: getColumns(col.map((link) => getLinkElement(link.onClick))),
  }));
};

/**
 * Convert ApiV2NavigationItem to Dive HeaderNavItem for the HeaderNav component
 * @param navigation Tree of navigation items (can be empty in prospect mode)
 * @returns new tree of HeaderNavItem
 * @example
 *  getHeaderNavItems([]) => [];
 *  getHeaderNavItems([{ displayName: 'Home', onClick: { path: '/' } }]) => [{ label: 'Home', wrapper: <Linker href="/" title="Home" ... /> }]
 */
export const getHeaderNavItems = (
  navigation: ApiV2NavigationItem[] = []
): HeaderNavItem[] => {
  return navigation.map(({ onClick, ...rest }): HeaderNavItem => {
    const { path, displayName: onClickDisplayName } = onClick || {};
    const label = rest?.displayName || onClickDisplayName || '';
    return {
      label,
      wrapper: (
        <Linker
          key={`${path || label}_headernavitem`}
          href={path}
          title={label}
          data-tv-focusmode="attribute"
          data={{
            ...(onClick && {
              mainOnClick:
                computeApiV2OnClickFromNavigationItemOnClick(onClick),
            }),
            ...rest,
          }}
        />
      ),
    };
  });
};

/**
 * Convert ApiV2Profile to Dive HeaderUserDropdownProfileItem for the HeaderUserDropdownProfiles component
 * @param profiles - List of profiles provided by Hodor
 * @param handleProfileClick - Callback to handle profile click
 * @returns new list of HeaderUserDropdownProfileItem
 * @example
 *   getHeaderUserMenuProfiles(hodorProfiles, true, () => {});
 */
export const getHeaderUserMenuProfiles = (
  profiles: ApiV2Profile[] = [],
  handleProfileClick?: UseProfileSelector['handleProfileClick']
): HeaderUserDropdownProfileItem[] => {
  return profiles.map((profile, index) => {
    const {
      displayName,
      onClick,
      URLImage,
      altImage,
      isKidsProfile,
      avatarId,
    } = profile || {};
    const isCurrent = index === 0;
    return {
      isCurrent,
      id: `${avatarId}`,
      isKids: isKidsProfile,
      label: displayName || onClick?.displayName || '',
      avatarImg: { url: URLImage || '', alt: altImage || '' },
      ...(handleProfileClick &&
        !isCurrent && {
          onClick: (event) => handleProfileClick(profile, event),
        }),
    };
  });
};

/**
 * Convert IContentSections to Dive HeaderUserDropdownListItem for the HeaderUserDropdownList component
 * @param contents - List of contents
 * @return new list of HeaderUserDropdownListItem
 * @example
 *   getHeaderProfileListItems([{ title: 'Settings', onClick: { ... }, URLImage: 'https://example.com/image.png' }]);
 */
export const getHeaderProfileListItems = (
  contents: IContentSections[] = []
): HeaderUserDropdownListItem[] => {
  return contents.map(({ title, onClick, URLImage }) => ({
    label: title || '',
    wrapper: (
      <Linker
        title={title}
        {...(onClick && { data: { mainOnClick: onClick } })}
      />
    ),
    icon: <MediaImage src={URLImage} alt={title} width="" height="" />,
  }));
};

/** Retrieve the index for the currently active item of the header navigation */
export const getHeaderActiveIndex = (
  rootUrl: string,
  path: string,
  navigation: ApiV2NavigationItem[] = []
): number =>
  navigation.findIndex((navItem) => {
    const navItemPath = navItem.onClick?.path;

    if (!navItemPath) {
      return false;
    }

    if (navItemPath === rootUrl) {
      // navItem points to the root url, so we check for string equality with the pagePathname (see explanation below)
      return navItemPath === path;
    }

    // The current pagePathname may be a sub-route of a navItem path. For example:
    // pagePathname = /cinema/comedy/
    // navItemPath = /cinema/
    // 👆 this should be a match, hence we use String.startsWith
    // (this doesn't work when navItemPath is the root url, because EVERY pagePathname starts with the root url, which is why we test for that case above)
    return path.startsWith(navItemPath);
  });
