import {
  isClientSide,
  removeCookie,
  setCookie,
} from '@canalplus/mycanal-commons';
import Cookies from 'js-cookie';
import { CookieKey } from '../../constants/cookie';
import { getPublicConfig } from '../../helpers/config/config-helper';

export function ABTestingCookiesHandler(
  abTestingPopulationValue?: number
): void {
  if (isClientSide()) {
    const { abTestingPercentage } = getPublicConfig().api.hodor;
    const abTestingPopulationCookies = Cookies.get(
      CookieKey.AbTestingPopulation
    );

    if (
      !abTestingPopulationCookies &&
      abTestingPercentage > 0 &&
      abTestingPopulationValue
    ) {
      setCookie(
        CookieKey.AbTestingPopulation,
        String(abTestingPopulationValue),
        {
          expires: 7,
          sameSite: 'Lax',
        }
      );
    }

    if (
      abTestingPopulationCookies &&
      parseInt(abTestingPopulationCookies, 10) &&
      !abTestingPercentage
    ) {
      removeCookie(CookieKey.AbTestingPopulation);
    }
  }
}
