import { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { NavigationItemOnClick as NavigationItemOnClickHodor } from '@dce-front/hodor-types/modules/account_profile/definitions';

/**
 * ⚠️ Temporary type
 * `serviceID` is missing in the `NavigationItemOnClick` Hodor typings
 *
 * Should be removed with ticket: https://gitlab.canalplus.pro/deddev/dedmycanalwebapp/-/issues/10985
 */
export type NavigationItemOnClickTemporary = NavigationItemOnClickHodor &
  Pick<ApiV2OnClick, 'serviceID' | 'perso' | 'isKids'>;

export const computeApiV2OnClickFromNavigationItemOnClick = ({
  displayLogo,
  ...rest
}: NavigationItemOnClickTemporary): ApiV2OnClick => rest;
