import { DidomiState } from './didomiTypes';
import { ActionType, DidomiAction } from './didomiActions';

const didomiReducer = (
  state: DidomiState,
  action: DidomiAction
): DidomiState => {
  switch (action.type) {
    case ActionType.HAS_DIDOMI_LOADED:
      return {
        ...state,
        hasLoaded: action.payload.hasLoaded,
      };
    case ActionType.HAS_DIDOMI_CONSENT_CHANGED:
      return {
        ...state,
        hasConsentChanged: action.payload.hasConsentChanged,
      };

    case ActionType.SET_DIDOMI_CONSENT:
      return {
        ...state,
        consent: action.payload.consent,
      };

    case ActionType.SET_DIDOMI_IS_IN_IFRAME:
      return {
        ...state,
        isInIframe: action.payload.isInIframe,
      };

    default:
      return state;
  }
};

export default didomiReducer;
