import { ParentalCodeExist } from 'r7extlib';

/**
 * mustEnableAdult
 *
 * this function check that the user has read the disclaimer,
 * and either has adult and not set the parental code,
 * or doesn't have adult in subscription
 * @param  {bool} adultInOffer   whether the user has adult in his subscription
 * @param  {bool} adultEnabled   whether the user has initialized adult code
 * @param  {bool} isInitialized
 * @returns {bool}
 */
export const mustEnableAdult = (
  adultInOffer: boolean,
  adultEnabled: boolean,
  isInitialized: boolean
): boolean => isInitialized && !(adultInOffer && adultEnabled);

/**
 * Open the parental code popin OneCore.
 * Return a promise with a boolean indicate if the parental code enter process is success or not
 * @param  {boolean} noDisclaimer indicate if oneCore display the disclaimer or not
 * @returns Promise<boolean>
 */
export const openOneCoreParentalCode = (
  noDisclaimer: boolean = true
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!window.R7) {
      reject(new Error('window.R7 is not defined'));
    }

    window.R7(
      'navigate',
      {
        control: 'parental_auth',
        context: { noDisclaimer },
      },
      (err, result) => {
        if (err) {
          return reject(err);
        }

        resolve(result.success);
      }
    );
  });
};

/**
 * Get information from oneCore about code parental status (exist and locked)
 * Return a promise with a boolean indicate if the parental code enter process is success or not
 * @returns Promise<ParentalCodeExist> object ParentalCodeExist
 */
export const getOneCoreParentalCodeExist = (): Promise<ParentalCodeExist> => {
  return new Promise((resolve, reject) => {
    if (!window.R7) {
      reject(new Error('window.R7 is not defined'));
    }

    window.R7('parentalCodeExist', (err, data) => {
      if (err && err.message === 'Method not found') {
        // by default consider exist
        return resolve({ exists: true, locked: false });
      }
      if (err) {
        return reject(err);
      }

      resolve(data);
    });
  });
};
