import { DisplayMode, isClientSide } from '@canalplus/mycanal-commons';
import { IUserInformation } from '@canalplus/mycanal-sdk';
import { StickyContainer } from '@canalplus/mycanal-sharedcomponent';
import { DidomiSDK } from '@didomi/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { ResizeViewportMapping } from '../../constants/resize';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { isDetailV5DisplayTemplateInSSRSelector } from '../../selectors/detailV5-selectors';
import {
  isCreativeMediaTemplateSelector,
  isHeaderActiveOnTVSelector,
} from '../../selectors/header-selectors';
import {
  applicationResizeSelector,
  didomiNoticeIdSelector,
  featDisplayHeaderMenuSelector,
  getFeatureToggleDidomi,
  hasNavigationSelector,
  isFooterHiddenSelector,
  isHeaderHiddenSelector,
} from '../../store/slices/application-selectors';
import { isFeatImmersiveFullAllowedSelector } from '../../store/slices/contextFeature-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { hasCoverSelector } from '../../store/slices/page-selectors';
import {
  isSearchActiveSelector,
  searchQuerySelector,
} from '../../store/slices/search-selectors';
import { displayModeSelector } from '../../store/slices/ui-selectors';
import LoadableSearch from '../../templates/Search';
import SlideshowNotificationsContainer from '../../templates/Slideshow/components/SlideshowNotificationsContainer';
import AlertNotificationContainer from '../Alert/AlertNotificationContainer';
import {
  hasDidomiConsentChanged,
  hasDidomiLoaded,
  setDidomiConsent,
} from '../DidomiProvider/didomiActions';
import { useDidomiDispatch } from '../DidomiProvider/didomiHooks';
import { FooterContainerConnected } from '../HeaderFooter/FooterContainerConnected';
import HeaderOneDiscovery from '../HeaderOneDiscovery/HeaderOneDiscovery';
import { MetaTagsConnected } from '../MetaTags/MetaTagsConnected';
import { ModalContainerConnected } from '../Modal/ModalContainerConnected';
import { NotificationDisplayerConnected } from '../NotificationDisplayer/NotificationDisplayerConnected';
import { RoutingContextProvider } from '../Page/RoutingContext';
import Routes from '../Routes/Routes';
import { SKIP_LINK_ID } from '../SkipLink/constants';
import SkipLink from '../SkipLink/SkipLink';
import { StickyBarConnected } from '../StickyBar/StickyBarConnected';
import { MycanalVisualDebuggerWrapper } from '../VisualDebugger/MycanalVisualDebuggerWrapper';
import WhoIsWatching from '../WhoIsWatching/WhoIsWatching';
import styles from './Application.css';
import { GdprModal } from './GdprModal';
import { useAbTestingCookiesHandler } from './hooks/useAbTestingCookiesHandler';
import { useAcmCinematic } from './hooks/useAcmCinematic';
import { useDidomi } from './hooks/useDidomi';
import { useHandleUnmountSearch } from './hooks/useHandleUnmountSearch';
import { useHasFocus } from './hooks/useHasFocus';
import { useIframeBehavior } from './hooks/useIframeBehavior';
import { useInitProfiles } from './hooks/useInitProfiles';
import { useLoadTrackingScripts } from './hooks/useLoadTrackingScripts';
import { usePerformanceObserver } from './hooks/usePerformanceObserver';
import { usePlayerScript } from './hooks/usePlayerScript';
import { usePostRenderCinematic } from './hooks/usePostRenderCinematic';
import { useR7ExtLib } from './hooks/useR7ExtLib';
import { useRenderSource } from './hooks/useRenderSource';
import { useSendNewrelicGlobalInfo } from './hooks/useSendNewrelicGlobalInfo';
import { useSetTargetedAds } from './hooks/useSetTargetedAds';
import { useStartupNotification } from './hooks/useStartupNotification';
import { useUserChangeSettings } from './hooks/useUserChangeSettings';
import { useWhoIsWatching } from './hooks/useWhoIsWatching';
import { useWindowResizeListener } from './hooks/useWindowResizeListener';
import { SkipLinkToggleAutoplay } from './SkipLinkToggleAutoplay';

export type ApplicationProps = {
  userInfos?: IUserInformation;
};

export function Application({ userInfos }: ApplicationProps): JSX.Element {
  const displayMode = useSelector(displayModeSelector);
  const featDisplayHeaderMenu = useSelector(featDisplayHeaderMenuSelector);
  const noticeId = useSelector(didomiNoticeIdSelector);
  const hasCover = useSelector(hasCoverSelector);
  const hasNavigation = useSelector(hasNavigationSelector);
  const isDetailV5DisplayTemplateInSSR = useSelector(
    isDetailV5DisplayTemplateInSSRSelector
  );
  const isCreativeMediaTemplate = useSelector(isCreativeMediaTemplateSelector);
  const isHeaderActiveOnTV = useSelector(isHeaderActiveOnTVSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const resizeMode = useSelector(applicationResizeSelector);
  const searchQuery = useSelector(searchQuerySelector);
  const isSearchActive =
    useSelector(isSearchActiveSelector) && Boolean(searchQuery);
  const isFeatImmersiveFullAllowed = useSelector(
    isFeatImmersiveFullAllowedSelector
  );
  const isFooterHidden = useSelector(isFooterHiddenSelector);
  const isFeatDidomi = useSelector(getFeatureToggleDidomi);
  const dataViewport = resizeMode && ResizeViewportMapping[resizeMode];
  const { showWhoIsWatching } = useWhoIsWatching();

  const isHeaderHidden =
    useSelector(isHeaderHiddenSelector) ||
    (displayMode === DisplayMode.FULLWINDOWED && !isTvDevice);

  const isHeaderNotActiveOnTV =
    (!featDisplayHeaderMenu || !isHeaderActiveOnTV) && isTvDevice;

  const { reactQueryDevtools } = getPublicConfig().debug;

  // Handle a11y focus on keydown events only (Web only, not TV)
  useHasFocus(isTvDevice);

  // Handle offset of application content wrapper
  // because of header has a changing style
  // or some pages is displaying above it (e.g. immersive or full-windowed player).
  const applicationClassNames = classNames(styles.application, {
    [styles['application--resetNav']]: !hasNavigation,
    [styles['application--noHeader']]:
      showWhoIsWatching || isHeaderHidden || isHeaderNotActiveOnTV,
    [styles['application--cover']]:
      hasCover || isDetailV5DisplayTemplateInSSR || isCreativeMediaTemplate,
  });

  const handleUnmountSearch = useHandleUnmountSearch();
  const isTrackingScriptLoaded = useLoadTrackingScripts();
  const didomiDispatch = useDidomiDispatch();
  const { DIDOMI } = getPublicConfig();

  usePostRenderCinematic({ userInfos });
  usePlayerScript();
  useAcmCinematic();
  useStartupNotification();
  useUserChangeSettings();
  useIframeBehavior();
  useSendNewrelicGlobalInfo();
  useWindowResizeListener();
  useAbTestingCookiesHandler();
  useDidomi(isTrackingScriptLoaded, isFeatDidomi);

  // TODO: delete this hook when didomi is fully integrated
  useSetTargetedAds(isTrackingScriptLoaded, !isFeatDidomi);

  useR7ExtLib();
  useRenderSource();
  usePerformanceObserver();
  useInitProfiles();

  return (
    <>
      <Helmet
        htmlAttributes={
          dataViewport
            ? {
                'data-viewport': dataViewport,
              }
            : {}
        }
      />
      <div id="application-container" className={applicationClassNames}>
        {isClientSide() && !isTvDevice && DIDOMI?.ACTIVE && isFeatDidomi ? (
          <DidomiSDK
            apiKey={DIDOMI.API_PUBLIC_KEY}
            noticeId={noticeId}
            onReady={() => didomiDispatch(hasDidomiLoaded(true))}
            onConsentChanged={() => {
              didomiDispatch(hasDidomiConsentChanged(true));
              didomiDispatch(
                setDidomiConsent(window.Didomi.getCurrentUserStatus())
              );
            }}
          />
        ) : (
          <GdprModal />
        )}

        {isTvDevice ? (
          <MycanalVisualDebuggerWrapper />
        ) : (
          !showWhoIsWatching && (
            <>
              <SkipLinkToggleAutoplay />
              <SkipLink
                href={`#${SKIP_LINK_ID.appMainContent}`}
                label="skipToMainContent"
                className={styles['application--skip-link']}
              />
              <SkipLink
                href={`#${SKIP_LINK_ID.appHeaderSearch}`}
                label="skipToSearch"
                className={styles['application--skip-link']}
              />
            </>
          )
        )}

        {showWhoIsWatching ? (
          <WhoIsWatching />
        ) : (
          <>
            <StickyContainer isFullHeight={isSearchActive && !isTvDevice}>
              {!isHeaderHidden && (
                <>
                  <NotificationDisplayerConnected />
                  <AlertNotificationContainer />
                  <div
                    className={classNames({
                      [styles['application--hideHeader']]:
                        isHeaderNotActiveOnTV,
                    })}
                  >
                    <HeaderOneDiscovery />
                  </div>
                </>
              )}
              {isSearchActive && !isTvDevice && (
                <RoutingContextProvider routingContext="search">
                  <LoadableSearch
                    from="root"
                    handleUnmount={() => handleUnmountSearch}
                  />
                </RoutingContextProvider>
              )}
              {!isSearchActive && !isTvDevice && <StickyBarConnected />}
            </StickyContainer>

            <div
              id={SKIP_LINK_ID.appMainContent}
              className={classNames(styles.application__mainContent, {
                [styles['application__mainContent--immersiveFull']]:
                  isFeatImmersiveFullAllowed,
                [styles['application__mainContent--with-skip-link']]:
                  !isTvDevice,
              })}
            >
              <Routes />
            </div>

            {!isFooterHidden && <FooterContainerConnected />}
          </>
        )}

        <RoutingContextProvider routingContext="slideshowNotifications">
          <SlideshowNotificationsContainer />
        </RoutingContextProvider>
        <ModalContainerConnected from="root" />

        {reactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
        <MetaTagsConnected />
      </div>
    </>
  );
}
