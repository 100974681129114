import {
  HodorSdkConfig,
  getHodorApiParametersFromBranSdkOnClickParameters,
  requestPage,
} from '@canalplus/sdk-hodor';
import { PERSO_HODOR_API_PARAMETERS } from '../constants/hodor';
import { TEMPLATES_PERSO } from './constants';
import type { ApiHeaders, FetchDetailsWithHodorSdkConfig } from './types';

export const requestHodorService = async <T>(
  url: string,
  fetchDetails: Omit<FetchDetailsWithHodorSdkConfig, 'from'>,
  addHeaders?: ApiHeaders
): Promise<T | undefined> => {
  const { hodorSdkConfig, template, onClickParameters } = fetchDetails;
  const hodorApiParameters =
    getHodorApiParametersFromBranSdkOnClickParameters(onClickParameters);

  const hodorApiParametersWithPerso =
    fetchDetails.options?.isPerso || TEMPLATES_PERSO.includes(template)
      ? [...hodorApiParameters, ...PERSO_HODOR_API_PARAMETERS]
      : hodorApiParameters;

  // When a header tokenPass is passed via `addHeaders`, we override the token in configuration.
  // This is used when requestHodorService() is called within apiCallWithTokenpass()
  const updatedHodorSdkConfig: HodorSdkConfig = {
    ...hodorSdkConfig,
    passToken: addHeaders?.tokenPass || hodorSdkConfig.passToken,
  };

  const response = await requestPage(updatedHodorSdkConfig, {
    url,
    parameters: hodorApiParametersWithPerso,
  });

  return response as T;
};
