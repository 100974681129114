import { exitApp, updateUserData } from '@canalplus/ifc-onecore';
import { isClientSide } from '@canalplus/mycanal-commons';
import { updateUserConsent } from '@canalplus/sdk-pass';
import { SERVER_ERROR } from '../../constants/errors';
import { ModalTypes } from '../../constants/modalTypes';
import Logger from '../../helpers/logger/logger-helper';
import { getPassSdkConfig } from '../../helpers/pass/getPassSdkConfig';
import { getClientSideUserInfos } from '../../helpers/user/getClientSideUserInfos';
import { waitForPassTokenAsync } from '../../helpers/waitForPassToken/waitForPassToken-helper';
import { errorSettingsUpdate } from './error';
import { closeModal } from './modal';
import { getPassToken, userChangeSettings } from './user';
import { hasUserDataCollectedSelector } from './user-selectors';
import type { UserSettingsState } from './user-type';

export const userConfirmSettings =
  (
    settings: UserSettingsState,
    modalType?: ModalTypes,
    modal = false
  ): Redux.ThunkAction<Promise<boolean>> =>
  async (dispatch, getState) => {
    const state = getState();
    const hasUserDataCollected = hasUserDataCollectedSelector(state);
    let isSuccess;

    /** Call API (by service 'updateProfile'), only if hasUserDataCollected was changed */
    if (
      Object.prototype.hasOwnProperty.call(settings, 'hasUserDataCollected') &&
      hasUserDataCollected !== settings.hasUserDataCollected &&
      isClientSide()
    ) {
      const passToken = await waitForPassTokenAsync();

      try {
        await updateUserConsent(getPassSdkConfig({ state }), {
          passToken,
          settings: {
            reco: settings.hasUserDataCollected,
          },
        });

        isSuccess = true;
      } catch (e) {
        isSuccess = false;
        Logger.error(
          `Pass::updateProfile Error while updating user profile: ${e}`
        );
      }
    }

    if (isSuccess === false) {
      /** Trigger error if service 'updateProfile' return false */
      dispatch(errorSettingsUpdate(SERVER_ERROR));
    } else {
      /** Make another createToken call to update the user's token with their new settings */
      try {
        const userInfo = await getClientSideUserInfos(state, {
          noCache: true,
        });

        dispatch(getPassToken(userInfo.passToken));
      } catch (e) {
        Logger.error(`Error occurs during getClientSideUserInfos: ${e}`);
      }

      /** If API call return true or if there isn't call, update new settings in store */
      dispatch(userChangeSettings(settings));
    }

    /** Required to update the token in Africa */
    if (isSuccess && !modal) {
      // Need setTimeout for the redux state to be updated when event 'beforeunload' is dispatched (see useWhoIswatching hook))
      setTimeout(() => {
        window.location.reload();
      });
    }

    /** Close modal if trigger by Confirmation modal */
    if (modal) {
      dispatch(closeModal(modalType));
    }

    return isSuccess;
  };

export const updateRecoPersoTV =
  (settings: UserSettingsState): Redux.ThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      await updateUserData({
        reco: settings.hasUserDataCollected,
      });
    } catch (error) {
      if (error) {
        Logger.error(`Error occurs during updateUserData: ${error}`);
        dispatch(errorSettingsUpdate(SERVER_ERROR));

        return;
      }
    }

    /** Reload oneDiscovery to apply modification */
    exitApp();
  };
