import { AVAILABLE_A11Y_KEYCODES } from '@canalplus/mycanal-commons';
import { useEffect, useState } from 'react';

/**
 * Attach listeners to handle a11y focus (Only for non TV devices)\
 * On non TV devices, the a11y focus is only active if user is navigating with the keyboard.\
 * It adds a noA11yFocus class to the body when the app has no a11y focus.
 *
 * @param isTvDevice Indicate if the device is a TV
 * @returns True if the app has focus
 * To be used on mount only
 */
export const useHasFocus = (isTvDevice: boolean): boolean => {
  const [hasFocus, setHasFocus] = useState(false);

  /**
   * Handle focus state, by listening to keydown and mousedown events.
   * It's a workaround to handle focus state on non TV devices. Every time
   * a listener callback is called, it disables itself and enables the other listener.
   * (Toggle between keydown and mousedown listeners distinctively)
   * E.g:
   *  - If a keydown event is triggered:
   *    - it disables the keydown listener and enables the mousedown listener.
   *  - If a mousedown event is triggered:
   *    - it disables the mousedown listener and enables the keydown listener.
   * :warning: only on mount
   */
  useEffect(() => {
    // Do nothing if it's a TV device
    if (isTvDevice) return;

    const listenKeydown = (event: KeyboardEvent) => {
      if (!AVAILABLE_A11Y_KEYCODES.includes(event.keyCode)) {
        return;
      }

      window.removeEventListener('keydown', listenKeydown);
      window.addEventListener('mousedown', listenMousedown);

      setHasFocus(true);
    };

    const listenMousedown = () => {
      window.removeEventListener('mousedown', listenMousedown);
      window.addEventListener('keydown', listenKeydown);

      setHasFocus(false);
    };

    // Start listener loop, by listening to keydown events first.
    // keydown -> mousedown -> keydown -> mousedown -> ...
    window.addEventListener('keydown', listenKeydown);

    return () => {
      window.removeEventListener('keydown', listenKeydown);
      window.removeEventListener('mousedown', listenMousedown);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Add a noA11yFocus class to the body when the app has no a11y focus.
   */
  useEffect(() => {
    document.body.classList.toggle('noA11yFocus', !hasFocus);
  }, [hasFocus]);

  return hasFocus;
};
