import classNames from 'classnames';
import { cloneElement, forwardRef } from 'react';
import { useDiveContext } from '../../../context';
import { ButtonLogoAsVariant } from '../ButtonLogo/ButtonLogo.types';
import {
  buttonIconCVA,
  buttonIconLabelCVA,
  buttonIconSvgCVA,
  buttonIconWrapperCVA,
} from './ButtonIcon.cva';
import {
  ButtonIconColorVariant,
  ButtonIconProps,
  ButtonIconVariant,
} from './ButtonIcon.types';

/**
 * A ButtonIcon is a clickable Icon used to perform an action.
 * It contains an icon and an optional label.
 *
 * ButtonIcon variants address the following use cases:
 * - `default`: common variant, used on myCanal's Detail Page
 * - `video`: used in relation to a video asset
 * - `header`: used in the context of a Header component (e.g.: Header's Search button Web and TV)
 * - `media-overlay`: used in the context of a MediaOverlay component
 * - `media-overlay-pvr`: used in the context of a MediaOverlay component on PVR (Personal Video Recorder) enabled devices only, e.g.: STB GLine
 *
 * @example
 * <ButtonIcon icon={<PlayIcon />} onClick={onClick} />
 */
export const ButtonIcon = forwardRef<
  HTMLButtonElement | HTMLDivElement,
  ButtonIconProps
>(function ButtonIcon(
  {
    icon,
    className,
    onClick,
    label,
    disabled,
    isSelected = false,
    iconColor = ButtonIconColorVariant.Neutral,
    variant = ButtonIconVariant.Default,
    as: Wrapper = ButtonLogoAsVariant.Button,
    'aria-label': ariaLabel,
    ...rest
  },
  ref
): JSX.Element | null {
  const { device } = useDiveContext();
  const isDisabled = !!disabled;
  const isButton = Wrapper === 'button';

  const buttonIconWrapperStyles = classNames(
    buttonIconWrapperCVA({
      variant,
      device,
      isDisabled: isDisabled || Wrapper === 'div',
      hasLabel: !!label,
    }),
    className
  );

  const buttonIconContent = (
    <>
      <div
        className={buttonIconCVA({ variant, device, isDisabled, isSelected })}
        {...(!ariaLabel && isButton && { 'aria-hidden': true })}
      >
        {cloneElement(icon, {
          className: buttonIconSvgCVA({
            variant,
            device,
            iconColor,
            isSelected,
          }),
          role: 'img',
          'aria-label': ariaLabel,
          'data-testid': icon.props['data-testid'],
        })}
      </div>
      {label && (
        <span className={buttonIconLabelCVA({ variant, device, isDisabled })}>
          {label}
        </span>
      )}
    </>
  );

  if (isButton) {
    return (
      <button
        {...rest}
        type="button"
        disabled={disabled}
        onClick={onClick}
        ref={ref as React.Ref<HTMLButtonElement>}
        className={buttonIconWrapperStyles}
      >
        {buttonIconContent}
      </button>
    );
  }

  return (
    <div
      {...rest}
      ref={ref as React.Ref<HTMLDivElement>}
      className={buttonIconWrapperStyles}
      {...(!label && !ariaLabel && { 'aria-hidden': true })}
    >
      {buttonIconContent}
    </div>
  );
});
