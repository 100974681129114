import { useEffect, useState } from 'react';
import { DEFAULT_PORTAL_ELEMENT_ID } from './ModalV2';

type UseIsModalV2OpenProps = {
  portalElementId?: string;
  enabled?: boolean;
};

/**
 * Custom React Hook that allow us to know if the Modal V2 is opened or closed
 * We use MutationObserver to observe the HTML element related to ModalV2 (mainly "default-portal")
 * If there is some children inside div, it means that the modal is opened
 * If the div is empty, the modal is closed
 * @param portalElementId
 * @param enabled user can disable the feature (for TV for example)
 * @returns boolean
 */

export const useIsModalV2Open = ({
  portalElementId = DEFAULT_PORTAL_ELEMENT_ID,
  enabled = true,
}: UseIsModalV2OpenProps = {}): boolean => {
  const [isModalV2Open, setIsModalV2Open] = useState(false);

  useEffect(() => {
    const portalModalV2 = document.getElementById(portalElementId);
    if (!enabled || !portalModalV2) {
      return;
    }

    const observer = new MutationObserver((e) => {
      const modalV2El = e[0].target as HTMLElement;

      if (!modalV2El) {
        return;
      }

      const hasModalV2Content = !!modalV2El.innerHTML;

      if (hasModalV2Content) {
        setIsModalV2Open(true);
      } else {
        setIsModalV2Open(false);
      }
    });

    observer.observe(portalModalV2, {
      attributes: false,
      childList: true,
      subtree: false,
    });

    return () => {
      observer.disconnect();
    };
  }, [portalElementId, enabled]);

  // if not enabled, return false
  if (!enabled) {
    return false;
  }

  return isModalV2Open;
};
