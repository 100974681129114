// TODO WAITING FOR HODOR TYPINGS
export type MockApiV2UserMenuLanguages = {
  _functionalTestsIgnored: boolean;
  currentPage: {
    _functionalTestsIgnored: boolean;
    path: string;
    displayTemplate: string;
  };
  languages: {
    language: string;
    languagePath: string;
    languageLabel: string;
    offerZone: string;
    locale: string;
  }[][];
};

/** Hodor `ApiV2NavigationItem` type dictionary */
export const NavigationItemType = {
  CurrentProfile: 'currentProfile',
  Text: 'text',
} as const;
