import { ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import { ApiV2AvatarsV2Strate } from '@dce-front/hodor-types/api/v2/avatars/v2/definitions';
import { useSelector } from 'react-redux';
import { getFeatureToggleKidsProfiles } from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import {
  avatarSelector,
  isKidsProfileSettingsSelector,
  profileInputSelector,
} from '../../../store/slices/profilesModal-selectors';
import ProfileForm from './ProfileForm';

export type ProfileCreationProps = {
  avatars: ApiV2AvatarV1Content[] | ApiV2AvatarsV2Strate[];
  setOpenModal: (isModalOpen: boolean) => void;
};

function ProfileCreation({
  setOpenModal,
  avatars,
}: ProfileCreationProps): JSX.Element {
  const avatarURL = useSelector(avatarSelector);
  const featKidsProfiles = useSelector(getFeatureToggleKidsProfiles);
  const isKidsProfileSettings = useSelector(isKidsProfileSettingsSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const profileInput = useSelector(profileInputSelector);

  return (
    <ProfileForm
      avatarURL={avatarURL}
      avatars={avatars}
      featKidsProfiles={featKidsProfiles}
      isKidsProfileSettings={isKidsProfileSettings}
      isTvDevice={isTvDevice}
      profileInput={profileInput}
      setOpenModal={setOpenModal}
    />
  );
}

export default ProfileCreation;
