import { OfferLocation, Platform } from '@canalplus/sdk-core';
import { Template } from '@canalplus/sdk-hodor';
import { LocationDescriptorObject } from 'history';
import { MouseEvent, SyntheticEvent } from 'react';
import { TemplateTypes } from '../../../../constants/templateTypes';
import { clientSideOneShopRedirection } from '../../../../helpers/user/user-helper';
import { handleLaunchExternalService } from '../../../../store/reducers/actions/ExternalServices-actions';
import type { AppHistory, LocationState } from '../../../../typings/routing';
import { LinkerProps } from '../../types';

/**
 * Dispatch the good actions for TEMPLATE.EXTERNAL_SITE
 * @param e event click
 * @param linkerProps
 * @param dispatch
 * @param isTvDevice
 * @param platform
 * @param offerLocation
 */

const onClickTemplateExternal = ({
  e,
  isTvDevice,
  linkerProps,
  dispatch,
  platform,
  offerLocation,
}: {
  e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent;
  isTvDevice: boolean;
  linkerProps: LinkerProps;
  dispatch: Redux.Dispatch;
  platform: Platform;
  offerLocation: OfferLocation;
}): void => {
  const { data, target = '_blank' } = linkerProps;
  const { contentID, mainOnClick: onClickHodor, trackingContext } = data || {};
  const {
    serviceID,
    URLPage,
    URLWebsite,
    target: onClickTarget,
  } = onClickHodor || {};

  e.preventDefault();

  dispatch(
    handleLaunchExternalService({
      // We should always use the contentID given in the onClick for deeplinks, the contentID on content is technical
      contentID: isTvDevice ? onClickHodor?.contentID : contentID,
      serviceID,
      URLPage,
      URLWebsite,
      trackingContext: trackingContext || onClickHodor?.trackingContext,
      target: onClickTarget || target,
      platform,
      offerLocation,
    })
  );
};

/**
 * Generate the function onClick to execute for linker data `linkerProps`
 * @param linkerProps
 * @param dispatch
 * @param isTvDevice
 * @param isNextEpisodeAutoplay
 * @param offerZone
 * @param offerLocation
 * @param platform
 * @returns Function onClick to execute for linker data `linkerProps`
 */
export const getOnClickGenerated =
  (
    linkerProps: LinkerProps,
    dispatch: Redux.Dispatch,
    isTvDevice: boolean,
    offerLocation: OfferLocation,
    platform: Platform
  ) =>
  (
    e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | SyntheticEvent
  ): void => {
    const { data, onClick } = linkerProps;
    const { mainOnClick: onClickHodor } = data || {};

    const { displayTemplate } = onClickHodor || {};

    if (!onClickHodor && !onClick) {
      return e.preventDefault();
    }

    if (onClick) {
      onClick(e);
    }

    if (displayTemplate === Template.ExternalSite) {
      onClickTemplateExternal({
        e,
        isTvDevice,
        linkerProps,
        dispatch,
        platform,
        offerLocation,
      });
      return;
    }

    if (displayTemplate === TemplateTypes.LAUNCH_ONE_SHOP) {
      clientSideOneShopRedirection(onClickHodor?.contentID);
      return;
    }
  };

/**
 * Navigate to a link using router
 * @param history Router History
 * @param to LocationDescriptorObject information of the target to navigate to
 * @param replace Indicate if replace or push in history
 */
export const redirectRouter = (
  history: AppHistory,
  to: LocationDescriptorObject<LocationState>,
  replace?: boolean
): void => {
  /* @todo use redirect from "react-router-dom" when we bump to react-router v6 */
  if (replace) {
    history.replace({ pathname: to.pathname, search: to.search }, to.state);
  } else {
    history.push({ pathname: to.pathname, search: to.search }, to.state);
  }
};
