import { fillTemplate } from '@canalplus/mycanal-commons';
import {
  EN_FOOTER_LABELS,
  FR_FOOTER_LABELS,
  FooterLabels,
  IT_FOOTER_LABELS,
  PL_FOOTER_LABELS,
} from '@canalplus/mycanal-sharedcomponent';

const getLabelsFromLang = (lang: string) => {
  switch (lang) {
    case 'pl':
      return PL_FOOTER_LABELS;

    case 'it':
      return IT_FOOTER_LABELS;

    case 'en':
      return EN_FOOTER_LABELS;

    case 'fr':
    default:
      return FR_FOOTER_LABELS;
  }
};

export const getFilledLabels = (
  lang: string,
  footerDescriptionTitle?: string,
  footerDescription?: string
): FooterLabels => {
  const labels = getLabelsFromLang(lang);

  return {
    ...labels,
    ...(footerDescriptionTitle && { title: footerDescriptionTitle }),
    ...(footerDescription && { content: footerDescription }),
    credits: fillTemplate(labels.credits, [
      ['year', new Date().getFullYear().toString()],
    ]),
  };
};
