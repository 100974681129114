import type { OneCoreKeyName } from '@canalplus/ifc-onecore';
import { Store } from '@canalplus/one-navigation';
import { debounce } from 'es-toolkit';
import { log } from '../../components/VisualDebugger/html-logger';
import { BINDER_DEFAULT_SELECTOR, POINTER_REFOCUS_DELAY } from './constants';

let pointerVisible = false;
let storeGetter: () => null | Store = () => null;

export function setPointerNotVisible(origin: string): void {
  if (!pointerVisible) {
    return;
  }

  log({
    level: 'info',
    tags: ['pointer'],
    message: `pointerHandler: disabled origin="${origin}"`,
  });
  pointerVisible = false;
}

export function initializeStore(inputStore: () => any): void {
  storeGetter = inputStore;
}

export function isPointerVisible(): boolean {
  return pointerVisible;
}

function setPointerVisible(origin: string): void {
  if (pointerVisible) {
    return;
  }

  log({
    level: 'info',
    tags: ['pointer'],
    message: `pointerHandler: enabled origin="${origin}"`,
  });
  pointerVisible = true;
}

function handleRefocus(event: MouseEvent): void {
  const store = storeGetter();

  if (
    !pointerVisible ||
    !store ||
    !(event.target instanceof HTMLElement || event.target instanceof SVGElement)
  ) {
    return;
  }

  // Find the focusable element related to what was clicked
  const closestFocusable = event.target.closest(BINDER_DEFAULT_SELECTOR);
  if (!closestFocusable || !(closestFocusable instanceof HTMLElement)) {
    return;
  }

  // Find the binder related to what was clicked
  const closestBinder = closestFocusable.closest('[data-binder]');
  if (!closestBinder) {
    return;
  }

  // Find the related binder instance in one-navigation store
  const layer = store.getActiveLayer();
  const binder = layer
    .getEnabledBinders()
    .find(({ el }) => el === closestBinder);

  if (!binder) {
    return;
  }

  // Check that focusable candidate is within binder elements
  const isFocusableWithin = binder.getElements().indexOf(closestFocusable) > -1;
  // Reset the binder dirty flag in case some async stuff loads before next click
  binder.dirty = true;

  if (isFocusableWithin) {
    layer.focus(binder, closestFocusable);
  }
}

if (typeof window !== 'undefined') {
  // LG Pointer documentation : https://webostv.developer.lge.com/develop/guides/system-ui-visibility#visibility-of-the-cursor

  // webOS 2.x +
  document.addEventListener(
    'cursorStateChange',
    (event: CustomEvent<{ visibility: boolean }>) => {
      if (event.detail.visibility) {
        setPointerVisible('cursorStateChange (webOS 2.x +)');
      } else {
        setPointerNotVisible('cursorStateChange (webOS 2.x +)');
      }
    }
  );

  // webOS 1.x
  // See documentation above for the magic numbers
  document.addEventListener('keydown', (event) => {
    switch (event.keyCode) {
      // Pointer visible event
      case 1536:
        setPointerVisible('keydown (webOS 1.x)');
        break;
      // Pointer not visible event
      case 1537:
        setPointerNotVisible('keydown (webOS 1.x)');
        break;
      default:
        break;
    }
  });

  // non-webOS devices (like emulator on regular browser)
  document.addEventListener('mousemove', () =>
    setPointerVisible('mousemove (non webOS)')
  );
  document.addEventListener('pointermove', () =>
    setPointerVisible('pointermove (non webOS)')
  );

  window.addEventListener(
    'r7:grabKey',
    (event: CustomEvent<{ key: OneCoreKeyName }>) => {
      if (['Down', 'Up', 'Left', 'Right'].includes(event.detail.key)) {
        setPointerNotVisible(`R7 grabKey ${event.detail.key}`);
      }
    }
  );

  // Catch click events to update universal keys state
  document.addEventListener(
    'mouseenter',
    debounce(handleRefocus, POINTER_REFOCUS_DELAY),
    // This is required to catch click events with preventDefault() or stopPropagation() calls
    // see: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#parameters
    // capture is not available with chrome<52 and safari<10. Lowest LG devices supported use
    // chrome 53 so we should be safe here.
    { capture: true }
  );
}
