import {
  addStreamListener,
  getContext,
  manageUserKeyPress,
} from '@canalplus/ifc-onecore';
import { ARROW_KEYCODES } from '@canalplus/mycanal-commons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setPointerNotVisible } from '../../../helpers/binder/pointer';
import { useAppHistory } from '../../../helpers/hooks/reactRouter';
import { parseOneCoreDeeplinkPath } from '../../../helpers/r7/r7-helpers';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';

/** To be used on mount only */
// @todo #12734 rename `useR7ExtLib` to `useIfcExtLib` when all methods ifc are implemented
export const useR7ExtLib = (): void => {
  const history = useAppHistory();
  const isTvDevice = useSelector(displayTVModeSelector);

  useEffect(() => {
    if (isTvDevice) {
      /**
       * In normal case we do not enter here, however sometimes focus will be set directly within our iframe.\
       * One-core is not available to catch key events and grabKey() is not invoked
       */
      const listenKeyup = ({ keyCode }: KeyboardEvent) => {
        if (ARROW_KEYCODES.includes(keyCode)) {
          return;
        }

        manageUserKeyPress({ keyCode, withoutBlur: true }).catch(console.error);
      };

      const listenKeydown = (event: KeyboardEvent) => {
        if (!ARROW_KEYCODES.includes(event.keyCode)) {
          return;
        }

        // This prevents unwanted behavior on arrow key press such as scroll
        event.preventDefault();

        // Immediately set cursor not visible since in this case we invoke universal keys
        // and we need scroll behavior
        setPointerNotVisible('keydown (Application.jsx fallback)');

        // Needs to be called otherwise grabKey() callbacks will never be called again
        manageUserKeyPress({
          keyCode: event.keyCode,
          withoutBlur: true,
        }).catch(console.error);
      };

      window.addEventListener('keyup', listenKeyup);
      window.addEventListener('keydown', listenKeydown);

      // If oneCore refocus on the application with a deeplink, we need to redirect the user
      addStreamListener('application:focus', async () => {
        try {
          const { deeplink } = await getContext();

          const deeplinkPath = parseOneCoreDeeplinkPath(deeplink?.path);
          if (deeplinkPath && history.location.pathname !== deeplinkPath) {
            history.push(deeplinkPath);
          }
        } catch (onecoreError) {
          console.error(onecoreError);
        }
      }).catch(console.error);

      return () => {
        window.removeEventListener('keyup', listenKeyup);
        window.removeEventListener('keydown', listenKeydown);
      };
    }

    return undefined;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
