import { isClientSide } from '@canalplus/mycanal-commons';
import { ModalV2 } from '@canalplus/mycanal-sharedcomponent';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPrivacyManagerUrl } from '../../helpers/url/getPrivacyManagerUrl';
import {
  appKeySelector,
  shouldShowGDPRSelector,
  tokenCMSSelector,
} from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import LoadableGDPRConsent from '../GDPR/GDPRConsent/LoadableGDPRConsent';
import styles from './Application.css';

export function GdprModal(): JSX.Element | null {
  const [isGDPRModalOpen, setIsGDPRModalOpen] = useState(
    useSelector(shouldShowGDPRSelector)
  );

  const appKey = useSelector(appKeySelector);
  const cmsToken = useSelector(tokenCMSSelector);
  const isTvDevice = useSelector(displayTVModeSelector);

  const URLPrivacyManager = getPrivacyManagerUrl(cmsToken, appKey);

  const handleCloseGDPR = () => {
    setIsGDPRModalOpen(false);
  };

  return isClientSide() && isGDPRModalOpen ? (
    <ModalV2
      className={styles.application__GDPRConsent}
      isTvDevice={isTvDevice}
      onClose={handleCloseGDPR}
      type="gdpr"
      id="modal-gdpr"
    >
      <LoadableGDPRConsent
        closeModal={handleCloseGDPR}
        URLPrivacyManager={URLPrivacyManager}
      />
    </ModalV2>
  ) : null;
}
