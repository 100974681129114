import { isClientSide } from '@canalplus/mycanal-commons';
import { updateUserConsent } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import Logger from '../../../helpers/logger/logger-helper';
import { getClientSideUserInfos } from '../../../helpers/user/getClientSideUserInfos';
import { shouldUsePassCache } from '../../../helpers/user/user-helper';
import { pathnameSelector } from '../../../store/slices/routing-selectors';
import { setTargetedAds } from '../../../store/slices/user';
import { authenticatedSelector } from '../../../store/slices/user-selectors';
import type { IState } from '../../../store/types/State-type';
import {
  setDidomiConsent,
  setDidomIsInIframe,
} from '../../DidomiProvider/didomiActions';
import {
  useDidomiAdvertisingConsent,
  useDidomiAnalyticsConsent,
  useDidomiConsent,
  useDidomiDispatch,
  useDidomiHasConsentChanged,
  useDidomiHasLoaded,
  useDidomiIsInIframe,
} from '../../DidomiProvider/didomiHooks';

/**
 * Custom hook to manage Didomi consent.
 *
 * This hook centralizes the effect handling related to Didomi consent management, optimizing for performance
 * by minimizing the number of re-renders and side effects.
 */
export const useDidomi = (
  isTrackingScriptLoaded: boolean,
  enabled: boolean = true
): void => {
  const state = useStore<IState>().getState();
  const dispatch = useAppDispatch();
  const updateUserConsentApi = usePassSdk(updateUserConsent);
  const isAuthenticated = useSelector(authenticatedSelector);
  const pathname = useSelector(pathnameSelector);
  const hasWaitForPassJSON =
    typeof window !== 'undefined' &&
    window.waitForPassJSON &&
    typeof window.waitForPassJSON === 'function';

  // Didomi state and dispatch from custom hooks
  const hasAdvertisingConsent = useDidomiAdvertisingConsent();
  const hasAnalyticsConsent = useDidomiAnalyticsConsent();
  const hasDidomiLoaded = useDidomiHasLoaded();
  const hasConsentChanged = useDidomiHasConsentChanged();
  const didomiConsent = useDidomiConsent();
  const didomiDispatch = useDidomiDispatch();
  const isDidomiInIframe = useDidomiIsInIframe();

  // This effect is used to update the user profile and cookies when the user has given his consent
  useEffect(() => {
    if (!enabled || !hasDidomiLoaded || !isClientSide()) {
      return;
    }

    const query = new URLSearchParams(window.location.search);
    const trackAnonymously = query.get('tracking') === 'anonymous';

    // Set tracking as anonymous if the tracking query param is set to anonymous and didomi is in iframe
    if (trackAnonymously) {
      didomiDispatch(setDidomIsInIframe(true));
      window.ttSetUserConsent({
        statsAllowed: false,
        adsAllowed: false,
        anonymousAllowed: true,
      });
    }

    const handleDidomiConsentUpdates = () => {
      // Update Didomi consents object in the store in case of page refresh or consent change

      if (
        !window.Didomi?.shouldUserStatusBeCollected() &&
        !didomiConsent?.purposes
      ) {
        didomiDispatch(setDidomiConsent(window.Didomi?.getCurrentUserStatus()));
      }

      // Update cookies and profile when consent has changed and tracking script is loaded
      if (hasConsentChanged && isTrackingScriptLoaded) {
        const settings = {
          analytics: hasAnalyticsConsent,
          trackingPub: hasAdvertisingConsent,
          anonymousTracking: true,
        };

        window.ttSetUserConsent({
          statsAllowed: hasAnalyticsConsent,
          adsAllowed: hasAdvertisingConsent,
          anonymousAllowed: true,
        });

        getClientSideUserInfos(state, {
          noCache: !shouldUsePassCache(),
          ...settings,
        });

        if (isAuthenticated) {
          updateProfileAndReload(settings);
        } else {
          window.location.reload();
        }
      }
    };

    const updateProfileAndReload = (settings) => {
      if (!hasWaitForPassJSON || !isClientSide()) {
        return;
      }

      window.waitForPassJSON?.(() => {
        if (
          (isAuthenticated && window.passJSON?.askForConsent === true) ||
          (isAuthenticated &&
            (window.passJSON?.analytics !== settings.analytics ||
              window.passJSON?.trackingPub !== settings.trackingPub ||
              window.passJSON?.anonymousTracking !==
                settings.anonymousTracking))
        ) {
          updateUserConsentApi({
            passToken: window.passJSON?.passToken || '',
            settings,
          })
            .then(() => {
              getClientSideUserInfos(state, { noCache: !shouldUsePassCache() });
              window.Didomi?.notice.hide();
            })
            .catch((e) => {
              Logger.error(
                `Pass::updateProfile Error while updating user profile: ${e}`
              );
            });
        }
        window.location.reload();
      });
    };

    handleDidomiConsentUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    didomiConsent,
    hasWaitForPassJSON,
    hasConsentChanged,
    hasDidomiLoaded,
    isAuthenticated,
    isTrackingScriptLoaded,
    enabled,
    hasAnalyticsConsent,
    hasAdvertisingConsent,
    isDidomiInIframe,
  ]);

  // This effect is used to show the Didomi notice or update the targetedAds in the store
  useEffect(() => {
    // Here we need to wait for passJSON to be available before showing the Didomi notice or updating the targetedAds in the store
    if (!hasWaitForPassJSON || !enabled || !isClientSide()) {
      return;
    }

    window.waitForPassJSON?.(async () => {
      // First case where @PASS askForConsent is true and we need to show the Didomi notice
      // Seconde case where euconsent-v2 is not present and we need to show the Didomi notice

      if (pathname === '/cookies/' || isDidomiInIframe) {
        // Hide Didomi notice on cookies page
        window.Didomi?.notice?.hide();
      } else if (
        (isAuthenticated &&
          hasDidomiLoaded &&
          window.passJSON?.askForConsent &&
          !window.Didomi?.notice?.isVisible() &&
          !isDidomiInIframe) ||
        (hasDidomiLoaded && !Cookies.get('euconsent-v2') && !isDidomiInIframe)
      ) {
        window.Didomi?.reset();
        window.Didomi?.notice?.show();
      }

      // This is the case where the user has already given his consent and we need to update targetedAds in the store
      if (
        (hasDidomiLoaded && hasAdvertisingConsent) ||
        (!hasDidomiLoaded && window.passJSON?.trackingPub)
      ) {
        // will send this to player
        // Update the user.targetedAds in the store when GDPR consent is confirmed
        dispatch(setTargetedAds('1'));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasDidomiLoaded,
    hasWaitForPassJSON,
    isAuthenticated,
    hasAdvertisingConsent,
    pathname,
    isDidomiInIframe,
  ]);
};
