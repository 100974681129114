export const sendDocHeightMsg = (bodyHeight: number): void => {
  window.parent.postMessage(
    {
      type: 'resize',
      height: bodyHeight,
    },
    '*'
  );
};

export const initIframeBehavior = (): void => {
  window.addEventListener(
    'load',
    () => {
      sendDocHeightMsg(document.body.scrollHeight);

      const myObserver = new ResizeObserver((entries) => {
        entries.forEach(() => {
          sendDocHeightMsg(document.body.scrollHeight);
        });
      });

      myObserver.observe(document.body);
    },
    false
  );
};
