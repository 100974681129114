import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ABTestingCookiesHandler } from '../../../client/components/ABTestingCookies';
import { abTestingPopulationSelector } from '../../../store/slices/user-selectors';

/**
 * Handle AbTesting cookies\
 * Used on mount only.
 */
export const useAbTestingCookiesHandler = (): void => {
  const abTestingPopulationValue = useSelector(abTestingPopulationSelector);

  useEffect(() => {
    ABTestingCookiesHandler(abTestingPopulationValue);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
