import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { ApiV2SearchCurrentPage } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/search/definitions';
import { ApiV2PageMeta } from '@dce-front/hodor-types/api/v2/page/dtos/helpers/page/definitions';
import { SearchPageParameters } from '@dce-front/hodor-types/modules/page/definitions';
import { BranSdkSearchPageParameters } from '@dce-front/hodor-types/sdks/bran/page/search/definition';

export interface ISearchStandaloneState {
  currentPage?: ApiV2SearchCurrentPage;
  pageParameters?: BranSdkSearchPageParameters & { searchkey: string };
  tracking?: ApiV2PageTracking;
}

type ApiV2StaticContentSearch = {
  meta?: ApiV2PageMeta;
  pageParameters?: SearchPageParameters & { searchkey: string };
};

// @todo Delete this type when hodor exposes 'searchkey' in the pageParameters object in ApiV2SearchPage
export type ApiV2SearchPageWithSearchKey = ApiV2StaticContentSearch & {
  currentPage?: ApiV2SearchCurrentPage;
  tracking?: ApiV2PageTracking;
};

const getFormattedSearchStandalone = (
  data: ApiV2SearchPageWithSearchKey
): ISearchStandaloneState => {
  const { currentPage, tracking, pageParameters } = data || {};

  return {
    currentPage,
    pageParameters,
    tracking,
  };
};

export default getFormattedSearchStandalone;
