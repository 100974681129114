import classNames from 'classnames';
import { useCallback, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import IconX from '../../../assets/svg/x.svg';
import { HeaderSearchIcon } from '../HeaderSearchIcon/HeaderSearchIcon';
import styles from './HeaderSearch.module.css';

export type HeaderSearchProps = {
  closeAriaLabel: string;
  closeId?: string;
  defaultQuery?: string;
  focusOnMount?: boolean;
  isClosing?: boolean; // HeaderWithSearchLayout determines this
  onSearch: (query: string) => void;
  onClose: () => void;
  searchAriaLabel: string;
  searchId?: string;
};

export function HeaderSearch({
  searchAriaLabel,
  closeAriaLabel,
  focusOnMount = true,
  isClosing = false,
  searchId = 'headerSearch',
  defaultQuery = '',
  closeId,
  onSearch,
  onClose,
}: HeaderSearchProps): JSX.Element {
  const searchRef = useRef<HTMLInputElement>(null);

  // Focus the input on mount
  useIsomorphicLayoutEffect(() => {
    if (focusOnMount && searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  const handleSearch = useCallback(() => {
    if (searchRef.current) {
      onSearch(searchRef.current.value);
    }
  }, [onSearch]);

  return (
    <label
      htmlFor={searchId}
      className={classNames(styles.HeaderSearch, {
        [styles['HeaderSearch--isClosing']]: isClosing,
      })}
      data-testid="header-search-label"
    >
      <input
        aria-label={searchAriaLabel}
        autoComplete="off"
        className={styles.HeaderSearch__input}
        id={searchId}
        onChange={handleSearch}
        ref={searchRef}
        type="search"
        value={defaultQuery}
      />

      <HeaderSearchIcon className={styles.HeaderSearch__searchIcon} />

      <button
        aria-label={closeAriaLabel}
        className={styles.HeaderSearch__closeButton}
        id={closeId}
        onClick={onClose}
        type="button"
        data-testid={closeId}
      >
        <IconX role="img" />
      </button>
    </label>
  );
}
