import { useOnPageScroll } from '@canalplus/mycanal-util-react';
import { useCallback, useEffect, useState } from 'react';
import {
  HEADER_TRANSPARENCY_SCROLL_DEPTH_LIMIT,
  HeaderTheme,
} from '../../constants/header';

/**
 * Hook to determine the header theme based on:
 *  - scroll depth
 *  - search active or not
 *  - app light/dark theme
 *  - header transparency
 * @param isDarkTheme
 * @param isTransparent
 * @param isSearchActive
 * @returns HeaderTheme
 * @example const headerTheme = useHeaderTheme(isDarkTheme, isTransparent, isSearchActive);
 */
export const useHeaderTheme = (
  isDarkTheme: boolean,
  isTransparent: boolean,
  isSearchActive: boolean
): HeaderTheme => {
  const baseHeaderTheme = isDarkTheme ? HeaderTheme.DARK : HeaderTheme.LIGHT;
  // No transparency when search is active
  const isTransparentEligible = isTransparent && !isSearchActive;

  const [currentHeaderTheme, setCurrentHeaderTheme] = useState<HeaderTheme>(
    isTransparent ? HeaderTheme.TRANSPARENT : baseHeaderTheme
  );

  const determineHeaderTheme = useCallback(
    (scrollDepth: number) => {
      const isWithinTransparentScrollDepth =
        scrollDepth < HEADER_TRANSPARENCY_SCROLL_DEPTH_LIMIT;
      setCurrentHeaderTheme(
        isTransparentEligible && isWithinTransparentScrollDepth
          ? HeaderTheme.TRANSPARENT
          : baseHeaderTheme
      );
    },
    [baseHeaderTheme, isTransparentEligible]
  );

  const handleScroll = useCallback(
    (scrollDepth: number) => {
      determineHeaderTheme(scrollDepth);
    },
    [determineHeaderTheme]
  );

  // Update theme if any of the determining values change
  useEffect(() => {
    determineHeaderTheme(document.documentElement.scrollTop ?? 0);
  }, [determineHeaderTheme]);

  useOnPageScroll(handleScroll, isTransparent);

  return currentHeaderTheme;
};
