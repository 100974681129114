import classNames from 'classnames';
import { cloneElement, forwardRef } from 'react';
import { HeaderNavItemProps } from '../HeaderNavItem/HeaderNavItem.types';

type Ref = HTMLDivElement & HTMLButtonElement & HTMLAnchorElement;

const HeaderNavDropdownItem = forwardRef<Ref, HeaderNavItemProps>(
  (
    {
      label,
      id,
      href,
      wrapper,
      icon,
      isCurrent = false,
      isFocused = false,
      ...props
    },
    forwardedRef
  ) => {
    const style = classNames(
      [
        'group flex items-center cursor-pointer no-underline box-border hover:outline-none',
        'font-dt-font-family-system text-dt-font-size-16 leading-dt-font-line-height-20 font-dt-font-weight-regular',
        'h-32 py-dt-spacing-100 px-dt-spacing-200 my-dt-spacing-100',
        'hover:bg-dt-theme-surface-card-tab-card-tab-hover focus:bg-dt-theme-surface-card-tab-card-tab-hover',
      ],
      {
        'bg-dt-theme-surface-card-tab-card-tab-hover': isFocused,
        'text-dt-theme-text-header-header-active': isCurrent,
        'text-dt-theme-text-header-header-default transition-colors duration-200':
          !isCurrent,
      }
    );

    const listItemIcon = (iconItem: React.ReactElement) =>
      cloneElement(iconItem, {
        className: classNames('h-26 basis-26 grow-0 shrink-0', {
          'fill-dt-theme-icon-header-icon-active': isCurrent,
          'fill-dt-theme-icon-header-icon group-hover:fill-dt-theme-icon-header-icon-active transition-colors duration-200':
            !isCurrent,
        }),
      });

    const listItemChildren = (
      <>
        {icon && listItemIcon(icon)}
        {label}
      </>
    );

    if (wrapper) {
      return cloneElement(
        wrapper,
        // eslint-disable-next-line react-compiler/react-compiler
        {
          id,
          className: style,
          'aria-current': isCurrent && 'page',
          ref: forwardedRef,
          ...props,
        },
        listItemChildren
      );
    }

    return (
      <a
        ref={forwardedRef}
        id={id}
        href={href}
        className={style}
        aria-current={isCurrent && 'page'}
        {...props}
      >
        {listItemChildren}
      </a>
    );
  }
);

HeaderNavDropdownItem.displayName = 'HeaderNavDropdownItem';

export default HeaderNavDropdownItem;
