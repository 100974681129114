export type FooterLabels = {
  title: string;
  content: string;
  credits: string;
};

/**
 * Default footer fr labels\
 * ⚠️ If used, **{year}** credits template should be filled with current year
 */
export const FR_FOOTER_LABELS = {
  title: 'Vivez l’expérience CANAL+',
  content:
    'Le site de streaming le plus complet et le seul qui réunit vos films, vos séries (en HD, VF et VOST), toute la TNT et les plus belles compétitions sportives en direct ou en replay. Le programme TV de ce soir, de la TNT et de toutes les chaines est gratuit et sans pub.',
  credits: '© CANAL+ {year}',
} as const satisfies FooterLabels;

/**
 * Default footer en labels\
 * ⚠️ If used, **{year}** credits template should be filled with current year
 */
export const EN_FOOTER_LABELS = {
  title: 'Are you subscribed?',
  content:
    'All shows & movies included in your subscription are available on CANAL+ PC/Mac, smartphone and tablet.',
  credits: '© CANAL+ {year}',
} as const satisfies FooterLabels;

/**
 * Default footer it labels\
 * ⚠️ If used, **{year}** credits template should be filled with current year
 */
export const IT_FOOTER_LABELS = {
  title: "Vivi l'esperienza TIM",
  content:
    'Su TIMVISION, trovi il meglio dei contenuti da guardare in streaming: film cult, le ultime novità nel mondo delle serie TV, programmi TV, cartoni animanti, i grandi eventi sportivi e molto altro ancora!',
  credits: '©{year} TIM - Partita IVA: 00488410010',
} as const satisfies FooterLabels;

/**
 * Default pl labels for footer\
 * ⚠️ If used, **{year}** credits template should be filled with current year
 */
export const PL_FOOTER_LABELS = {
  title: 'Czy jesteś naszym Klientem?',
  content:
    'Wszystkie programy w ramach Twojej oferty CANAL+ są dostępne na PC/Mac, smartfonach i tabletach',
  credits: '© CANAL+ {year}',
} as const satisfies FooterLabels;
