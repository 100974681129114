import { EpgGridPage } from '@dce-front/hodor-types/api/v2/epg_grid/definitions';
import { getEpgIdFromURLChannelSchedule } from './helpers';

export const getFormattedEPGGrid = (
  data: EpgGridPage,
  blackListedChannels?: number[]
): EpgGridPage => {
  // Filter out channels that are missing URLLogoChannel or URLChannelSchedule attributes
  const filteredChannels = data.channels.filter(
    (channel) =>
      !!channel.URLLogoChannel &&
      !!channel.URLChannelSchedule &&
      !blackListedChannels?.includes(
        getEpgIdFromURLChannelSchedule(channel.URLChannelSchedule)
      )
  );

  return {
    ...data,
    channels: filteredChannels,
  };
};
