import { cva } from 'class-variance-authority';

export const headerCVA = cva('', {
  variants: {
    isFixed: {
      true: 'fixed top-0 left-0 right-0',
      false: undefined,
    },
    isTransparent: {
      true: 'bg-gradient-to-b from-dt-theme-gradient-header-gradient-gradient-start to-dt-theme-gradient-header-gradient-gradient-end',
      false: 'bg-dt-theme-background-header-header',
    },
    device: {
      web: undefined,
      tv: undefined,
    },
  },
  compoundVariants: [
    {
      isTransparent: false,
      device: 'web',
      className:
        'border-solid border-dt-border-none border-b-dt-border-thin border-b-dt-theme-border-header-header',
    },
    {
      isTransparent: true,
      device: 'web',
      className: 'h-[8.75rem]',
    },
  ],
});

export const headerContentCVA = cva(
  'flex flex-wrap dt-breakpoint-sm-start:flex-nowrap items-center w-full',
  {
    variants: {
      device: {
        web: [
          'min-h-64 pt-dt-spacing-100 dt-breakpoint-sm-start:pt-dt-spacing-none px-dt-spacing-none dt-breakpoint-sm-start:py-dt-spacing-none',
          '[&>*:first-child]:pl-dt-spacing-200 [&>*:first-child]:dt-breakpoint-sm-start:pl-dt-spacing-400', // First child only
        ],
        tv: 'h-[9.875rem] box-border px-dt-spacing-1400',
      },
    },
  }
);

export const headerLogoCVA = cva(
  'flex flex-none items-center order-1 my-dt-spacing-100',
  {
    variants: {
      device: {
        web: 'h-32 pr-dt-spacing-200 dt-breakpoint-sm-start:pr-dt-spacing-250',
        tv: 'h-48 pr-dt-spacing-600',
      },
    },
  }
);

export const headerPrimaryNavCVA = cva(
  'relative w-full overflow-hidden order-3 dt-breakpoint-sm-start:order-2'
);

export const headerSecondaryNavCVA = cva(
  [
    'flex items-center justify-end order-2 dt-breakpoint-sm-start:order-3 dt-breakpoint-sm-start:grow',
    'flex-auto dt-breakpoint-sm-start:flex-initial',
    'w-0 dt-breakpoint-sm-start:w-auto',
    'min-w-32 dt-breakpoint-sm-start:min-w-fit',
  ],
  {
    variants: {
      device: {
        web: [
          'h-32 ml-dt-spacing-100 dt-breakpoint-sm-start:ml-dt-spacing-200 pr-dt-spacing-200',
          'dt-breakpoint-sm-start:pr-dt-spacing-400 my-dt-spacing-100 space-x-dt-spacing-200',
        ],
        tv: 'h-54 space-x-dt-spacing-300',
      },
    },
  }
);
