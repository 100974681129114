import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { getContentWithContexts } from '../../../helpers/contents/contents-helper';
import { StrateV5 } from '../../../templates/LandingV5/data/formatter';
import { IContentRowStandardState } from './types';

const formatContentRowData = (strate: StrateV5): IContentRowStandardState => {
  const contentsWithStaticKey = mapStaticKey(strate.contents, 'title');

  return {
    contents: contentsWithStaticKey.map((content) => {
      if (!content.onClick) {
        return content;
      }
      return getContentWithContexts(content, strate.context);
    }),
    URLNextPage: strate.paging?.URLPage,
  };
};

export default formatContentRowData;
