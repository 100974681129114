import { KEYCODE, throttle } from '@canalplus/mycanal-commons';
import { useOnClickOutside } from '@canalplus/mycanal-util-react';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DATA_ATTR_HEADER_NAVIGATION_WRAPPER,
  DATA_ATTR_HEADER_SEARCH_TRIGGER,
  HEADER_HEIGHT_MOBILE_SEARCH_OPEN,
  HEADER_HEIGHT_TABLET,
  HEADER_LAYOUT_SHIFT_BP,
  HEADER_SEARCH_ICON_SIZE,
  HEADER_SEARCH_INPUT_EXPANSION_DURATION,
  HEADER_SEARCH_INPUT_EXTENSION,
  HEADER_SEARCH_INPUT_HEIGHT,
  HEADER_SEARCH_INPUT_MIN_EDGE_DISTANCE,
} from '../../../constants/header';
import { HeaderSearch, HeaderSearchProps } from '../HeaderSearch/HeaderSearch';
import styles from './HeaderWithSearchLayout.module.css';

enum HeaderSearchPhase {
  CLOSED, // Search input is not rendered
  POSITION_INITIAL, // Search input positioned around search trigger
  POSITION_EXPANDED, // Search input stretches horizontally to determined points
  CLOSING, // Search input returns to the initial position as the css fades it out
}

export type HeaderSearchCoords = {
  searchFormTop: number;
  searchFormLeft: number;
  searchFormRight: number;
};

export type HeaderWithSearchLayoutProps = HeaderSearchProps & {
  isSearchOpen: boolean;
  searchDefaultQuery?: string;
  children: React.ReactNode;
};

/**
 * HeaderWithSearchLayout
 *
 * This component controls the layout and styles for the header search interface.
 * The whole search interface (apart from the search trigger) is positioned directly on top of the site header.
 * It positions (via js) and animates (via css) a wrapper element that houses the search input (<HeaderSearch>).
 *
 * To achieve this, we track two header elements - the search trigger and the navigation wrapper.
 * Those elements give us the coordinates needed to position the search interface and perform our animations.
 *
 * We're directly accessing the DOM instead of using refs to get our reference element coordinates.  This is done
 * because passing around deeply nested refs would vastly complicate the relatively decoupled nature of the Header V5.
 * It works fine this way and there are fallback positions if for some reason the reference elements are not found.
 *
 * @param children            The header component
 * @param isSearchOpen        Determines if we display the search
 * @param searchDefaultQuery  Determines the first value of the search input
 * @param onClose             Callback to run when the user closes the search interface
 * @param onSearch            Callback to run when the user types in the search bar
 * @param closeAriaLabel      A11y label for the close button
 * @param closeId             ID attribute for the close button
 * @param focusOnMount        If true, the search input will autofocus when first rendered
 * @param searchAriaLabel     A11y label for the search input
 * @param searchId            ID attribute for the search input
 * @returns {node}
 */
export function HeaderWithSearchLayout({
  children,
  isSearchOpen,
  searchDefaultQuery,
  onClose,
  onSearch,
  ...restHeaderSearchProps
}: HeaderWithSearchLayoutProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  // We need to keep track of whether there's a query so we can close the search on outside clicks
  const [hasQuery, setHasQuery] = useState<boolean>(
    !!searchDefaultQuery || false
  );
  // Keep track of whether or not a query has been entered into search, and pass query to onSearch callback
  const handleSearch = useCallback(
    (query: string) => {
      if (query && !hasQuery) {
        setHasQuery(true);
      } else if (!query && hasQuery) {
        setHasQuery(false);
      }
      onSearch(query);
    },
    [hasQuery, onSearch]
  );

  // For certain search-close scenarios, we need to put the focus back on the search trigger so the tab/focus flow is not broken
  const closeAndFocusSearchTrigger = useCallback(() => {
    onClose();
    const elSearchTrigger = document.querySelector<HTMLButtonElement>(
      `[${DATA_ATTR_HEADER_SEARCH_TRIGGER}]`
    );
    if (elSearchTrigger) {
      elSearchTrigger.focus();
    }
  }, [onClose]);

  // Close the search form if it's open with no query entered and the user clicks elsewhere on the page
  useOnClickOutside(containerRef, onClose, isSearchOpen && !hasQuery);

  const [phase, setPhase] = useState<HeaderSearchPhase>(
    HeaderSearchPhase.CLOSED
  );
  const [coords, setCoords] = useState<HeaderSearchCoords>({
    searchFormTop: 8,
    searchFormLeft: HEADER_SEARCH_INPUT_MIN_EDGE_DISTANCE,
    searchFormRight: HEADER_SEARCH_INPUT_MIN_EDGE_DISTANCE,
  });

  const updateCoords = useCallback(() => {
    if (containerRef.current) {
      const { width: headerWidth } =
        containerRef.current.getBoundingClientRect();
      const isMobileLayout = headerWidth < HEADER_LAYOUT_SHIFT_BP;

      // Fallback initial coordinates for the search input
      const centeredInitialInputPosition =
        phase === HeaderSearchPhase.POSITION_EXPANDED
          ? HEADER_SEARCH_INPUT_MIN_EDGE_DISTANCE
          : headerWidth / 2 -
            (HEADER_SEARCH_ICON_SIZE + HEADER_SEARCH_INPUT_EXTENSION * 2) / 2;

      // These coordinates will be used if either of the reference elements can't be found in the DOM
      const newCoords: HeaderSearchCoords = {
        searchFormTop:
          ((isMobileLayout
            ? HEADER_HEIGHT_MOBILE_SEARCH_OPEN
            : HEADER_HEIGHT_TABLET) -
            HEADER_SEARCH_INPUT_HEIGHT) /
          2,
        searchFormLeft: centeredInitialInputPosition,
        searchFormRight: centeredInitialInputPosition,
      };

      // Find the reference elements:
      // Used to center search input around trigger when search is initiated, also dictates the extended right boundary for tablet+
      const elSearchTrigger = document.querySelector(
        `[${DATA_ATTR_HEADER_SEARCH_TRIGGER}]`
      );
      // Used to set the extended left boundary for tablet+
      const elNavigation = document.querySelector(
        `[${DATA_ATTR_HEADER_NAVIGATION_WRAPPER}]`
      );

      if (elSearchTrigger && elNavigation) {
        // Get reference elements' coordinates
        const searchTriggerCoords = elSearchTrigger.getBoundingClientRect();
        const navigationCoords = elNavigation.getBoundingClientRect();
        const searchTriggerParentCoords = (
          elSearchTrigger as HTMLButtonElement
        ).offsetParent.getBoundingClientRect();
        // Search trigger coordinates
        const {
          top: searchTriggerTop,
          left: searchTriggerLeft,
          height: searchTriggerHeight,
          width: searchTriggerWidth,
        } = searchTriggerCoords;
        const searchTriggerRightExtended =
          headerWidth -
          searchTriggerLeft -
          searchTriggerWidth -
          HEADER_SEARCH_INPUT_EXTENSION; // Half way between search trigger and next user option

        // Expanded limits
        const boundaryLeft = isMobileLayout
          ? HEADER_SEARCH_INPUT_MIN_EDGE_DISTANCE
          : navigationCoords.left - 5; // 5px left of navigation's left edge
        const boundaryRight = isMobileLayout
          ? HEADER_SEARCH_INPUT_MIN_EDGE_DISTANCE
          : searchTriggerRightExtended;

        // Search form positioning
        newCoords.searchFormTop =
          searchTriggerTop -
          searchTriggerParentCoords.top -
          (HEADER_SEARCH_INPUT_HEIGHT - searchTriggerHeight) / 2;
        newCoords.searchFormLeft =
          phase === HeaderSearchPhase.POSITION_EXPANDED
            ? boundaryLeft
            : searchTriggerLeft - HEADER_SEARCH_INPUT_EXTENSION;
        newCoords.searchFormRight =
          phase === HeaderSearchPhase.POSITION_EXPANDED
            ? boundaryRight
            : searchTriggerRightExtended;
      }

      setCoords(newCoords);
    }
  }, [phase]);

  // If the search is open, update coordinates on window rezize
  useEffect(() => {
    if (phase !== HeaderSearchPhase.CLOSED) {
      const updateCoordsThrottled = throttle(updateCoords, 16);
      window.addEventListener('resize', updateCoordsThrottled);
      return () => window.removeEventListener('resize', updateCoordsThrottled);
    }
    return;
  }, [phase, updateCoords]);

  // When isSearchOpen prop changes, set the search form positioning in motion
  useEffect(() => {
    if (isSearchOpen) {
      setPhase(HeaderSearchPhase.POSITION_INITIAL);
    } else if (phase !== HeaderSearchPhase.CLOSED) {
      setPhase(HeaderSearchPhase.CLOSING);
      setHasQuery(false);
      setTimeout(() => {
        setPhase(HeaderSearchPhase.CLOSED);
      }, HEADER_SEARCH_INPUT_EXPANSION_DURATION + 100);
    }
  }, [isSearchOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  // When phase changes, if we have just rendered the search form's initial position, move on to expand it
  useEffect(() => {
    if (phase === HeaderSearchPhase.POSITION_INITIAL) {
      setPhase(HeaderSearchPhase.POSITION_EXPANDED);
    }
  }, [phase]);

  // Update coordinates whenever phase (state) or isSearchOpen (prop) changes.  This ensures the initial position is correct.
  useEffect(() => {
    updateCoords();
  }, [isSearchOpen, phase]); // eslint-disable-line react-hooks/exhaustive-deps

  // Close search on escape key press (if no query entered)
  useEffect(() => {
    if (isSearchOpen && !hasQuery) {
      const potentiallyClose = (event: KeyboardEvent) => {
        if (event.which === KEYCODE.ESCAPE) {
          closeAndFocusSearchTrigger();
        }
      };
      document.addEventListener('keydown', potentiallyClose);
      return () => {
        document.removeEventListener('keydown', potentiallyClose);
      };
    }
    return;
  }, [closeAndFocusSearchTrigger, isSearchOpen, hasQuery]);

  const renderSearchForm = phase !== HeaderSearchPhase.CLOSED;
  const isClosing = phase === HeaderSearchPhase.CLOSING;

  return (
    <div
      className={classNames(styles.HeaderWithSearchLayout, {
        [styles['HeaderWithSearchLayout--searchOpen']]: renderSearchForm,
        [styles['HeaderWithSearchLayout--closing']]: isClosing,
      })}
      ref={containerRef}
    >
      <div className={styles.HeaderWithSearchLayout__headerWrap}>
        {children}
      </div>{' '}
      {/* The Header */}
      {renderSearchForm && (
        <div className={styles.HeaderWithSearchLayout__searchLayout}>
          <div
            className={styles.HeaderWithSearchLayout__searchWrap}
            style={{
              left: `${coords.searchFormLeft}px`,
              right: `${coords.searchFormRight}px`,
              top: `${coords.searchFormTop}px`,
            }}
            role="search"
          >
            <HeaderSearch
              isClosing={isClosing}
              defaultQuery={searchDefaultQuery}
              onClose={closeAndFocusSearchTrigger}
              onSearch={handleSearch}
              {...restHeaderSearchProps}
            />
          </div>
        </div>
      )}
    </div>
  );
}
