import { getThumborUrl } from '@canalplus/dive';
import { THUMBOR } from '@canalplus/mycanal-commons';
import { OfferLocation } from '@canalplus/sdk-core';
import Helmet from 'react-helmet';
import Metas from '../../../config/application/offerLocation/default';
import { MetasType } from '../../../config/application/types';
import { getPublicConfig } from '../../helpers/config/config-helper';
import {
  buildSharedTitle,
  metaTagsTranslator,
} from '../../helpers/metaTags/buildMeta';
import {
  getAlternateLinks,
  mergeMetaTags,
} from '../../helpers/metaTags/metaTags-helper';
import type { IMetaTag } from '../../helpers/metaTags/types';
import { getUrlWithoutDefaultLang } from '../../helpers/url/url-helper';
import I18n from '../../lang';
import type { AlternateRecord } from '../../store/slices/alternateLinks';

export type MetaTagsProps = {
  canonicalUrl: string;
  config?: MetasType;
  currentUrl: string;
  hostname: string;
  locale: string;
  offerLocation: OfferLocation;
  pathname: string;
  contentMetaRobots?: string;
  description?: string;
  image?: string;
  isAdult?: boolean;
  title?: string;
  metasTwitter?: IMetaTag[];
  alternateLinks?: AlternateRecord;
  snippets?: string;
};

const META_TAG_DEFAULT = { width: 1200, height: 630 } as const;

/**
 * META tags used to place metadata in an HTML page
 *
 * @param title       title of the page
 * @param description short description of the page
 * @param image       URL of the image that represents the page
 * @param offerLocation user location
 * @param pathname    current path of the page
 * @param hostname    hostname
 * @param currentUrl  URL of the page
 */
export function MetaTags({
  canonicalUrl,
  config,
  contentMetaRobots = '',
  currentUrl,
  description = '',
  hostname,
  image: imageUrl,
  isAdult = false,
  locale,
  offerLocation,
  pathname,
  title = '',
  snippets,
  metasTwitter,
  alternateLinks,
}: MetaTagsProps): JSX.Element | null {
  const { t } = I18n.useTranslation();
  const translatedTitle = metaTagsTranslator(t, title);
  const sharedTitle = buildSharedTitle(translatedTitle, t);
  const publicConfig = getPublicConfig();

  if (!publicConfig.documentHeader || !config) {
    return null;
  }

  const documentHeader =
    publicConfig.documentHeader[offerLocation] ||
    publicConfig.documentHeader.default;

  const urlImageDefault = documentHeader.app?.head?.twitter?.urlImageDefault;

  const metaTagWidth = Metas?.app?.head?.meta?.find(
    (el) => el.property === 'og:image:width'
  )?.content;
  const metaTagHeight = Metas?.app?.head?.meta?.find(
    (el) => el.property === 'og:image:height'
  )?.content;

  const thumbor = imageUrl
    ? getThumborUrl({
        url: imageUrl,
        includesAllowedSourceInUrl:
          THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE.some((endpoint) =>
            imageUrl?.includes(endpoint)
          ),
        dimensions: {
          height: Number(metaTagHeight) || META_TAG_DEFAULT.height,
          width: Number(metaTagWidth) || META_TAG_DEFAULT.width,
        },
        quality: THUMBOR.QUALITY_DEFAULT,
      })
    : undefined;

  const pathnameWithoutDefaultLang = getUrlWithoutDefaultLang(pathname);

  const metasTwitterFormatted: IMetaTag[] = metasTwitter
    ? metasTwitter.map((meta) => {
        if (meta.name === 'twitter:title') {
          return { ...meta, content: translatedTitle };
        }
        if (meta.name === 'twitter:image') {
          return {
            ...meta,
            content: !isAdult ? thumbor?.url : urlImageDefault,
          };
        }
        return meta;
      })
    : [];

  const metas: IMetaTag[] = [
    { property: 'title', content: translatedTitle },
    { property: 'og:title', content: sharedTitle },
    {
      property: 'og:description',
      content: description || config.app.description,
    },
    {
      property: 'og:image',
      content: !isAdult && thumbor ? thumbor.url : urlImageDefault,
    },
    { property: 'og:locale', content: locale },
    {
      property: 'og:url',
      content: `https://${currentUrl}${pathnameWithoutDefaultLang}`,
    },
  ];

  const descriptionMetas: IMetaTag[] = [
    { name: 'description', content: description || config.app.description },
  ];

  const newMetas = mergeMetaTags(config.app.head.meta, metas);

  // add meta tags robots only if we have property robots in meta response
  const robotsMetas = contentMetaRobots
    ? [
        {
          name: 'robots',
          content: contentMetaRobots,
        },
      ]
    : [];

  const links = [
    { rel: 'canonical', href: canonicalUrl },
    ...config.app.head.link,
  ];

  // add alternate links
  if (alternateLinks) {
    links.push(
      ...getAlternateLinks(alternateLinks, hostname, pathname, offerLocation)
    );
  }

  return (
    <Helmet
      {...config.app.head}
      title={translatedTitle || config.app.title}
      meta={[
        ...newMetas,
        ...descriptionMetas,
        ...robotsMetas,
        ...metasTwitterFormatted,
      ]}
      link={links}
    >
      {snippets && (
        <script type="application/ld+json">{JSON.stringify(snippets)}</script>
      )}
    </Helmet>
  );
}
