import { DiveContextProvider } from '@canalplus/dive';
import { ready } from '@canalplus/ifc-onecore';
import {
  StoreProvider as OneNavigationProvider,
  Store,
} from '@canalplus/one-navigation';
import { PassSdkProvider } from '@canalplus/sdk-pass-react';
import { loadableReady } from '@loadable/component';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ConnectedRouter } from 'connected-react-router';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  type ApplicationProps,
  Application,
} from '../../components/Application/Application';
import { DidomiProvider } from '../../components/DidomiProvider/didomiContext';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { AcmSdkProvider } from '../../components/SafetyCode/AcmSdkProvider';
import { getCurrentTheme } from '../../helpers/application/application-helper';
import { BINDER_DEFAULT_SELECTOR } from '../../helpers/binder/constants';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { patchIfcForMocks } from '../../helpers/ifc/ifc-onecore.patch';
import {
  bindR7Keys,
  initOneNavigationStore,
} from '../../helpers/oneNavigation/store';
import { getPassSdkConfig } from '../../helpers/pass/getPassSdkConfig';
import { markPerformance } from '../../helpers/performance/markPerformance';
import { patchR7ForMocks } from '../../helpers/r7/r7extlib.patch';
import { createQueryClient } from '../../helpers/reactQuery/reactQuery-helper';
import I18n from '../../lang';
import { getAcmSdkConfig } from '../../services/Acm/getAcmSdkConfig';
import {
  fullLocaleSelector,
  getFeatureToggleBlueTim,
  getThemeSelector,
  isDarkModeSelector,
} from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { AppHistory } from '../../typings/routing';

type RendererParameters = {
  history: AppHistory;
  store: Redux.CustomStore;
  target: Element | Document;
  userInfos: ApplicationProps['userInfos'];
};

export default async function renderer({
  history,
  store,
  target,
  userInfos,
}: RendererParameters): Promise<void> {
  const state = store.getState();
  const queryClient = createQueryClient();
  const dehydratedState = window.REACT_QUERY_STATE;
  const isTvDevice = displayTVModeSelector(state);
  const locale = fullLocaleSelector(state);

  const oneNavigationStore = isTvDevice
    ? initOneNavigationStore(history, store)
    : new Store({ selector: BINDER_DEFAULT_SELECTOR });

  if (isTvDevice) {
    // On tv device, we manually control the scroll behavior via one-navigation. Therefore we do
    // not want scroll restoration to mess things up
    // See : https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    if (!getPublicConfig().mock) {
      // On regular configuration we just load R7
      await import('../../lib/r7extlib');
    } else {
      // On mock configuration we load the patch version
      await patchR7ForMocks();

      // Run the Ifc patch file if we're in mock configuration
      await patchIfcForMocks();
    }
  }

  let component = (
    <Provider store={store} key="provider">
      <OneNavigationProvider store={oneNavigationStore}>
        <ConnectedRouter history={history}>
          <DiveContextProvider isTv={isTvDevice}>
            <AcmSdkProvider acmSdkConfig={getAcmSdkConfig({ state })}>
              <PassSdkProvider passSdkConfig={getPassSdkConfig({ state })}>
                <I18n.Provider locale={locale}>
                  <DidomiProvider>
                    <Application userInfos={userInfos} />
                  </DidomiProvider>
                </I18n.Provider>
              </PassSdkProvider>
            </AcmSdkProvider>
          </DiveContextProvider>
        </ConnectedRouter>
      </OneNavigationProvider>
    </Provider>
  );

  // In SSR mode we need to hydrate the app from what was returned server side.
  if ($_BUILD_RENDERMODE_SSR) {
    component = (
      <HydrationBoundary state={dehydratedState}>{component}</HydrationBoundary>
    );
  }

  const initialChildren = (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        {component}
      </QueryClientProvider>
    </ErrorBoundary>
  );

  if ($_BUILD_RENDERMODE_SSR) {
    await loadableReady(() => hydrateRoot(target, initialChildren));
  } else {
    // On CSR mode we need to setup the theme and remove display=none
    const isDarkMode = isDarkModeSelector();
    const mainTheme = getThemeSelector(state);
    const isBlueTim = getFeatureToggleBlueTim(state);

    document.body.setAttribute(
      'data-theme',
      getCurrentTheme(isDarkMode, mainTheme, isBlueTim)
    );
    document.body.style.display = 'block';

    await loadableReady(() =>
      createRoot(target as Element).render(initialChildren)
    );
  }

  markPerformance('react-render', state);

  if (isTvDevice) {
    try {
      // Once react is first rendered / hydrated we call R7.ready and we bind navigation keys
      await ready();
      bindR7Keys(oneNavigationStore);
    } catch (e) {
      console.error(e);
    }
  }
}
