import { FetchRequestTypes } from './types';

export const TEMPLATES_PERSO = [
  FetchRequestTypes.ActionLayout,
  FetchRequestTypes.ContentGridPerso,
  FetchRequestTypes.ContentRowPerso,
  FetchRequestTypes.ContentRowPersoContainer,
  FetchRequestTypes.ContextualOffer,
  FetchRequestTypes.EpisodeContainer,
  FetchRequestTypes.EpisodeListContainer,
  FetchRequestTypes.FunnelPaymentV5,
  FetchRequestTypes.GabaritList,
  FetchRequestTypes.LiveGridFavorites,
  FetchRequestTypes.LiveGridFavoritesContainer,
  FetchRequestTypes.MediaUrlPerso,
  FetchRequestTypes.MyAccountIdp,
  FetchRequestTypes.Slideshow,
];

export const TEMPLATES_WITH_NO_CACHE = TEMPLATES_PERSO.concat([
  FetchRequestTypes.LiveTv,
  FetchRequestTypes.LiveGrid,
]);
