import { dynamic, enter, memory, spatial } from '@canalplus/one-navigation';
import {
  centerBinder,
  pageScrollTop,
  scroll,
  scrollFocusedIntoHorizontalList,
  scrollFocusedIntoVerticalScrollContainer,
  scrollFocusedIntoVerticalViewport,
  scrollModalTop,
  scrollTop,
  scrollToTopOnFirstLineOfContentGrid,
} from './scroll';

export const MIDDLEWARE_HEADER = [scroll([pageScrollTop]), memory(), spatial()];

export const MIDDLEWARE_CENTER = [scroll([centerBinder]), spatial()];

// forceFocus on the "a" letter
export const MIDDLEWARE_KEYBOARD = [
  enter({ forceFocusIndex: 1 }),
  scroll([pageScrollTop]),
  memory(),
  spatial(),
];

export const MIDDLEWARE_STRATE = [
  scroll([
    scrollFocusedIntoVerticalViewport(),
    scrollFocusedIntoHorizontalList(),
  ]),
  spatial(),
];

export const MIDDLEWARE_CONTENTGRID_PAGE = [
  scroll([
    scrollFocusedIntoVerticalViewport(),
    scrollFocusedIntoHorizontalList(),
    scrollToTopOnFirstLineOfContentGrid(),
  ]),
  spatial(),
];

export const MIDDLEWARE_STRATE_TOP = [
  scroll([pageScrollTop, scrollFocusedIntoHorizontalList()]),
  spatial(),
];

export const MIDDLEWARE_LIVE_GRID_PERSO = [
  dynamic({}),
  scroll([
    scrollFocusedIntoVerticalViewport(),
    scrollFocusedIntoHorizontalList(),
  ]),
  spatial(),
];

export const MIDDLEWARE_STRATE_PERSO = [
  dynamic({
    listSelector: 'ul',
  }),
  scroll([
    scrollFocusedIntoVerticalViewport(),
    scrollFocusedIntoHorizontalList(),
  ]),
  spatial(),
];

export const MIDDLEWARE_STRATE_PERSO_TOP = [
  dynamic({
    listSelector: 'ul',
  }),
  scroll([pageScrollTop, scrollFocusedIntoHorizontalList()]),
  spatial(),
];

export const MIDDLEWARE_MODAL = [
  scroll([
    scrollFocusedIntoVerticalScrollContainer('#modal-scroll-container'),
    scrollFocusedIntoHorizontalList(),
  ]),
  spatial(),
];

export const MIDDLEWARE_MODAL_TOP = [
  scroll([scrollModalTop, scrollFocusedIntoHorizontalList()]),
  spatial(),
];

export const MIDDLEWARE_SHOWCASE = [
  scroll([centerBinder, scrollFocusedIntoHorizontalList()]),
  spatial(),
];

export const MIDDLEWARE_SHOWCASE_TOP = [scroll([pageScrollTop]), spatial()];

export const MIDDLEWARE_ACTION_LAYOUT = [
  dynamic({ listSelector: 'div' }),
  scroll([scrollTop]),
  spatial(),
];

export const MIDDLEWARE_PROFILES_LIST = [
  scroll([
    scrollFocusedIntoVerticalViewport(),
    scrollFocusedIntoHorizontalList('ul'),
  ]),
  memory(),
  spatial(),
];

export const MIDDLEWARE_PROFILES_SELECTOR = [
  scroll([
    scrollFocusedIntoVerticalViewport(),
    scrollFocusedIntoHorizontalList(),
  ]),
  enter({ forceFocusIndex: 0, shouldForceFocusOnce: true }),
  memory(),
  spatial(),
];

export const MIDDLEWARE_VIRTUAL_KEYBOARD = [
  scroll([scrollFocusedIntoVerticalViewport()]),
  enter({ forceFocusIndex: 1, shouldForceFocusOnce: true }),
  memory(),
  spatial(),
];

export const MIDDLEWARE_SETTINGS = [
  scroll([scrollFocusedIntoVerticalScrollContainer('#modal-scroll-container')]),
  spatial(),
];

export const MIDDLEWARE_FUNNEL_VOD = [
  scroll([
    scrollFocusedIntoVerticalScrollContainer('#modal_funnel_tvod', {
      margins: {
        top: 45, // 720px
        bottom: 17.5, // 280px
      },
    }),
  ]),
  spatial(),
];

export const MIDDLEWARE_FUNNEL_VOD_SEASONS_TABS = [
  scroll([scrollFocusedIntoHorizontalList()]),
  spatial(),
];

export const MIDDLEWARE_FUNNEL_PROMOTIONAL = [
  enter({ forceFocusIndex: 2 }),
  spatial(),
];
export const KEYBOARD_MIDDLEWARE = [memory(), spatial()];

export const MIDDLEWARE_ERROR = [
  dynamic({}),
  enter({ forceFocusIndex: 1, shouldForceFocusOnce: true }),
  spatial(),
];

export const MIDDLEWARE_MOOD = [enter({ forceFocusIndex: 1 }), spatial()];

export const MIDDLEWARE_TIMELINE = [
  scroll([scrollFocusedIntoVerticalViewport()]),
  spatial(),
];
