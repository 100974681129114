import loadable from '@loadable/component';
import Spinner from '../../../Spinner/Spinner';

const LoadableD2GConfirmationDeletionModal = loadable(
  () => import('./D2GConfirmationDeletionModal'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableD2GConfirmationDeletionModal;
