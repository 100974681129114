import {
  THUMBOR_QUALITY_TO_DPR,
  getThumborSources,
  getThumborUrl,
  isDeviceToViewportToImageDimensions,
  isViewportToImageDimensions,
} from '@canalplus/dive';
import { THUMBOR, VIEWPORTS } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import styles from './ImageType.module.css';
import { ImageTypeProps } from './types';

export function ImageType({
  altImage = '',
  className,
  dimensions,
  id,
  imageQualityPercentage: quality = THUMBOR.QUALITY_DEFAULT,
  isArtDirection = false,
  isFixedDimensions = false,
  isDesktopOnly = false,
  isMobileOnly = false,
  isTvDevice = false,
  loading = 'lazy',
  maxImageRatio,
  onLoad,
  URLImage,
  dataTestId,
}: ImageTypeProps): JSX.Element | null {
  // Return null when BO contribution is faulty
  if (
    !URLImage?.default ||
    (isViewportToImageDimensions(dimensions) && !dimensions.XLarge) ||
    (isDeviceToViewportToImageDimensions(dimensions) &&
      !dimensions.default?.XLarge)
  ) {
    return null;
  }

  const devicePixelRatio =
    !!maxImageRatio && THUMBOR_QUALITY_TO_DPR[quality] > maxImageRatio
      ? maxImageRatio
      : THUMBOR_QUALITY_TO_DPR[quality];

  const thumbor = getThumborUrl({
    url: URLImage?.default,
    includesAllowedSourceInUrl:
      THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE.some((endpoint) =>
        URLImage?.default?.includes(endpoint)
      ),
    dimensions: isDeviceToViewportToImageDimensions(dimensions)
      ? dimensions.default?.large
      : dimensions?.large,
    quality,
    devicePixelRatio,
  });

  const showSingleImgElement =
    isTvDevice || isFixedDimensions || !isArtDirection;

  const img = (
    <img
      loading={loading}
      ref={(ref) => ref?.complete && onLoad?.()}
      src={
        // Favour highest src resolution when rendering single <img> element
        showSingleImgElement && thumbor?.urlRetina
          ? thumbor?.urlRetina
          : thumbor.url
      }
      data-testid={dataTestId}
      alt={altImage}
      className={classNames(styles.ImageType, className)}
      onLoad={onLoad}
      id={id}
    />
  );

  if (showSingleImgElement) {
    return img;
  }

  const thumborSources = getThumborSources({
    dimensions,
    urlsWithAllowedSource: THUMBOR.URLS_ALREADY_INCLUDING_ALLOWED_SOURCE,
    urls: URLImage,
    quality,
    devicePixelRatio,
    viewports: (() => {
      if (isDesktopOnly) return VIEWPORTS.DESKTOP_ASCENDING;
      if (isMobileOnly) return VIEWPORTS.MOBILE_ASCENDING;
      return VIEWPORTS.ASCENDING;
    })(),
  });

  return (
    <picture id={id}>
      {thumborSources?.map(({ media, srcset }) => (
        <source key={media} srcSet={srcset?.join(', ')} media={media} />
      ))}
      {img}
    </picture>
  );
}
