import { HeaderNav, HeaderProps } from '@canalplus/dive';
import { DATA_ATTR_HEADER_NAVIGATION_WRAPPER } from '@canalplus/mycanal-sharedcomponent';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getHeaderActiveIndex,
  getHeaderNavItems,
} from '../../../helpers/navigation/navigation-helper';
import I18n from '../../../lang';
import {
  activeNavigationPathSelector,
  rootPathSelector,
} from '../../../selectors/header-selectors';
import { navigationSelector } from '../../../store/slices/application-selectors';

const MANDATORY_HEADER_SEARCH_DATA_ATTRIBUTE = {
  [DATA_ATTR_HEADER_NAVIGATION_WRAPPER]: true,
};

export type HeaderNavigationOneDiscoveryProps = {
  isTransparent?: HeaderProps['isTransparent'];
};

function HeaderNavigationOneDiscovery({
  isTransparent,
}: HeaderNavigationOneDiscoveryProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const activePath = useSelector(activeNavigationPathSelector);
  const rootPath = useSelector(rootPathSelector);
  const navigation = useSelector(navigationSelector);
  const path = activePath || rootPath;

  const navItems = useMemo(() => getHeaderNavItems(navigation), [navigation]);
  const activeIndex = useMemo(
    () => getHeaderActiveIndex(rootPath, path, navigation),
    [navigation, rootPath, path]
  );
  const dropdownLabel = t('Navigation.dropdownLabel');
  const ariaLabel = t('A11y.navigationMenu');

  return (
    <HeaderNav
      variant="priority"
      aria-label={ariaLabel}
      activeIndex={activeIndex}
      items={navItems}
      dropdownLabel={dropdownLabel}
      shouldCenterActiveItem
      isTransparent={isTransparent}
      {...MANDATORY_HEADER_SEARCH_DATA_ATTRIBUTE}
    />
  );
}

export default HeaderNavigationOneDiscovery;
