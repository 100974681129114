import { useContext } from 'react';
import { LayerContext } from '../context/LayerContext';

/**
 * Retrieves context layer
 * @returns layer object
 */
export function useLayerId(): number {
  return useContext(LayerContext) || 0;
}
