import loadable from '@loadable/component';
import Spinner from '../../../Spinner/Spinner';

const LoadableD2GInformationModal = loadable(
  () => import('./D2GInformationModalConnected'),
  {
    fallback: <Spinner delay={750} />,
  }
);

export default LoadableD2GInformationModal;
