import { Platform } from '@canalplus/sdk-core';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { injectScript } from '../../../helpers/scripts/scripts-helper';
import { getOnePlayerUrl } from '../../../helpers/url/url-helper';
import { loadPlayerSuccess } from '../../../store/slices/application';
import { platformSelector } from '../../../store/slices/application-selectors';

/** Inject the player script into the DOM. */
export const usePlayerScript = (): void => {
  const [isScriptInjected, setIsScriptInjected] = useReducer(() => true, false);

  const dispatch = useAppDispatch();
  const platform = useSelector(platformSelector);

  const onePlayerSrc = getOnePlayerUrl(platform);

  useEffect(() => {
    (async function performPlayerScript() {
      if (!isScriptInjected) {
        if (platform !== Platform.Web) {
          dispatch(loadPlayerSuccess());
          setIsScriptInjected();

          return;
        }

        await injectScript(onePlayerSrc, { isAsync: true }).then(() => {
          dispatch(loadPlayerSuccess());
          setIsScriptInjected();
        });
      }
    })();
  }, [dispatch, platform, isScriptInjected, onePlayerSrc]);
};
