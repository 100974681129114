import { ApiV2WsFromPath } from '@dce-front/hodor-types';
import { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { XOR } from 'ts-essentials';
import type { Immersive } from '../../store/slices/immersive';
import type { Page } from '../../store/slices/page';

type ComputeApiV2OnClickParameters = XOR<
  { store: Page | Immersive },
  { redirectTo: ApiV2WsFromPath['redirectTo'] }
>;

export const computeApiV2OnClick = ({
  store,
  redirectTo,
}: ComputeApiV2OnClickParameters): ApiV2OnClick => {
  if (store) {
    const { trackingContext, ...restMainOnClick } = store.mainOnClick || {};

    return restMainOnClick;
  }

  const { perso, ...restRedirectTo } = redirectTo;

  return {
    ...restRedirectTo,
  };
};
