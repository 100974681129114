import { Template } from '@canalplus/sdk-hodor';
import {
  ApiV2NavigationNotification,
  ApiV2NavigationNotificationsOnTrigger,
} from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import {
  saveNotificationNextDisplayDate,
  shouldShowNotification,
} from '../../../helpers/notification/notification-helper';
import { FetchDetails } from '../../../services/types';
import { setSlideShowNotificationOpen } from '../../../store/slices/application';
import { getStartupNotificationsSelector } from '../../../store/slices/application-selectors';
import { accountIdSelector } from '../../../store/slices/user-selectors';
import LoadableSlideshowNotifications from './LoadableSlideshowNotifications';

function SlideshowNotificationsContainer(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const startupNotifications = useSelector(getStartupNotificationsSelector);
  const [startupNotificationList, setStartupNotificationList] =
    useState(startupNotifications);
  const [currentSlideshow, setCurrentSlideshow] = useState<
    ApiV2NavigationNotification | undefined
  >(undefined);
  const accountId = useSelector(accountIdSelector);

  // update local state startupNotificationList if startupNotifications from store changes
  useEffect(() => {
    setStartupNotificationList(startupNotifications);
  }, [startupNotifications]);

  // Check if has startupNotifications slideshow to display
  useEffect(() => {
    if (
      startupNotificationList &&
      !currentSlideshow &&
      accountId !== undefined
    ) {
      const newStartupSlideshowToDisplay = startupNotificationList.find(
        ({ contentID, onTrigger, triggers }) =>
          shouldShowNotification({
            accountId,
            appNextStartup: triggers?.appNextStartup,
            contentID: contentID,
            daysBetweenTriggers: (triggers as any)?.daysBetweenTriggers, // @todo remove 'as any' when hodor types 'triggers' will have property daysBetweenTriggers
            displayTemplate: onTrigger?.displayTemplate,
          })
      );

      if (
        newStartupSlideshowToDisplay?.contentID &&
        newStartupSlideshowToDisplay.triggers
      ) {
        setCurrentSlideshow(newStartupSlideshowToDisplay);
        dispatch(setSlideShowNotificationOpen(true));

        // Not save slideshow displaying if the popin RGPD is displayed.
        // Because the popin RGPD will reload the app after accept consent and we have to see slideshow again.
        if (!window.Didomi?.shouldUserStatusBeCollected()) {
          saveNotificationNextDisplayDate({
            accountId,
            contentID: newStartupSlideshowToDisplay.contentID,
            displayTemplate:
              newStartupSlideshowToDisplay.onTrigger?.displayTemplate,
            daysBetweenTriggers: (newStartupSlideshowToDisplay.triggers as any)
              .daysBetweenTriggers, // @todo remove 'as any' when hodor types 'triggers' will have property daysBetweenTriggers
          });
        }
      }
    }
  }, [
    startupNotificationList,
    currentSlideshow,
    setCurrentSlideshow,
    accountId,
    dispatch,
  ]);

  /**
   * In case the currentSlideshow is on error, check if a next slideshow can be displayed
   */
  const displayNextSlideshow = useCallback(() => {
    if (startupNotificationList && startupNotificationList.length > 1) {
      // Remove the slideshow on error from the list
      const newStartupNotifications: ApiV2NavigationNotification[] = [];
      startupNotificationList.forEach((item) => {
        if (item.contentID !== currentSlideshow?.contentID) {
          newStartupNotifications.push(item);
        }
      });

      setStartupNotificationList(newStartupNotifications);
      setCurrentSlideshow(undefined);
    }
  }, [
    startupNotificationList,
    currentSlideshow,
    setStartupNotificationList,
    setCurrentSlideshow,
  ]);

  const { onTrigger, contentID } = currentSlideshow || {};

  // @todo remove this when hodor-types ApiV2NavigationNotificationsOnTrigger will be updated with 'parameters'
  const onTriggerWithParameters =
    onTrigger as ApiV2NavigationNotificationsOnTrigger & {
      parameters: FetchDetails['onClickParameters'];
    };

  return onTrigger?.URLPage && contentID ? (
    <LoadableSlideshowNotifications
      from={Template.Slideshow}
      url={onTrigger.URLPage}
      onClickParameters={onTriggerWithParameters.parameters}
      onError={displayNextSlideshow}
    />
  ) : null;
}

export default SlideshowNotificationsContainer;
