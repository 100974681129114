import { throttle } from '@canalplus/mycanal-commons';
import { useEffect } from 'react';

/**
 * Hook to execute a callback on page scroll
 * @param callback
 * @param isActive
 * @param throttleDuration
 * @example useOnPageScroll((scrollDepth) => console.log(scrollDepth), isActive);
 */
export const useOnPageScroll = (
  callback: (scrollDepth: number) => void,
  isActive = true,
  throttleDuration: number = 250
): void => {
  useEffect(() => {
    if (isActive) {
      const onScroll = throttle((e) => {
        callback(e.target.documentElement.scrollTop);
      }, throttleDuration);
      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }
    return;
  }, [callback, isActive, throttleDuration]);
};
