import { isClientSide } from '@canalplus/mycanal-commons';

export const SCRIPT_IS_MISSING = 'Script source is missing';
export const CANNOT_USE_DOM = 'Cannot load script on Server Side Rendering';
export const errorOnLoad = (src: string): string =>
  `error while loading script: ${src}`;

/**
 * scriptExist
 *
 * this function take a script url and check if it already exist
 *
 * @param  {string} src            script uri
 * @returns {bool}               true if script already exist or no script
 *                                 specified as an argument to the function
 */
export const scriptExist = (src: string = ''): boolean => {
  if (!src) return true;

  const scriptsList = document.querySelectorAll('script');
  // Convert NodeList object to Array
  const scripts = Object.keys(scriptsList).map((key) => scriptsList[key]);
  return !!scripts.find((script) => script.src.substr(-src.length) === src);
};

/**
 * Inject a script by url in document.body if it don't already exist.
 *
 * @param  {string}  src - Script uri
 * @param  {object}  [options] - A set of options for injection
 * @param  {bool} [options.async=false] - Whether or not the <script /> tag should be added with `async` attribute
 * @returns {promise} - Resolves if loaded, rejects otherwise or if not an url
 */
export const injectScript = async (
  src: string,
  { isAsync = false } = {}
): Promise<HTMLElement | string | void> =>
  new Promise((resolve, reject) => {
    if (!src) {
      reject(SCRIPT_IS_MISSING);
      return;
    }

    if (!isClientSide()) {
      // Don't reject here anymore because it's not an error
      resolve();
      return;
    }

    if (scriptExist(src)) {
      resolve(src);
      return;
    }

    const script = document.createElement('script');

    script.src = src;
    if (isAsync) {
      script.setAttribute('async', 'true');
    }
    script.onload = () => resolve();
    script.onerror = () => reject(errorOnLoad(src));

    return document.body.appendChild(script);
  });
