import classNames from 'classnames';
import { ForwardedRef, cloneElement, forwardRef } from 'react';
import { useDiveContext } from '../../../context';
import { MaterialChevronRightSvg, StatusCheckSvg } from '../../../icons/icons';
import {
  leftElementContainerCVA,
  listCVA,
  listTextCVA,
  listTextRightColorCVA,
  listTitleCVA,
  rightContainerColorCVA,
  rightElementContainerCVA,
  spacingLeftContainerCVA,
} from './List.cva';
import { ListProps } from './List.types';

/**
 * A List can represent continuous groups or hierarchies of text.
 * It can be composed of multiple informative texts and it can enable user interactions.
 *
 * @example
 * <List title="Title" label="Label" leftIcon={<LinkSelfcareSvg />} />
 */
export const List = forwardRef<HTMLButtonElement | HTMLDivElement, ListProps>(
  function List(
    {
      id,
      title,
      subtitle,
      label,
      onClick,
      actionableElement,
      lineThroughLabel,
      leftIcon,
      displayChevron = false,
      isChecked = false,
      isDisabled = false,
      layout = 'inline',
      className,
      checkIconAriaLabel = 'selected',
      'data-testid': dataTestId,
      ...a11y
    },
    ref
  ): JSX.Element {
    const { isTv, device } = useDiveContext();

    const hasRightElement = displayChevron || isChecked || !!actionableElement;
    const hasLineThrough = !!lineThroughLabel;
    const rightIconToBeDisplayed = displayChevron ? (
      <MaterialChevronRightSvg
        aria-hidden
        className={rightContainerColorCVA({
          device,
          hasLineThrough,
          className: 'w-full h-full',
        })}
      />
    ) : (
      isChecked && (
        <StatusCheckSvg
          role="img"
          aria-label={checkIconAriaLabel}
          className={classNames(
            'h-full, w-full',
            isTv
              ? 'fill-dt-theme-tv-icon-list-icon tv-focus-parent:fill-dt-theme-tv-icon-list-icon-focus'
              : 'fill-dt-theme-icon-list-row-check-icon-accent'
          )}
        />
      )
    );

    const content = (
      <>
        <div className="flex relative items-center overflow-x-clip mr-16">
          {leftIcon &&
            !isTv &&
            cloneElement(leftIcon, {
              className: classNames('absolute w-32 h-32', { 'hidden': isTv }),
              'aria-hidden': true,
            })}
          <div
            className={leftElementContainerCVA({
              device,
              layout,
              className: classNames({ 'ml-48': !!leftIcon && !isTv }),
            })}
          >
            <span className={listTitleCVA({ device })}>{title}</span>
            {subtitle && (
              <span
                className={listTextCVA({
                  device,
                  className: `${spacingLeftContainerCVA({ device, layout })} line-clamp-1`,
                })}
              >
                {subtitle}
              </span>
            )}
          </div>
        </div>
        <div className="justify-end flex relative items-center">
          <div
            className={classNames(
              'flex items-end',
              hasRightElement && (isTv ? 'mr-50' : 'mr-32')
            )}
          >
            {lineThroughLabel && (
              <s
                className={listTextCVA({
                  device,
                  className: classNames('mr-dt-spacing-100'),
                })}
              >
                {lineThroughLabel}
              </s>
            )}

            {label && (
              <span
                className={listTextRightColorCVA({ device, hasLineThrough })}
              >
                {label}
              </span>
            )}
          </div>

          {hasRightElement && !(isTv && actionableElement) && (
            <div className={rightElementContainerCVA({ device })}>
              {rightIconToBeDisplayed || actionableElement}
            </div>
          )}
        </div>
      </>
    );

    if (actionableElement && !isTv)
      return (
        <div
          id={id}
          data-testid={dataTestId}
          ref={ref as ForwardedRef<HTMLDivElement>}
          className={classNames(listCVA({ device, isDisabled, className }))}
        >
          {content}
        </div>
      );

    return (
      <button
        id={id}
        data-testid={dataTestId}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        type="button"
        onClick={onClick}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        className={classNames(
          'w-full',
          listCVA({ device, isDisabled, className })
        )}
        {...a11y}
      >
        {content}
      </button>
    );
  }
);
