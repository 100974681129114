import { useHistory, useLocation } from 'react-router';
import type {
  AppHistory,
  AppLocation,
  LocationState,
} from '../../typings/routing';

/** Pre-typed `useLocation` */
export const useAppLocation = (): AppLocation =>
  useLocation<LocationState | undefined>();

/** Pre-typed `useHistory` */
export const useAppHistory = (): AppHistory =>
  useHistory<LocationState | undefined>();
