import { isClientSide } from '@canalplus/mycanal-commons';
import { isEqual } from 'es-toolkit';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../useAppDispatch';
import { AllQueryHandlerResult } from '../useMetas';
import { updateAndFormatAlternateLinks } from './updateAndFormatAlternateLinks';

export const useAlternateLinks = (
  enableAlternateLinksUpdate: boolean,
  data?: AllQueryHandlerResult
): void => {
  const dispatch = useAppDispatch();
  const previousData = useRef({});

  const updateAlternateLinks = useCallback(() => {
    if (enableAlternateLinksUpdate && data) {
      const hasDataChanged = !isEqual(data, previousData.current);
      previousData.current = data;

      const currentPage = data.pages?.[0]?.currentPage || data.currentPage;

      if (hasDataChanged && currentPage) {
        dispatch(updateAndFormatAlternateLinks(currentPage.alternate));
      }
    }
  }, [data, enableAlternateLinksUpdate, dispatch]);

  // ⚠️ `updateAlternateLinks` should be executed on both server and client side
  useEffect(() => {
    updateAlternateLinks();
  }, [updateAlternateLinks]);

  if (!isClientSide()) {
    updateAlternateLinks();
  }
};
