import { History } from 'history';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import { immersiveSelector } from '../../../../../store/slices/immersive-selectors';
import { pageSelector } from '../../../../../store/slices/page-selectors';
import type { AppLocation } from '../../../../../typings/routing';
import { isOnClickDifferFromState } from '../useGetPageContent/useGetPageContent';

/*
 * The page scroll must go back to the top each time the user navigates to a new page (excluding TV which manages its own scrolling handler).
 * This excludes navigation when closing an immersive (because the main background page remains the same) or during browser backward/forward
 * navigation because the app benefits from history scrollRestoration.
 */

export default function usePageScrollTop(
  history: History,
  location: AppLocation
): void {
  const isTvDevice = useSelector(displayTVModeSelector);
  const page = useSelector(pageSelector);
  const immersive = useSelector(immersiveSelector);

  useEffect(() => {
    if (isTvDevice) {
      return;
    }

    const onClickImmersive = location.state?.immersive?.mainOnClick;
    const onClickPage = location.state?.page?.mainOnClick;

    const isBrowserNavigation = history.action === 'POP';
    if (isBrowserNavigation) {
      return;
    }

    const willPageTemplateBeUpdated =
      !!onClickPage &&
      isOnClickDifferFromState(onClickPage, page) &&
      history.action !== 'REPLACE';

    const willImmersiveTemplateBeUpdated =
      !!onClickImmersive &&
      isOnClickDifferFromState(onClickImmersive, immersive.mainOnClick);

    if (willPageTemplateBeUpdated && !willImmersiveTemplateBeUpdated) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  // Scroll to top before page refresh (SSR) to prevent the browser from restoring the scroll position
  useEffect(() => {
    if (isTvDevice) {
      return;
    }
    const onPageHide = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener('pagehide', onPageHide);
    return () => window.removeEventListener('pagehide', onPageHide);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
