import loadable from '@loadable/component';
import Spinner from '../../Spinner/Spinner';

const LoadableUserExitsSettingsModal = loadable(
  () => import('./UserExitsSettingsModal'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableUserExitsSettingsModal;
