export enum TemplateTypes {
  DARK = 'dark',
  DISABLED = 'disabled',
  LAUNCH_ONE_SHOP = 'launchOneShop',
  PRIMARY = 'primary',
  PRIMARY_INVERTED = 'primaryInverted',
  SECONDARY = 'secondary',
  SECONDARY_INVERTED = 'secondaryInverted',
  SECONDARY_DISABLED = 'secondaryDisabled',
  TV = 'tv',
  DEFAULT = 'default',
}
