import { isSomeEnum } from '@canalplus/mycanal-commons';
import { PersoLists, Template as TemplateEnum } from '@canalplus/sdk-hodor';
import { ErrorInfo, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Logger from '../../helpers/logger/logger-helper';
import { FromProp } from '../../server/modules/fetchWithQuery/types';
import {
  errorTemplateTextSelector,
  errorTemplateTitleSelector,
} from '../../store/slices/error-selectors';
import LoadableContentGrid from '../../templates/ContentGrid';
import LoadableContentGridPerso from '../../templates/ContentGridPerso';
import LoadableCreativeMediaConnected from '../../templates/CreativeMedia';
import LoadableDetailLight from '../../templates/DetailLight';
import LoadableDetailV5 from '../../templates/DetailV5';
import LoadableDownloadToGo from '../../templates/DownloadToGo';
import LoadableError from '../../templates/Error';
import LoadableFunnelTvod from '../../templates/FunnelTvod/components/LoadableFunnelTvod';
import { FunnelHodorStep } from '../../templates/FunnelTvod/stores/constants';
import LoadableGabaritList from '../../templates/GabaritList';
import LoadableLandingV5 from '../../templates/LandingV5';
import LoadableMosaic from '../../templates/Mosaic';
import LoadableMyAccountIdp from '../../templates/MyAccountIdp';
import LoadablePrivacyManager from '../../templates/PrivacyManager';
import LoadableSearchStandalone from '../../templates/SearchStandalone';
import LoadableSectionsList from '../../templates/SectionsList';
import LoadableSettings from '../../templates/Settings';
import LoadableShowcase from '../../templates/Showcase';
import LoadableSlideshow from '../../templates/Slideshow';
import LoadableStub from '../../templates/Stub';
import LoadableTextBrut from '../../templates/TextBrut';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { LoadableMyAccountConnected } from '../MyAccount/LoadableMyAccountConnected';
import { log } from '../VisualDebugger/html-logger';

export type TemplateProps = {
  animationEnded?: boolean;
  onFocusable?: () => void;
} & Pick<
  Routing.IOneDiscoveryOnClick,
  'displayName' | 'displayTemplate' | 'perso' | 'URLPage' | 'parameters'
>;

/** Switch between displayTemplate */
function Template({
  displayName = '',
  onFocusable,
  perso,
  parameters,
  URLPage = '',
  displayTemplate,
}: TemplateProps): JSX.Element | null {
  const errorTemplateText = useSelector(errorTemplateTextSelector);
  const errorTemplateTitle = useSelector(errorTemplateTitleSelector);

  const onError = useCallback(
    (error: Error, info: ErrorInfo): void => {
      Logger.error(
        `An error has occurred during while rendering the ${displayTemplate} template`
      );
      log({
        level: 'error',
        tags: ['<Template />'],
        message: `${error.message}${info.componentStack}`,
      });
    },
    [displayTemplate]
  );

  // `displayTemplate` is empty until we receive the hodor response
  if (!displayTemplate) {
    return null;
  }

  const commonProps = {
    from: 'root',
    onClickParameters: parameters,
    url: URLPage,
  } satisfies {
    url: Routing.IOneDiscoveryOnClick['URLPage'];
    onClickParameters: Routing.IOneDiscoveryOnClick['parameters'];
  } & FromProp;

  const persoType = isSomeEnum(PersoLists)(perso) ? perso : undefined;

  const renderTemplate = (): JSX.Element => {
    switch (displayTemplate) {
      case TemplateEnum.ContentGrid:
        if (persoType) {
          return (
            <LoadableContentGridPerso
              header={{ title: displayName }}
              onFocusable={onFocusable}
              persoType={persoType}
              {...commonProps}
            />
          );
        }
        return (
          <LoadableContentGrid
            header={{ title: displayName }}
            onFocusable={onFocusable}
            {...commonProps}
          />
        );
      case TemplateEnum.Mosaic:
        return <LoadableMosaic nbPlaceholderItems={20} {...commonProps} />;

      case TemplateEnum.DetailLight:
        return (
          <LoadableDetailLight {...commonProps} onFocusable={onFocusable} />
        );

      case TemplateEnum.DetailSeason:
      case TemplateEnum.DetailShow:
      case TemplateEnum.DetailPage:
      case TemplateEnum.Quicktime:
        return <LoadableDetailV5 onFocusable={onFocusable} {...commonProps} />;

      case TemplateEnum.PaymentMeans:
      case TemplateEnum.ContextualOfferPage: {
        return (
          <LoadableFunnelTvod
            from="root"
            hodorStep={displayTemplate as FunnelHodorStep}
            url={URLPage}
            fullPage
          />
        );
      }

      case TemplateEnum.CreativeMedia:
        return <LoadableCreativeMediaConnected {...commonProps} />;

      case TemplateEnum.GabaritList:
        return (
          <LoadableGabaritList onFocusable={onFocusable} {...commonProps} />
        );

      case TemplateEnum.Error:
        return (
          <LoadableError
            from={commonProps.from}
            url={URLPage}
            title={errorTemplateTitle}
            text={errorTemplateText}
          />
        );

      case TemplateEnum.Landing:
        return <LoadableLandingV5 onFocusable={onFocusable} {...commonProps} />;

      case TemplateEnum.TextBrut:
        return <LoadableTextBrut {...commonProps} />;

      case TemplateEnum.Settings:
        return <LoadableSettings {...commonProps} />;

      case TemplateEnum.MyAccount:
        return <LoadableMyAccountConnected />;

      case TemplateEnum.MyAccountIdp:
        return (
          <LoadableMyAccountIdp onFocusable={onFocusable} {...commonProps} />
        );

      case TemplateEnum.Showcase:
        return <LoadableShowcase onFocusable={onFocusable} {...commonProps} />;

      case TemplateEnum.DownloadToGo:
        return <LoadableDownloadToGo {...commonProps} />;

      case TemplateEnum.SectionsList:
        return (
          <LoadableSectionsList onFocusable={onFocusable} {...commonProps} />
        );

      case TemplateEnum.Search:
        return (
          <LoadableSearchStandalone
            onFocusable={onFocusable}
            {...commonProps}
          />
        );

      case TemplateEnum.Slideshow:
        return <LoadableSlideshow onFocusable={onFocusable} {...commonProps} />;

      case TemplateEnum.Stub:
        return <LoadableStub onFocusable={onFocusable} {...commonProps} />;

      case TemplateEnum.PrivacyManager:
        return (
          <LoadablePrivacyManager onFocusable={onFocusable} {...commonProps} />
        );

      default:
        return <LoadableError from={commonProps.from} />;
    }
  };

  return <ErrorBoundary onError={onError}>{renderTemplate()}</ErrorBoundary>;
}

export default memo(Template);
