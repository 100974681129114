import classNames from 'classnames';
import styles from './StickyContainer.module.css';

export type StickyContainerProps = {
  isFullHeight?: boolean;
  children?: React.ReactNode;
};

/** A simple container that positions itself at the top of the page.  Useful for headers ;) */
export function StickyContainer({
  children,
  isFullHeight = false,
}: StickyContainerProps): JSX.Element {
  return (
    <div
      className={classNames(styles.StickyContainer, {
        [styles['StickyContainer--fullHeight']]: isFullHeight,
      })}
    >
      {children}
    </div>
  );
}
