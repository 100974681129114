import { useEffect } from 'react';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { getLocalStorageSettings } from '../../../helpers/settings/settings-helper';
import { userChangeSettings } from '../../../store/slices/user';

/**
 * Dispatch all localStorage's settings to the store\
 * Used on mount only.
 */
export const useUserChangeSettings = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const localSettings = getLocalStorageSettings();

    if (localSettings) {
      dispatch(userChangeSettings(localSettings));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
