import { HodorApiParameter } from '@canalplus/sdk-hodor';

/**
 * Hodor Api parameters used for a perso call
 * - **tokenPass**
 * - **xx-profile-id**
 */
export const PERSO_HODOR_API_PARAMETERS: HodorApiParameter[] = [
  { id: 'xx-profile-id', in: 'header' },
  { id: 'tokenPass', in: 'header' },
];
