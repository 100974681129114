import { KEYCODE } from '@canalplus/mycanal-commons';
import {
  getFocusableElements,
  useBodyNoScroll,
  useFocusTrap,
  useKeyboardEvent,
} from '@canalplus/mycanal-util-react';
import classNames from 'classnames';
import {
  AriaAttributes,
  CSSProperties,
  HTMLAttributes,
  cloneElement,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import IconArrow from '../../assets/svg/arrow.svg';
import IconClose from '../../assets/svg/close.svg';
import styles from './Modal.module.css';

export enum ModalV2Size {
  FitContent = 'fitContent',
  Small = 'small',
  Medium = 'medium',
  FullScreen = 'fullScreen',
  D2G = 'd2g',
}

export enum ModalV2Type {
  Funnel = 'funnel',
  Purchase = 'purchase',
  Profile = 'profile',
  LiveGrid = 'liveGrid',
  Account = 'account',
  Mood = 'mood',
  MoreInfos = 'moreInfos',
  TextBrut = 'textBrut',
  Sharing = 'sharing',
  D2G = 'd2g',
  Adult = 'adult',
  GDPR = 'gdpr',
}

export type ModalV2Props = {
  backButtonIcon?: React.ReactElement;
  children: React.ReactNode;
  closeButtonIcon?: React.ReactElement;
  disableAutoFocus?: boolean;
  hideCloseButton?: boolean;
  hidePadding?: boolean;
  idFrom?: HTMLElement | null;
  isTvDevice?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  portalElementId?: string;
  portalId?: string;
  size?: `${ModalV2Size}`;
  style?: CSSProperties;
  type?: `${ModalV2Type}`;
  title?: string | null;
  closeAriaLabel?: string;
  backAriaLabel?: string;
} & Pick<HTMLAttributes<HTMLDivElement>, 'role' | 'id' | 'className'> &
  Pick<AriaAttributes, 'aria-label' | 'aria-describedby'>;

const focusFromElement = (idFrom: HTMLElement | null | undefined): void =>
  idFrom?.focus();

export const DEFAULT_PORTAL_ELEMENT_ID = 'default-portal';

export function ModalV2({
  backButtonIcon = <IconArrow />,
  children,
  className,
  hideCloseButton = false,
  hidePadding = false,
  id,
  isTvDevice = false,
  onBack,
  onClose,
  portalElementId = DEFAULT_PORTAL_ELEMENT_ID,
  portalId,
  size,
  style,
  type,
  title,
  closeAriaLabel = 'close',
  backAriaLabel = 'back',
  idFrom,
  role = 'dialog',
  disableAutoFocus = false,
  closeButtonIcon = <IconClose />,
  ...a11y
}: ModalV2Props): JSX.Element {
  const ModalRef = useRef<HTMLDivElement>(null);

  useFocusTrap(ModalRef, { isTvDevice, autoFocus: !disableAutoFocus });
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  // to be removed in TVOD urba, when step tvod created
  const noScrollSuffix = ['modalv2', type, id?.toLocaleLowerCase().trim()]
    .filter(Boolean)
    .join('-');
  useBodyNoScroll({
    ignore: type === ModalV2Type.Purchase,
    suffix: noScrollSuffix,
  });

  // To force restore focus on the modal
  const onStartClosePrivacy = () => {
    if (ModalRef.current) {
      const { firstFocusableElement } = getFocusableElements(ModalRef.current);
      firstFocusableElement?.focus();
    }
  };

  /**
   * Listen the close event of privacy manager popin (from TrackingTool)
   * To force restore focus on the modal
   */
  useEffect(() => {
    if (isTvDevice) return;

    window.addEventListener('CLOSE_PRIVACY_SETTINGS', onStartClosePrivacy);

    return () => {
      window.removeEventListener('CLOSE_PRIVACY_SETTINGS', onStartClosePrivacy);
    };
  }, [children, isTvDevice]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    if (idFrom) {
      focusFromElement(idFrom);
    }
  };

  // listen keyboard key Back to close modal
  useKeyboardEvent({
    keycode: KEYCODE.ESCAPE,
    listen: !isTvDevice && Boolean(onClose),
    triggerAction: handleClose,
  });

  const renderButtonClose = () => {
    return (
      onClose &&
      !hideCloseButton && (
        <button
          className={`${styles.modalV2__button} ${styles['modalV2__button--close']}`}
          onClick={handleClose}
          data-testid="ModalV2-close"
          type="button"
          ref={closeButtonRef}
          aria-label={closeAriaLabel}
        >
          {cloneElement(closeButtonIcon, { role: 'img', 'aria-hidden': true })}
        </button>
      )
    );
  };

  /**
   * For A11y purpose we focus default close button, it need to be at first position
   * for purchase and adult we focus on the element to enter the parental code or purchase code
   * */
  const isFirstButtonClose =
    type !== ModalV2Type.Adult && type !== ModalV2Type.Purchase;

  return createPortal(
    <div
      data-testid="ModalV2"
      className={classNames(styles.modalV2, {
        [styles[`modalV2--${size}`]]: size,
        [styles[`modalV2--${type}`]]: type,
        [styles[`modalV2--no-padding`]]: hidePadding,
      })}
      role={role}
      aria-modal="true"
      id={portalId}
      {...a11y}
    >
      <div
        data-testid="ModalV2-overlay"
        className={styles.modalV2__overlay}
        onClick={(e) => {
          e.stopPropagation();
          handleClose();
        }}
      />
      <div
        data-testid="ModalV2-content"
        onClick={(e) => e.stopPropagation()}
        className={classNames(`${styles.modalV2__content}`)}
        ref={ModalRef}
        style={style}
        id={portalId}
      >
        {title && (
          <div className={styles.modalV2__header} data-testid="ModalV2-header">
            <h1
              aria-labelledby={portalId || title}
              className={styles.modalV2__title}
            >
              {title}
            </h1>
          </div>
        )}

        {isFirstButtonClose && renderButtonClose()}

        <div
          data-testid="ModalV2-body"
          className={classNames(`${styles.modalV2__body}`, className)}
          id={id}
        >
          {children}
        </div>

        {!isFirstButtonClose && renderButtonClose()}

        {onBack && (
          <button
            className={`${styles.modalV2__button} ${styles['modalV2__button--back']}`}
            onClick={onBack}
            data-testid="ModalV2-back"
            type="button"
            aria-label={backAriaLabel}
          >
            {cloneElement(backButtonIcon, { role: 'img', 'aria-hidden': true })}
          </button>
        )}
      </div>
    </div>,
    document.getElementById(portalElementId) || document.body
  );
}
