import {
  CLEAR_KEY,
  CONTENT_KEYS,
  FOOTER_KEYS,
  POLISH_CONTENT_KEYS,
  POLISH_LAST_ROW_CONTENT_KEYS,
  SPACE_KEY,
} from './const';
import { Key, Mode } from './types';

/**
 * Add a new character to a **string[]**, depending of an index and the keyboard mode
 *
 * @param id The **id** of the `Key` to add
 * @param input The **array** where to add the new character
 * @param cursorIndex The **index** where to add the new character
 * @param mode The **keyboard mode** (lowercase, uppercase or special)
 * @param isPolish Whether the keyboard is using the **Polish layout** or not
 */
export const addCharacter = (
  id: string,
  input: string[],
  cursorIndex: number,
  mode: Mode,
  isPolish = false
): string[] => {
  const contentKeys = isPolish
    ? [...POLISH_CONTENT_KEYS, ...POLISH_LAST_ROW_CONTENT_KEYS]
    : CONTENT_KEYS;
  const allKeys = [SPACE_KEY, CLEAR_KEY, ...FOOTER_KEYS, ...contentKeys];
  const keyToAdd = allKeys.find((key) => key.id === id);

  if (!keyToAdd) {
    return input;
  }

  const characterToAdd =
    keyToAdd === SPACE_KEY ? ' ' : getFormatedSign(keyToAdd, mode);

  return [
    ...input.slice(0, cursorIndex),
    characterToAdd,
    ...input.slice(cursorIndex),
  ];
};

/**
 * Delete a character of a **string[]**, depending of an index
 *
 * @param input The **array** where to delete the character
 * @param cursorIndex The **index** where to delete the character
 */
export const deleteCharacter = (
  input: string[],
  cursorIndex: number
): string[] => [
  ...input.slice(0, cursorIndex - 1),
  ...input.slice(cursorIndex),
];

/**
 * Get a **formated sign** of a `Key`, depending of the keyboard mode
 *
 * @param key The **key** to format
 * @param mode The **keyboard mode** (lowercase, uppercase or special)
 */
export const getFormatedSign = (key: Key, mode?: Mode): string => {
  const { sign, special = sign } = key;

  if (mode === Mode.SPECIALS) {
    return special;
  }

  if (sign.length === 1 && mode === Mode.LOWERCASE) {
    return sign.toLowerCase();
  }

  return sign;
};
