import { useEffect } from 'react';
import { RenderSource } from '../../../constants/renderSource';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { renderSource } from '../../../store/slices/application';

/**
 * Dispatch the render source to **client**.\
 * Used on mount only.
 */
export const useRenderSource = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(renderSource(RenderSource.CLIENT));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
