import { canUseEventListeners } from '@canalplus/mycanal-commons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initIframeBehavior } from '../../../helpers/iframe/iframe-helper';
import { isFeatIframeHeightAllowedSelector } from '../../../store/slices/contextFeature-selectors';

/**
 * Init iframe behavior\
 * Used on mount only.
 */
export const useIframeBehavior = (): void => {
  const isFeatIframeHeightAllowed = useSelector(
    isFeatIframeHeightAllowedSelector
  );

  useEffect(() => {
    if (canUseEventListeners() && isFeatIframeHeightAllowed) {
      initIframeBehavior();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
