import { useSelector } from 'react-redux';
import { offerLocationSelector } from '../../store/slices/application-selectors';
import { pageURLSelector } from '../../store/slices/page-selectors';
import {
  isKidsProfileSelector,
  notificationSelector,
} from '../../store/slices/user-selectors';
import NotificationDisplayer, {
  NotificationDisplayerProps,
} from './NotificationDisplayer';

export function NotificationDisplayerConnected(
  props: Omit<
    NotificationDisplayerProps,
    'isKids' | 'localeInfo' | 'notification' | 'pageURL' | 'setUserNotification'
  >
): JSX.Element {
  const isKids = useSelector(isKidsProfileSelector);
  const localeInfo = useSelector(offerLocationSelector);
  const notification = useSelector(notificationSelector);
  const pageURL = useSelector(pageURLSelector) || '';

  return (
    <NotificationDisplayer
      {...props}
      isKids={isKids}
      localeInfo={localeInfo}
      notification={notification}
      pageURL={pageURL}
    />
  );
}
