import { getRatio } from '@canalplus/mycanal-commons';
import { ApiV2SpyroSearch } from '@dce-front/hodor-types/api/v2/search/definitions';
import { getContentWithContexts } from '../../../helpers/contents/contents-helper';
import { ISearch } from './types';

export const getSearchFormatted = (data: ApiV2SpyroSearch): ISearch => {
  const {
    contents,
    displayParameters,
    currentPage,
    tracking,
    context,
    paging,
  } = data;
  const { imageRatio } = displayParameters;

  return {
    contents: contents.map((content, index) => {
      if (!content.onClick) {
        return content;
      }

      const trackingContext = {
        ...context,
        context_list_item_position: index + 1,
      };

      return getContentWithContexts(content, trackingContext);
    }),

    displayParameters: {
      imageRatio: getRatio({ imageRatio }),
    },
    title: currentPage.query,
    tracking,
    paging,
  };
};
