import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../store/slices/user';
import {
  areAnimationsAutoplaySelector,
  isTrailerAutoplaySelector,
  settingsSelector,
} from '../../../store/slices/user-selectors';

/**
 * Users can enable/disable the animations autoplay
 * This value is stored in local storage and redux store
 */
export const useAreAnimationsAutoplay = (): {
  /**
   * Can all video animations (excluded the trailer) autoplay
   * in the user settings saved in local storage
   */
  areAnimationsAutoplay: boolean;
  /**
   * Can Trailer feature autoplay
   * in the user settings saved in local storage
   */
  isTrailerAutoplay: boolean;
  /**
   * Can Trailer feature or all other video animations autoplay
   * in the user settings saved in local storage
   */
  isAutoplayEnabled: boolean;
  /**
   * Toggle the autoplay value of all video animations (excluded the trailer)
   * in the user settings saved in local storage
   */
  toggleAreAnimationsAutoplay: () => void;
  /**
   * Toggle the autoplay value of Trailer feature
   * in the user settings saved in local storage
   */
  toggleIsTrailerAutoplay: () => void;
  /**
   * Toggle the autoplay value of all video animations (included the trailer)
   * in the user settings saved in local storage
   */
  toggleAnimationsAndTrailerAutoplay: () => void;
} => {
  const dispatch = useAppDispatch();
  const settings = useSelector(settingsSelector);

  const areAnimationsAutoplay = useSelector(areAnimationsAutoplaySelector);
  const toggleAreAnimationsAutoplay = useCallback(() => {
    dispatch(
      userChangeSettings({
        ...settings,
        areAnimationsAutoplay: !areAnimationsAutoplay,
      })
    );
  }, [areAnimationsAutoplay, dispatch, settings]);

  const isTrailerAutoplay = useSelector(isTrailerAutoplaySelector);
  const toggleIsTrailerAutoplay = useCallback(() => {
    dispatch(
      userChangeSettings({
        ...settings,
        isTrailerAutoplay: !isTrailerAutoplay,
      })
    );
  }, [dispatch, isTrailerAutoplay, settings]);

  const isAutoplayEnabled = areAnimationsAutoplay || isTrailerAutoplay;
  const toggleAnimationsAndTrailerAutoplay = () => {
    dispatch(
      userChangeSettings({
        ...settings,
        areAnimationsAutoplay: !isAutoplayEnabled,
        isTrailerAutoplay: !isAutoplayEnabled,
      })
    );
  };

  return {
    areAnimationsAutoplay,
    isTrailerAutoplay,
    isAutoplayEnabled,
    toggleAreAnimationsAutoplay,
    toggleIsTrailerAutoplay,
    toggleAnimationsAndTrailerAutoplay,
  };
};
