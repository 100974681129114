import { ApiV2PageGabaritList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/gabarit_list/definitions';
import { removeLiveGridFavoritesRubric } from '../../../helpers/rubrics/rubrics-helper';

const formatGabaritListData = (
  data: ApiV2PageGabaritList,
  isLiveGridFavoritesEnabled: boolean
): ApiV2PageGabaritList => {
  const { currentPage, rubriques = [], displayParameters } = data;

  return {
    currentPage,
    displayParameters,
    rubriques: isLiveGridFavoritesEnabled
      ? rubriques
      : removeLiveGridFavoritesRubric(rubriques),
  };
};

export default formatGabaritListData;
