import {
  ImageSize,
  addQueryParam,
  isSomeEnum,
} from '@canalplus/mycanal-commons';
import { Ratio } from '@canalplus/mycanal-sdk';
import {
  HashObjectReturn,
  hashObject,
  mapStaticKey,
} from '@canalplus/mycanal-util-react';
import { PersoLists, TitleDisplayMode } from '@canalplus/sdk-hodor';
import { ApiV2StrateDisplayParameters } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';
import {
  ApiV2PageStrate,
  ApiV2PageTracking,
} from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import {
  ApiV2LandingCurrentPage,
  ApiV2PageLanding,
} from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/landing/definitions';
import {
  ApiV2Strate,
  ApiV2StrateContent,
} from '@dce-front/hodor-types/api/v2/page/dtos/strates/contents/definitions';
import { AvailabilityInfo } from '@dce-front/hodor-types/modules/availabilities/definitions';
import { CoverData } from '../../../components/IdentityCover/types';
import {
  getContentsWithContext,
  setContextForOnClick,
} from '../../../helpers/contents/contents-helper';
import getFormattedPromotionStrate from '../../../templates/LandingV5/components/PromotionStrate/data/getFormattedPromotionStrate';
import { PromotionStrateData } from '../../../templates/LandingV5/components/PromotionStrate/data/types';
import { LocationStateContext } from '../../../typings/routing';
import getFormattedIdentityCover from '../../helpers/IdentityV5Helpers/data/getFormattedIdentityCover';

const NB_ITEMS_IN_STRATES_TO_FETCH = 14;
const NB_ITEMS_IN_STRATES_TO_FETCH_TV = 10;

export type DisplayParameters = Omit<
  ApiV2StrateDisplayParameters,
  'imageSize' | 'imageRatio' | 'titleDisplayMode'
> & {
  // Overload and improve ApiV2StrateDisplayParameters typing for keys:
  imageSize?: ImageSize;
  imageRatio?: Ratio;
  titleDisplayMode?: TitleDisplayMode;
};

export type ContentStrateV5 = ApiV2StrateContent &
  HashObjectReturn<{
    altLogoChannel?: string;
    contentAvailability?: AvailabilityInfo;
    endTime?: number;
    isInOffer?: boolean;
    startTime?: number;
    stickers?: ApiV2SpyroStrateContentSticker[];
    URLImageForDarkMode?: string;
    URLImageForLightMode?: string;
    isLogoTyped?: boolean;
    userProgress?: number;
    lastDays?: boolean;
    context?: LocationStateContext;
  }>;

export type StrateV5 = HashObjectReturn<
  ApiV2Strate & {
    contents?: ContentStrateV5[];
    css?: string;
    displayParameters?: DisplayParameters;
    html?: string;
    js?: string;
    perso?: PersoLists;
    URLProgramGuide?: string;
  }
>;

export type LandingV5State = {
  currentPage?: ApiV2LandingCurrentPage;
  promotionCover?: PromotionStrateData;
  cover?: CoverData;
  strates: StrateV5[];
  tracking?: ApiV2PageTracking;
};

const isTitleDisplayMode = isSomeEnum(TitleDisplayMode);

const isStrate = (strate: ApiV2PageStrate): strate is StrateV5 =>
  !!strate?.type;

export const getFormattedLandingV5 = (
  data: ApiV2PageLanding,
  featIdentityV5 = false,
  isIOS = false
): LandingV5State => {
  const { covers, strates = [], tracking, currentPage } = data || {};
  const tempHashTable = [];

  const formattedCover = getFormattedIdentityCover(covers, featIdentityV5);
  const formattedStrates = strates.filter(isStrate).map((strate, _) => {
    const strateWithStaticKey = hashObject(
      strate,
      // here typescript doesn't recognize type of context :/
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [
        'type',
        'context.context_list_title',
        'context.context_list_category',
        'context.context_page_title',
      ],
      tempHashTable
    );
    const contentsWithStaticKey = mapStaticKey(strateWithStaticKey.contents, [
      'contentID',
    ]);
    const {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      displayParameters: { titleDisplayMode } = {},
    } = strateWithStaticKey;
    const displayParameters = strateWithStaticKey.displayParameters && {
      ...strateWithStaticKey.displayParameters,
      titleDisplayMode:
        titleDisplayMode && isTitleDisplayMode(titleDisplayMode)
          ? titleDisplayMode
          : undefined,
    };

    return {
      ...strateWithStaticKey,
      title: strateWithStaticKey.title || undefined,
      button: setContextForOnClick(strateWithStaticKey.button || {}),
      contents: getContentsWithContext(
        contentsWithStaticKey,
        strateWithStaticKey.context
      ),
      displayParameters,
    };
  });

  const formattedPromotionCover = getFormattedPromotionStrate(
    formattedStrates[0],
    isIOS
  );

  return {
    currentPage,
    cover: formattedCover,
    promotionCover: formattedPromotionCover,
    strates: formattedStrates,
    tracking,
  };
};

/**
 * Hodor allows you to limit the number of content loaded in the layers of the landing.
 * To do this, you must add the query "get" in the url of the Hodor page.
 * For better performance, we reduced to 14 items on first render for desktop and 10 items for TV devices.
 */
export const getFormattedLandingUrl = (
  url: string,
  isTvDevice: boolean
): string =>
  addQueryParam(
    url,
    'get',
    String(
      isTvDevice
        ? NB_ITEMS_IN_STRATES_TO_FETCH_TV
        : NB_ITEMS_IN_STRATES_TO_FETCH
    )
  );
