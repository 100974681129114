import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { updateAdultActive } from '../../../store/slices/adult';
import { activeAdultSelector } from '../../../store/slices/adult-selectors';
import { pageAdultSelector } from '../../../store/slices/page-selectors';

/**
 * This method cannot be instantiated directly within the SearchContainer component (in the cleanup function of a useEffect),
 * because it is based on the isAdultActive and isAdultPage values of the store which are updated at the same time as isSearchActive.
 * Note that isSearchActive is the data that triggers the unmounting of the SearchContainer component (See below in the render).
 * If this method is instantiated in the SearchContainer component, when calling the cleanup (unmount) function, isAdultActive and isAdultPage will not yet be up to date.
 * In the cleanup of the useEffect hook, those two values would be based on a previous state of the component, which prevents us from having the correct behavior.
 * For this reason, we instantiate this method in the parent component (Application) where all the values (isAdultPage, isAdultActive, isSearchActive) are up-to-date
 * And then we pass it to its child component (SearchContainer) in order to be consumed during its unmounting.
 */
export const useHandleUnmountSearch = (): void => {
  const dispatch = useAppDispatch();

  const isAdultActive = useSelector(activeAdultSelector);
  const isAdultPage = useSelector(pageAdultSelector);

  if (!isAdultActive && isAdultPage) {
    dispatch(updateAdultActive());
  }
};
