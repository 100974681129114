import { HeaderLogo } from '@canalplus/dive';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { myCanalTitlesMapping } from '../../../constants/international';
import I18n from '../../../lang';
import {
  isHeaderDarkSelector,
  isHomePageSelector,
  rootPathSelector,
} from '../../../selectors/header-selectors';
import { HOME_KIDS_PATHNAME } from '../../../store/constants';
import {
  getDarkLogoURLSelector,
  getLightLogoURLSelector,
  isProspectSelector,
  kidsHomeUrlSelector,
  logoSelector,
  offerLocationSelector,
} from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { isKidsProfileSelector } from '../../../store/slices/user-selectors';
import styles from './HeaderLogoOneDiscovery.module.css';

type HeaderLogoOneDiscoveryProps = {
  isTransparent?: boolean;
};

function HeaderLogoOneDiscovery({
  isTransparent,
}: HeaderLogoOneDiscoveryProps): JSX.Element {
  const { t } = I18n.useTranslation();

  const {
    altImage = '',
    ariaLabel: hodorAriaLabel,
    URLImageForDarkMode,
    URLImageForLightMode,
  } = useSelector(logoSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const rootPath = useSelector(rootPathSelector);
  const isKidsProfile = useSelector(isKidsProfileSelector);
  const isProspect = useSelector(isProspectSelector);
  const isHomePage = useSelector(isHomePageSelector);
  const isHeaderDark = useSelector(isHeaderDarkSelector);
  const darkLogoUrl = useSelector(getDarkLogoURLSelector);
  const lightLogoUrl = useSelector(getLightLogoURLSelector);
  const kidsHomeUrl = useSelector(kidsHomeUrlSelector);
  const offerLocation = useSelector(offerLocationSelector);

  const isPageHeading = isProspect && isHomePage;
  const darkUrl = darkLogoUrl || URLImageForDarkMode;
  const lightUrl = lightLogoUrl || URLImageForLightMode;
  const logoUrl = (isTransparent || isHeaderDark ? darkUrl : lightUrl) || '';
  const ariaLabel =
    hodorAriaLabel ||
    t('A11y.headerLogo', {
      appName: altImage || myCanalTitlesMapping[offerLocation] || '',
    });

  const [linkUrl, setLinkUrl] = useState<string>();

  useEffect(() => {
    if (!isTvDevice) {
      // Hodor sends us to the kids page with a URL "/theme/pid110530-home-profils-kids.html"
      // which causes us to lose the "/home-kids/" route when we click on the icon.
      setLinkUrl(
        isKidsProfile
          ? rootPath?.replace(kidsHomeUrl, HOME_KIDS_PATHNAME)
          : rootPath
      );
    }
  }, [isKidsProfile, isTvDevice, rootPath, kidsHomeUrl]);

  const logo = (
    <HeaderLogo
      src={logoUrl}
      alt={altImage}
      href={linkUrl}
      aria-label={ariaLabel}
    />
  );

  if (isPageHeading) {
    return <h1 className={styles.HeaderLogoOneDiscovery__heading}>{logo}</h1>;
  }

  return logo;
}

export default HeaderLogoOneDiscovery;
