import { isClientSide } from '@canalplus/mycanal-commons';
import { requestPage } from '@canalplus/sdk-hodor';
import { ApiV2MeNotifications } from '@dce-front/hodor-types';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { DAY_IN_MS } from '../../../constants/date';
import { DEFAULT_CACHE_TIME } from '../../../constants/reactQuery';
import { shouldShowNotification } from '../../../helpers/notification/notification-helper';
import { getAlertingV2Url } from '../../../helpers/url/getAlertingV2Url';
import {
  alertingV2UrlSelector,
  appKeySelector,
  getFeatureToggleAlertingV2,
  hodorSdkConfigSelector,
  tokenCMSSelector,
} from '../../../store/slices/application-selectors';
import {
  accountIdSelector,
  authenticatedSelector,
  isKidsProfileSelector,
} from '../../../store/slices/user-selectors';

export const useAlerting = (): ApiV2MeNotifications | undefined => {
  const authenticated = useSelector(authenticatedSelector);
  const accountId = useSelector(accountIdSelector);
  const appKey = useSelector(appKeySelector);
  const cmsToken = useSelector(tokenCMSSelector);
  const featAlertingV2 = useSelector(getFeatureToggleAlertingV2);
  const path = useSelector(alertingV2UrlSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);
  const isKidsProfile = useSelector(isKidsProfileSelector);

  const { data: alertingPage } = useQuery({
    queryKey: [path, hodorSdkConfig, appKey, cmsToken, accountId],
    queryFn: async () => {
      if (!path || !hodorSdkConfig) {
        return;
      }
      return (await requestPage(hodorSdkConfig, {
        url: getAlertingV2Url({ path, appKey, cmsToken }),
        parameters: [
          { id: 'tokenPass', in: 'header' },
          { id: 'xx-profile-id', in: 'header' },
        ],
      })) as ApiV2MeNotifications;
    },
    enabled:
      featAlertingV2 && isClientSide() && authenticated && !isKidsProfile,
    gcTime: DEFAULT_CACHE_TIME,
    staleTime: DAY_IN_MS,
  });

  const showNotification =
    alertingPage?.alert &&
    accountId !== undefined &&
    shouldShowNotification({
      accountId,
      displayTemplate: alertingPage.currentPage.displayTemplate,
      contentID: alertingPage.alert.contentID,
      ...alertingPage.alert.triggers,
    });

  return showNotification ? alertingPage : undefined;
};
