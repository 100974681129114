import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableErrorConnected = loadable(
  () => import('./components/ErrorContainer'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableErrorConnected;
