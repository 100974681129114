import {
  Middleware,
  MiddlewareFactory,
  MiddlewareHooks,
  OriginElement,
} from '../store/types';

/**
 * Instanciate a new memory middleware
 *
 * @example
 *  const MIDDLEWARE = [memory(), spacial()];
 *
 *  function MyComponent() {
 *    return (
 *      <Binder middleware={MIDDLEWARE}>
 *        <button>hello</button>
 *      </Binder>
 *    );
 *  }
 *
 * @returns Memory middleware factory
 */
export const memory: Middleware =
  (): MiddlewareFactory =>
  (binder): MiddlewareHooks => {
    let memoizedElement: HTMLElement | undefined;

    const focusEnter = () => {
      if (memoizedElement && binder.getElements().includes(memoizedElement)) {
        return memoizedElement;
      }
    };

    const focused = (element: OriginElement) => {
      if (element instanceof HTMLElement) {
        // Here memoizedElement is used to avoid undefined of nearest who can result in a memory loose
        memoizedElement = element;
      }
    };

    return {
      focusEnter,
      focused,
    };
  };
