import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';

export type ErrorData = {
  meta?: any;
  text?: string;
  title?: string;
  tracking?: Tracking;
};

const getFormattedError = (data: any): ErrorData => {
  return {
    title: data?.title,
    text: data?.text,
    tracking: data?.tracking,
    meta: { title: data?.meta?.title || data?.title },
  };
};

export default getFormattedError;
