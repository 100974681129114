import classNames from 'classnames';
import IconMangifyingGlass from '../../../assets/svg/magnifyingGlass.svg';
import styles from './HeaderSearchIcon.module.css';

export type HeaderSearchIconProps = {
  className?: string;
  isActive?: boolean;
};

export function HeaderSearchIcon({
  className = '',
  isActive = false,
}: HeaderSearchIconProps): JSX.Element {
  return (
    <IconMangifyingGlass
      className={classNames(styles.HeaderSearchIcon, 'HeaderSearchIcon', {
        [className]: !!className,
        [styles['HeaderSearchIcon--isActive']]: isActive,
      })}
      role="img"
    />
  );
}
