/**
 * Helper function to extract the epgID from the urlChannelSchedule parameter
 * @param urlChannelSchedule URL to retrieve channel content during a specific day
 * @returns epgId of the channel
 * @example
 *
 * getEpgIdFromURLChannelSchedule(`https://hodor.canalplus.pro/api/v2/mycanal/channels/0b331b8ce682b569513cfb954fc5d1c9/301/broadcasts/day/0`)
 * returns 301
 *
 */
export const getEpgIdFromURLChannelSchedule = (
  urlChannelSchedule: string
): number => parseInt(urlChannelSchedule.split('/').slice(-4, -3)[0], 10);
