import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { defaultSocialLinks } from './config';
import { FormattedSocialLink } from './types';

export type SocialLinks =
  | ({ contentID?: string } & ApiV2NavigationItem)[]
  | {
      facebook: string;
      twitter: string | null;
      dailymotion: string | null;
      youtube: string | null;
    };

export const getSocialLinks = (
  isSocialLinksFromBO: boolean,
  socialLinks: SocialLinks
): FormattedSocialLink[] => {
  if (isSocialLinksFromBO && Array.isArray(socialLinks)) {
    // Get social links from props (from BO)
    return socialLinks.map((socialLink) => ({
      link: socialLink.onClick?.URLWebsite,
      name: socialLink.contentID || socialLink.displayName?.toLowerCase(),
      displayName: socialLink.displayName,
    }));
  }

  // Get social links whether from props if defined (from config) or headerfooter config by default
  return socialLinks
    ? Object.keys(socialLinks).reduce((footerSocials, socialKey) => {
        if (socialLinks[socialKey]) {
          return [
            ...footerSocials,
            {
              ...defaultSocialLinks.find(({ name }) => socialKey === name),
              ...(socialLinks[socialKey]
                ? { link: socialLinks[socialKey] }
                : {}),
            },
          ];
        }
        return footerSocials;
      }, [] as FormattedSocialLink[])
    : defaultSocialLinks;
};
