import { IContent } from '@canalplus/mycanal-sdk';
import { Template } from '@canalplus/sdk-hodor';

/**
 * getContentID
 *
 * @param  {object} [content]         content properties from Card (required)
 * @returns {string}                  return contentID or EpgID for Player stringified
 */
export const getContentID = (content: IContent): string | number => {
  // use second verification for Startover in LiveRow
  if (
    (content.onClick &&
      content.onClick.displayTemplate === Template.Player &&
      content.channel &&
      content.channel.epgID) ||
    (content.channel && content.channel.epgID)
  ) {
    return content.channel.epgID;
  }

  const firstEpisodeId = content?.onClick?.firstEpisodeID;
  const contentID = content.contentID;

  return firstEpisodeId || contentID || '';
};
