import { cva } from 'class-variance-authority';
import { MediaAspectRatio } from './Media.types';

export const mediaContainerCVA = cva('flex box-border', {
  variants: {
    hasAspectRatio: {
      true: '',
      // Default to parent element's size when no aspect ratio is set
      false: 'size-full',
    },
    isTv: {
      true: '',
      false: '',
    },
    footerPosition: {
      default: 'flex-col',
      responsive: [
        'flex-row items-center dt-breakpoint-sm-start:flex-col dt-breakpoint-sm-start:items-stretch',
        'space-x-dt-spacing-200 dt-breakpoint-sm-start:space-x-dt-spacing-none',
      ],
    },
  },
  compoundVariants: [
    {
      isTv: true,
      footerPosition: 'default',
      className: 'space-y-dt-spacing-200',
    },
    {
      isTv: true,
      footerPosition: 'responsive',
      className: 'space-y-dt-spacing-200',
    },
    {
      isTv: false,
      footerPosition: 'default',
      className: 'space-y-dt-spacing-100',
    },
    {
      isTv: false,
      footerPosition: 'responsive',
      className: 'dt-breakpoint-sm-start:space-y-dt-spacing-100',
    },
  ],
});

export const mediaCva = cva('relative overflow-hidden', {
  variants: {
    isTv: {
      true: 'rounded-dt-radius-medium',
      false: 'rounded-dt-radius-small',
    },
    variant: {
      solid:
        'before:content-[""] before:absolute before:w-full before:h-full before:left-0 before:top-0',
      ghost: '',
    },
    showPlaceholderAnim: {
      true: 'before:animate-pulse-6s',
      false: '',
    },
    aspectRatio: {
      [MediaAspectRatio.Square1x1]: 'aspect-w-1 aspect-h-1',
      [MediaAspectRatio.Portrait2x3]: 'aspect-w-2 aspect-h-3',
      [MediaAspectRatio.Portrait3x4]: 'aspect-w-3 aspect-h-4',
      [MediaAspectRatio.Portrait3x5]: 'aspect-w-3 aspect-h-5',
      [MediaAspectRatio.Portrait4x7]: 'aspect-w-4 aspect-h-7',
      [MediaAspectRatio.Landscape4x3]: 'aspect-w-4 aspect-h-3',
      [MediaAspectRatio.Landscape16x9]: 'aspect-w-16 aspect-h-9',
      [MediaAspectRatio.Landscape16x8]: 'aspect-w-16 aspect-h-8',
      [MediaAspectRatio.Landscape16x6]: 'aspect-w-16 aspect-h-6',
      [MediaAspectRatio.Landscape23x6]: 'aspect-w-23 aspect-h-6',
      // Default to parent element's size when no aspect ratio is set
      undefined: 'size-full',
    },
    showTvFocusStyles: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      variant: 'solid',
      isTv: true,
      className:
        'before:bg-dt-theme-tv-background-load-media-placeholder-load-media-placeholder',
    },
    {
      variant: 'solid',
      isTv: false,
      className:
        'before:bg-dt-theme-background-load-media-placeholder-load-media-placeholder',
    },
    {
      showTvFocusStyles: true,
      isTv: true,
      className:
        'tv-focus-parent:outline tv-focus-parent:outline-dt-border-medium tv-focus-parent:outline-dt-theme-tv-border-focus tv-focus-parent:scale-tv-focus',
    },
  ],
});
