import { useEffect } from 'react';
import { useStore } from './useStore';

/**
 * Allows to switch to a layer in a declarative way via a hook
 * @param layer layer id to switch on
 * @param active if passed, the given layer is set active when true and inative when false
 *
 * @example
 *  // This will set layer 42 active when mounted and previous active layer is restored on unmount
 *  useActiveLayer(42);
 *
 * @example
 *  // This will set layer 42 active when enable is true and previous active layer is restored
 *  // when enable pass to false
 *  useActiveLayer(42);
 */
export function useActiveLayer(layer: number, active: boolean = true): void {
  const store = useStore();

  useEffect(() => {
    // No-op if
    // - inative
    // - one-navigation is in "stack" mode and parameter layer is under active layer
    if (
      !active ||
      (store.layerMode === 'stack' && layer <= store.activeLayer)
    ) {
      return undefined;
    }

    const previousLayer = store.activeLayer;
    store.setActiveLayer(layer);
    store.focusDefault();

    return () => {
      // No-op if this hook is unmounted while not being the active layer
      if (store.activeLayer !== layer) {
        return;
      }

      // In stack mode, we try to retrieve the highest layer below the current one
      // If there's one we switch to it
      if (store.layerMode === 'stack') {
        const underLayer = store.getHighestActiveLayer(layer - 1);

        if (underLayer) {
          store.setActiveLayer(underLayer.id);
          store.focusDefault();
          return;
        }
      }

      // Fallback to the previous layer
      store.setActiveLayer(previousLayer);
    };
  }, [store, layer, active]);
}
