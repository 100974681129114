import { IContent } from '@canalplus/mycanal-sdk';
import { mapStaticKey } from '@canalplus/mycanal-util-react';
import type { PersoLists } from '@canalplus/sdk-hodor';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { ApiV2Contents } from '@dce-front/hodor-types/api/v2/strate_content/definitions';
import { getContentsWithContext } from '../../../helpers/contents/contents-helper';
import type {
  ContentStrateV5,
  DisplayParameters,
} from '../../LandingV5/data/formatter';

export type ContentRowPersoData = {
  contents?: ContentStrateV5[];
  type?: PersoLists;
  currentPage?: {
    title?: string;
    subtitle?: string;
  };
};

const getFormattedContentRowPerso = (
  data: {
    contents?: (ApiV2Contents | IContent)[];
    context?: ApiV2Context;
    displayParameters?: DisplayParameters;
    currentPage?: {
      perso?: PersoLists;
    };
  },
  trackingContext?: ApiV2Context
): ContentRowPersoData => {
  const { contents, currentPage } = data || {};
  const contentsWithStaticKey = mapStaticKey(contents, 'title');
  const persoTrackingContext = data?.context ?? {};

  return {
    contents: contentsWithStaticKey
      ? getContentsWithContext(contentsWithStaticKey, {
          ...trackingContext,
          ...persoTrackingContext,
        })
      : undefined,
    type: currentPage?.perso,
  };
};

export default getFormattedContentRowPerso;
