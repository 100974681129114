/**
 * All ACM error codes
 *
 * - **400**: Bad request
 * - **400.1**: Unknown offerZone
 * - **400.2**: Unknown encryption method
 * - **400.3**: Salt is empty. It's required with a sha256 encryption.
 * - **400.4**: Parental code already exists
 * - **400.5**: ParentalCode not created
 * - **400.6**: Parental code not matching
 * - **400.7**: Forbidden value for parental code
 * - **401.1**: MFA failed
 * - **401.2**: Too many Parental Code errors in a short time
 * - **404.1**: Subscriber not found
 * - **404.2**: Account not found
 * - **404.3**: Code doesn't exist
 * - **500**: Internal server error
 */
export enum AcmErrorCode {
  'Code_400' = '400',
  'Code_400.1' = '400.1',
  'Code_400.2' = '400.2',
  'Code_400.3' = '400.3',
  'Code_400.4' = '400.4',
  'Code_400.5' = '400.5',
  'Code_400.6' = '400.6',
  'Code_400.7' = '400.7',
  'Code_401.1' = '401.1',
  'Code_401.2' = '401.2',
  'Code_404.1' = '404.1',
  'Code_404.2' = '404.2',
  'Code_404.3' = '404.3',
  'Code_500' = '500',
}
