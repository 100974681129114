import { useEffect, useState } from 'react';
import { throttle } from '../../../helpers/helpers';

const isSSR = typeof window === 'undefined';

type UseOverflowDetectorProps<T> = {
  /**
   * Container that wraps the items. Used to determine max width available for items.
   * @example <nav ref={containerRef}>...</nav>
   */
  containerRef: React.RefObject<HTMLElement>;
  /**
   * Invisible duplicate of all container children, used as visual reference to compute
   * the total width of each item.
   *
   * @example
   *   // Duplicated from original <ul /> only for measurement
   *   <ul ref={measurableRef} aria-hidden className="absolute invisible z-0">
   *     {items.map((item) => ...)}
   *   </ul>
   */
  measurableRef: React.RefObject<HTMLElement>;
  /**
   * Ref of dropdown element where the overflowing items will be stored.
   * Also considered in overflowing computation
   * @example <li ref={dropdownRef>...</li>
   */
  dropdownRef?: React.RefObject<HTMLElement>;
  /**
   * Original list of items that should be splitted into visible and overflowing.
   */
  items: T[];
  /**
   * Enable or disable the overflow detection.
   */
  enabled?: boolean | (() => boolean);
  /**
   * Throttle interval for the resize event.
   * @default 250
   */
  throttleInterval?: number;
};

/**
 * Hook that checks which items are visible and which are overflowing.
 *
 * @example
 * const { overflowingItems, visibleItems } = useOverflowDropdownItems({
 *   containerRef,
 *   measurableRef,
 *   dropdownRef,
 *   items,
 *   enabled: isPriorityMode,
 * });
 */
export const useOverflowDropdownItems = <T>({
  containerRef,
  measurableRef,
  dropdownRef,
  items,
  enabled = true,
  throttleInterval = 250,
}: UseOverflowDetectorProps<T>): {
  visibleItems: T[];
  overflowingItems: T[];
} => {
  const [visibleItems, setVisibleItems] = useState<T[]>(items);
  const [overflowingItems, setOverflowingItems] = useState<T[]>([]);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    const handleResize = throttle(() => {
      if (
        !enabled ||
        !containerRef.current ||
        !measurableRef.current ||
        !dropdownRef?.current
      ) {
        setVisibleItems(items);
        setOverflowingItems([]);
        return;
      }

      const dropdownWidth =
        dropdownRef.current?.getBoundingClientRect().width || 0;
      const containerRight =
        containerRef.current.getBoundingClientRect().right || 0;
      const measurableRight =
        measurableRef.current.getBoundingClientRect().right || 0;

      if (measurableRight > containerRight) {
        // Helper to determine if current item is overflowing
        const isOverflowing = (itemRight: number) => itemRight > containerRight;

        // Get Index of first element that doesn't fit in the visible nav (along side with the dropdown item)
        const measurableItems = Array.from(measurableRef.current.children);
        const indexToSplit = measurableItems.findIndex((item) => {
          const itemRight = item.getBoundingClientRect().right;
          return (
            isOverflowing(itemRight) || isOverflowing(itemRight + dropdownWidth)
          );
        });

        setVisibleItems(items.slice(0, indexToSplit));
        setOverflowingItems(items.slice(indexToSplit, items.length));
      } else {
        setVisibleItems(items);
        setOverflowingItems([]);
      }
    }, throttleInterval);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [
    enabled,
    items,
    containerRef,
    measurableRef,
    dropdownRef,
    throttleInterval,
  ]);

  return { visibleItems, overflowingItems };
};
