import { ModalTypes } from '../../constants/modalTypes';
import { removeTokenCmsCookie } from '../../helpers/application/application-helper';
import { useAppDispatch } from '../../helpers/hooks/useAppDispatch';
import { useSigninRedirect } from '../../helpers/pass/useSigninRedirect';
import { userConfirmSettings } from '../../store/slices/user-thunk';
import type { UserSettingsState } from '../../store/slices/user-type';
import type { AppHistory } from '../../typings/routing';

export const useCloseConfirm = () => {
  const dispatch = useAppDispatch();
  const signinRedirect = useSigninRedirect();

  return async (
    settings: UserSettingsState,
    history: AppHistory
  ): Promise<void> => {
    const { nextActionBeforeSaving } = settings;

    /** When user exit page without saving their changes, we ask him to confirm their new settings in modal */
    const isSuccess = await dispatch(
      userConfirmSettings(
        {
          ...settings,
          nextActionBeforeSaving: undefined,
        },
        ModalTypes.USER_EXITS_SETTINGS_MODAL,
        true
      )
    );

    if (typeof isSuccess === 'undefined' || isSuccess) {
      /**
       * Dispatch changing page action:
       * if no call API (when user decides to cancel their changes)
       * or if update is success
       */
      history.push({
        pathname: nextActionBeforeSaving?.pathname,
      });

      if (isSuccess) {
        const domain = window.location.hostname;
        removeTokenCmsCookie(domain);
        signinRedirect();
      }
    }
  };
};
