export enum ShowcaseTypes {
  BANNER = 'banner',
  BUTTON = 'button',
  BRANDDISCOVER = 'brandDiscover',
  CHANNELSGRID = 'channelsGrid',
  CONTENTROW = 'contentRow',
  IMAGE = 'image',
  OFFERHIGHLIGHT = 'offerHighlight',
  PROGRAMSHIGHLIGHT = 'programsHighlight',
  PLAINTEXTHTML = 'plainTextHTML',
  REBOUNDPRODUCT = 'reboundProduct',
  SEPARATOR = 'separator',
  TABS = 'tabs',
  TEXT = 'text',
  TILES = 'tiles',
  WALL = 'wall',
}

export enum TextLayerTypes {
  PARAGRAPH = 'paragraph',
  SUBTITLE = 'subtitle',
  TITLE = 'title',
  TITLE2 = 'title2',
}

export enum ButtonLayerTypes {
  INLINE = 'inline',
  PLAIN = 'plain',
  PRIMARY = 'primary',
}
