import { HeaderNavDropdown, HeaderSubscribeButton } from '@canalplus/dive';
import { setCookie } from '@canalplus/mycanal-commons';
import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { useSelector } from 'react-redux';
import { CookieKey } from '../../../constants/cookie';
import { computeApiV2OnClickFromNavigationItemOnClick } from '../../../helpers/hodor/computeApiV2OnClickFromNavigationItemOnClick';
import { activeNavigationPathSelector } from '../../../selectors/header-selectors';
import {
  userMenuSelector,
  zoneInfoSelector,
} from '../../../store/slices/application-selectors';
import Linker from '../../Linker/Linker';
import HeaderProfileMyCanal from '../HeaderProfileOneDiscovery/HeaderProfileOneDiscovery';
import { HeaderSearchButtonOneDiscovery } from '../HeaderSearchButtonOneDiscovery/HeaderSearchButtonOneDiscovery';
import { useUserMenuExtended } from '../hooks/useUserMenuExtended';

function HeaderUserMenuOneDiscovery(): JSX.Element {
  const activePath = useSelector(activeNavigationPathSelector);
  const userMenu = useSelector(userMenuSelector);
  const userMenuWithStaticKeys = mapStaticKey(userMenu, 'type');
  const zoneInfo = useSelector(zoneInfoSelector);

  const { userMenuLanguages } = useUserMenuExtended();

  return (
    <>
      {userMenuWithStaticKeys.map((item) => {
        const { type, picto, onClick, hash, buttonLabel = '' } = item;
        const isActive = activePath === onClick?.path;

        switch (type) {
          case 'picto':
            if (picto === 'search') {
              return (
                <HeaderSearchButtonOneDiscovery
                  key={hash}
                  data={item}
                  isActive={isActive}
                />
              );
            }
            return null;

          case 'currentProfile':
            return (
              <HeaderProfileMyCanal
                key={hash}
                data={item}
                isActive={isActive}
              />
            );

          case 'button':
            return (
              <HeaderSubscribeButton
                key={hash}
                label={buttonLabel}
                renderWrapper={({ ref, ...rest }) => (
                  <Linker
                    ref={ref}
                    data={
                      onClick
                        ? {
                            mainOnClick:
                              computeApiV2OnClickFromNavigationItemOnClick(
                                onClick
                              ),
                          }
                        : undefined
                    }
                    {...rest}
                  />
                )}
              />
            );

          case 'text': {
            if (
              userMenuLanguages &&
              onClick?.displayTemplate === 'languageSelector'
            ) {
              const currentLang = zoneInfo.lang?.toUpperCase();

              const onSetOfferZone = (offerZone: string) => {
                setCookie(CookieKey.Offerzone, offerZone);
              };

              return (
                <HeaderNavDropdown
                  key="language-selector"
                  items={userMenuLanguages.languages[0]
                    .filter(
                      (lang) => lang.language.toUpperCase() !== currentLang
                    )
                    .map((language) => ({
                      label: language.languageLabel,
                      href: language.languagePath,
                      onClick: () => onSetOfferZone(language.offerZone),
                    }))}
                  label={currentLang}
                  tabIndex={0}
                  dropdownPlacement="bottom-end"
                />
              );
            }

            return null;
          }

          default:
            return null;
        }
      })}
    </>
  );
}

export default HeaderUserMenuOneDiscovery;
