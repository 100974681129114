import { isClientSide } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import { ApiV2DetailV5 } from '@dce-front/hodor-types';
import { ApiV2Article } from '@dce-front/hodor-types/api/v2/article/definitions';
import { isEqual } from 'es-toolkit';
import { useCallback, useEffect, useRef } from 'react';
import type { GenericOutput } from '../../../services/types';
import { updateAndFormatMeta } from '../../../store/slices/meta-thunk';
import { isDetailTemplate } from '../../page/page-helper';
import { useAppDispatch } from '../useAppDispatch';
import { UseInfiniteQueryHandlerData } from '../useInfiniteQueryTemplate/types';

export type AllQueryHandlerResult = Partial<
  UseInfiniteQueryHandlerData<GenericOutput>
>;

const isApiV2DetailV5 = (
  data: AllQueryHandlerResult
): data is ApiV2DetailV5 => {
  return (
    !!data.currentPage?.displayTemplate &&
    isDetailTemplate(data.currentPage?.displayTemplate) &&
    Boolean((data as ApiV2DetailV5).detail)
  );
};

const isApiV2Article = (data: AllQueryHandlerResult): data is ApiV2Article => {
  return data.currentPage?.displayTemplate === Template.CreativeMedia;
};

/**
 * The component MetaTags must be after this call in the dom to receive the good metas data from redux (because in server side there are only one render cycle).\
 * Please ensure the `<MetaTagsConnected>` is the last component of the dom.
 */
export const useMetas = (
  disableMetaUpdate: boolean,
  data?: AllQueryHandlerResult
): void => {
  const dispatch = useAppDispatch();
  const previousData = useRef({});

  const updateMeta = useCallback(() => {
    if (!disableMetaUpdate && data) {
      const hasDataChanged = !isEqual(data, previousData.current);
      previousData.current = data;

      const meta = data.pages?.[0]?.meta || data.meta;
      const currentPage = data.pages?.[0]?.currentPage || data.currentPage;

      if (hasDataChanged && meta && currentPage) {
        let urlImage: string | undefined;

        if (isApiV2DetailV5(data)) {
          urlImage = data.detail.cover?.regularImage?.URLImage;
        }

        if (isApiV2Article(data)) {
          urlImage = data.informations?.mainMedia?.URLImage;
        }

        dispatch(
          updateAndFormatMeta({
            meta,
            displayTemplate: currentPage.displayTemplate,
            displayName: currentPage.displayName,
            urlImage,
          })
        );
      }
    }
  }, [data, disableMetaUpdate, dispatch]);

  // ⚠️ `updateMeta` should be executed on both server and client side
  useEffect(() => {
    updateMeta();
  }, [updateMeta]);

  if (!isClientSide()) {
    updateMeta();
  }
};
