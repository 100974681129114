import { createContext, ReactNode } from 'react';

export const LayerContext = createContext<number>(0);

export type LayerProps = {
  children: ReactNode;
  layer: number;
};

export function Layer({ children, layer }: LayerProps): JSX.Element {
  return (
    <LayerContext.Provider value={layer}>{children}</LayerContext.Provider>
  );
}
