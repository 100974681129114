import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableDownloadToGoContainer = loadable(
  () => import('./components/DownloadToGoContainer'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableDownloadToGoContainer;
