export enum PlayerPlaybackState {
  Stopped = 'STOPPED',
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Ended = 'ENDED',
  Playing = 'PLAYING',
  Paused = 'PAUSED',
  Buffering = 'BUFFERING',
  Seeking = 'SEEKING',
}
