import { removeCookie, setCookie } from '@canalplus/mycanal-commons';
import Cookies from 'js-cookie';
import { CookieKey } from '../../constants/cookie';
import { getNotificationExpires } from '../../helpers/date/date-helper';
import type { UserNotification } from '../../store/slices/user-type';

export const storeNotification = (notification: UserNotification): void => {
  const cacheNotification = Cookies.get(CookieKey.Notification);
  const parsedNotification: UserNotification = cacheNotification
    ? JSON.parse(cacheNotification)
    : undefined;

  const isNotificationHidden = notification?.hidden || false;
  const isCacheNotificationHidden = parsedNotification?.hidden || false;

  if (isNotificationHidden && !isCacheNotificationHidden) {
    removeCookie(CookieKey.Notification);

    setCookie(CookieKey.Notification, notification, {
      expires: getNotificationExpires(notification.refreshInterval || 0),
      sameSite: 'lax',
    });
  }
};
