import { DisplayMode } from '@canalplus/mycanal-commons';
import { destroyPlayer } from '../../../store/slices/player';
import { setDisplayMode } from '../../../store/slices/ui';

export type RemovePlayerProps = {
  dispatch: Redux.Dispatch;
};
export function removePlayer({ dispatch }: RemovePlayerProps): void {
  // if (Object.keys(playerInstance).length) {
  // }
  dispatch(destroyPlayer());

  // TODO: handle in a middleware maybe
  dispatch(setDisplayMode(DisplayMode.WINDOWED));
}
