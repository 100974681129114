const SUPPORTED_SOCIAL_NETWORKS = [
  'Dailymotion',
  'Facebook',
  'Twitter',
  'Youtube',
  'Instagram',
  'LinkedIn',
  'Tiktok',
] as const;

export type SocialNetwork = (typeof SUPPORTED_SOCIAL_NETWORKS)[number];

export const isSocialNetwork = (name: string): name is SocialNetwork => {
  return SUPPORTED_SOCIAL_NETWORKS.includes(name as SocialNetwork);
};
