import loadable from '@loadable/component';
import Spinner from '../../Spinner/Spinner';

const LoadableMobileAppDownloadModal = loadable(
  () => import('./MobileAppDownloadModal'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableMobileAppDownloadModal;
