import { debounce } from 'es-toolkit';

/** Evaluate screen size in client side before re-hydrating */
export const windowResizeListener = (
  eventDispatch: (innerWidth: number) => void
): void => {
  const dispatchResize = () => eventDispatch(window.innerWidth);
  dispatchResize();
  window.addEventListener('resize', debounce(dispatchResize, 20));
};
