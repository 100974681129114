import { useContext } from 'react';
import { StoreContext } from '../context/StoreContext';
import { Store } from '../store/Store';

export function useStore(): Store {
  const context = useContext(StoreContext);

  if (!context) {
    throw new TypeError('OneNavigation: expected a StoreContext to be defined');
  }

  return context;
}
