import { CompositeItem } from '@floating-ui/react';
import { cloneElement, forwardRef, HTMLAttributes } from 'react';
import { useDiveContext } from '../../../context';
import { DiveIconPosition } from '../../../types/Dive.types';
import { Button } from '../../Actions/Button/Button';
import { ButtonProps } from '../../Actions/Button/Button.types';
import {
  navItemCVA,
  navItemIconCVA,
  navItemLabelCVA,
} from './HeaderNavItem.cva';
import { HeaderNavItemProps } from './HeaderNavItem.types';

/**
 * HeaderNavItem component to be used inside a `<HeaderNav />` component. It uses Floating/UI CompositeItem
 * which makes the item navigable by arrow keys outside of floating element context, whenever it is implemented
 * inside a `<Composite>`.
 * @see https://floating-ui.com/docs/composite
 *
 * @example
 *  <HeaderNavItem label="Home" icon={<HomeIcon />} />
 *  <HeaderNavItem label="Home" icon={<HomeIcon />} isCurrent /> // Current page
 *  <HeaderNavItem label="Home" icon={<HomeIcon />} href="/home" /> // Link
 *  <HeaderNavItem label="Home" icon={<HomeIcon />} wrapper={<Link href="/home" />} /> // Custom wrapper
 */
const HeaderNavItem = forwardRef<HTMLElement, HeaderNavItemProps>(
  function HeaderNavItem(
    {
      label,
      icon,
      id,
      isCurrent = false,
      iconPosition = DiveIconPosition.Left,
      href,
      wrapper,
      'data-testid': dataTestId,
      tabIndex,
      ...props
    },
    ref
  ): JSX.Element {
    const { isTv, device } = useDiveContext();

    const commonProps: HTMLAttributes<HTMLElement> = {
      id,
      role: 'menuitem',
      ...(dataTestId && { 'data-testid': dataTestId }),
      ...(isCurrent && { 'aria-current': 'page' }),
      ...props,
    };

    const renderWrapper: ButtonProps['renderWrapper'] = ({
      children,
      className,
    }) => (
      <CompositeItem
        className={className}
        ref={ref}
        render={(htmlProps) => {
          if (href) {
            return (
              <a
                href={href}
                {...commonProps}
                {...htmlProps}
                tabIndex={tabIndex}
              >
                {children}
              </a>
            );
          }
          if (wrapper) {
            return cloneElement(wrapper, {
              ...commonProps,
              ...htmlProps,
              tabIndex,
              children,
            });
          }
          return (
            <button
              type="button"
              {...commonProps}
              {...htmlProps}
              tabIndex={tabIndex}
            >
              {children}
            </button>
          );
        }}
      />
    );

    return (
      <Button
        id={id}
        variant={isTv ? 'tertiary' : 'text'}
        font="hind"
        iconPosition={iconPosition}
        className={navItemCVA({ device, current: isCurrent })}
        renderWrapper={renderWrapper}
        {...(icon && {
          icon: cloneElement(icon, {
            className: navItemIconCVA({ device, current: isCurrent }),
          }),
        })}
      >
        <span className={navItemLabelCVA({ device, current: isCurrent })}>
          {label}
        </span>
      </Button>
    );
  }
);

export default HeaderNavItem;
