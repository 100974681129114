import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableMosaicConnected = loadable(
  () => import('./components/MosaicContainer'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableMosaicConnected;
