import loadable from '@loadable/component';
import Spinner from '../../Spinner/Spinner';

const LoadableMaxProfilesReachedModal = loadable(
  () => import('./MaxProfilesReachedModal'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableMaxProfilesReachedModal;
