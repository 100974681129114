import { Ratio } from '@canalplus/mycanal-commons';
import { IContent } from '@canalplus/mycanal-sdk';
import { HashObjectReturn } from '@canalplus/mycanal-util-react';

export interface IMosaicState {
  contents: HashObjectReturn<IContent>[];
  ratio: Ratio;
  pagination: boolean;
}

const getFormattedMosaic = (data: Record<string, any>): IMosaicState => {
  return {
    contents: data.contents,
    ratio: Ratio.Ratio43,
    pagination: data.paging,
  };
};

export default getFormattedMosaic;
