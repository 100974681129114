/**
 * Convert milliseconds into seconds
 *
 * @param ms Milliseconds value to convert into seconds
 * @returns Value in seconds, defaults to 0 if `ms` is not provided
 */
export const msToSeconds = (ms: number | string = 0): number => {
  const numberMs = typeof ms === 'number' ? ms : parseInt(ms, 10);
  return (!!numberMs && numberMs / 1000) || 0;
};
