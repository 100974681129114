import { useEffect } from 'react';
import { Key } from '../constants/keys';
import { useLayerId } from './useLayerId';
import { useStore } from './useStore';

/**
 * Add an event listener for a given key and layer
 * @param key Key to catch
 * @param listener Listener to call once key is catched
 * @param propLayer Layer on which listener should be added
 */
export function useKeyCatcher(
  key: Key,
  listener: () => void,
  propLayer?: number
): void {
  const store = useStore();
  const contextLayerId = useLayerId();
  const layer = propLayer || contextLayerId || 0;

  useEffect(() => {
    store.getLayer(layer).addListener(key, listener);

    return () => {
      store.getLayer(layer).removeListener(key, listener);
    };
  }, [store, layer, listener, key]);
}
