import { cva } from 'class-variance-authority';
import { ButtonIconVariant } from './ButtonIcon.types';

export const buttonIconWrapperCVA = cva(
  'border-none bg-transparent group flex items-center p-dt-spacing-none',
  {
    variants: {
      variant: {
        default: undefined,
        video: undefined,
        header: undefined,
        'media-overlay': undefined,
        'media-overlay-pvr': 'flex-row',
      },
      hasLabel: {
        false: 'rounded-dt-radius-circle',
        true: undefined,
      },
      device: {
        tv: 'tv-focus-self:outline-none',
        web: undefined,
      },
      isDisabled: {
        true: 'cursor-default',
        false: 'cursor-pointer',
      },
    },
    compoundVariants: [
      {
        variant: [
          ButtonIconVariant.Default,
          ButtonIconVariant.Video,
          ButtonIconVariant.Header,
          ButtonIconVariant.MediaOverlay,
        ],
        hasLabel: true,
        className: 'flex-col',
      },
      {
        variant: ButtonIconVariant.MediaOverlay,
        hasLabel: true,
        device: 'tv',
        className: 'w-100',
      },
      {
        variant: [
          ButtonIconVariant.Default,
          ButtonIconVariant.Video,
          ButtonIconVariant.MediaOverlay,
        ],
        hasLabel: true,
        device: 'web',
        className: 'w-80',
      },
    ],
  }
);

export const buttonIconCVA = cva(
  'flex rounded-dt-radius-circle justify-center items-center',
  {
    variants: {
      variant: {
        video:
          'border-dt-theme-border-button-icon-secondary-button-icon-secondary bg-dt-theme-background-button-icon-video-button-icon-video',
        default: 'bg-dt-theme-background-button-icon-button-icon-primary',
        header: undefined,
        'media-overlay': [
          'justify-center items-center z-10 transition-none duration-0',
          'bg-dt-theme-background-episode-list-button-icon-episode-list',
        ],
        'media-overlay-pvr':
          'justify-center items-center z-10 transition-none duration-0',
      },
      isDisabled: {
        true: undefined,
        false: undefined,
      },
      isSelected: {
        true: undefined,
        false: undefined,
      },
      device: {
        'tv': undefined,
        'web': undefined,
      },
    },
    compoundVariants: [
      {
        isDisabled: false,
        variant: ButtonIconVariant.Default,
        device: 'web',
        className:
          'group-hover:bg-dt-theme-background-button-icon-button-icon-primary-hover group-hover:scale-button-icon transition-transform duration-150',
      },
      {
        isDisabled: true,
        variant: [
          ButtonIconVariant.Default,
          ButtonIconVariant.Video,
          ButtonIconVariant.Header,
        ],
        className: 'opacity-dt-opacity-50',
      },
      {
        isDisabled: false,
        variant: ButtonIconVariant.Header,
        device: 'web',
        className: 'bg-transparent',
      },
      {
        variant: ButtonIconVariant.Header,
        device: 'tv',
        className:
          'bg-dt-theme-tv-background-button-icon-button-icon-default group-focus:bg-dt-theme-tv-background-button-icon-button-icon-focus',
      },
      {
        isDisabled: false,
        variant: ButtonIconVariant.Header,
        device: 'web',
        className:
          'group-hover:scale-button-icon transition-transform duration-150',
      },
      {
        variant: [ButtonIconVariant.Default, ButtonIconVariant.Video],
        className: 'size-28',
      },
      {
        variant: ButtonIconVariant.Header,
        device: 'web',
        className: 'size-32',
      },
      {
        variant: ButtonIconVariant.Header,
        device: 'tv',
        className: 'size-54',
      },
      {
        variant: ButtonIconVariant.Video,
        className: 'border-dt-border-thin border-solid',
      },
      {
        variant: ButtonIconVariant.Header,
        device: 'tv',
        className: 'border-dt-border-none',
      },
      {
        isDisabled: false,
        variant: ButtonIconVariant.Header,
        device: 'tv',
        className: [
          'tv-focus-parent:bg-dt-theme-tv-background-button-icon-button-icon-focus',
          'tv-focus-parent:ring-fallback-4',
        ],
      },
      {
        isDisabled: false,
        isSelected: true,
        variant: ButtonIconVariant.Header,
        device: 'tv',
        className: 'ring-op50-4',
      },
      {
        variant: ButtonIconVariant.MediaOverlay,
        device: 'web',
        className: 'size-50',
      },
      {
        variant: ButtonIconVariant.MediaOverlay,
        device: 'tv',
        className: 'size-[6.25rem]',
      },
      {
        variant: ButtonIconVariant.MediaOverlayPvr,
        className: 'size-[2.3375rem]',
      },
    ],
  }
);

export const buttonIconSvgCVA = cva(undefined, {
  variants: {
    variant: {
      default: 'w-16',
      video: 'w-16',
      header: undefined,
      'media-overlay': 'w-2/3',
      'media-overlay-pvr': 'size-full',
    },
    iconColor: {
      neutral: undefined,
      positive: 'fill-dt-theme-icon-button-icon-mood-icon-good',
      negative: 'fill-dt-theme-icon-button-icon-mood-icon-bad',
    },
    device: {
      web: undefined,
      tv: undefined,
    },
    isSelected: {
      true: undefined,
      false: undefined,
    },
  },
  compoundVariants: [
    {
      variant: [
        ButtonIconVariant.MediaOverlay,
        ButtonIconVariant.MediaOverlayPvr,
      ],
      iconColor: 'neutral',
      className: 'fill-dt-theme-icon-episode-list-button-icon-icon',
    },
    {
      variant: [
        ButtonIconVariant.Default,
        ButtonIconVariant.Video,
        ButtonIconVariant.Header,
      ],
      iconColor: 'neutral',
      className: 'fill-dt-theme-icon-button-icon-mood-icon-neutral',
    },
    {
      variant: ButtonIconVariant.Header,
      iconColor: 'neutral',
      device: 'web',
      className:
        'group-hover:fill-dt-theme-icon-header-icon-active transition-colors duration-150',
    },
    {
      variant: ButtonIconVariant.Header,
      device: 'web',
      className: 'm-dt-spacing-50 w-[inherit]',
    },
    {
      isSelected: false,
      variant: ButtonIconVariant.Header,
      device: 'tv',
      className: 'size-38 tv-focus-parent:size-40',
    },
    {
      isSelected: true,
      variant: ButtonIconVariant.Header,
      device: 'tv',
      className: 'size-40',
    },
    {
      variant: ButtonIconVariant.Header,
      device: 'tv',
      className: 'tv-focus-parent:fill-dt-theme-tv-icon-button-icon-focus',
    },
  ],
});

export const buttonIconLabelCVA = cva('truncate text-center', {
  variants: {
    variant: {
      default: undefined,
      video: undefined,
      header: undefined,
      'media-overlay': undefined,
      'media-overlay-pvr': [
        'text-dt-theme-icon-episode-list-button-icon-icon uppercase ml-dt-spacing-200',
        'font-dt-font-family-brand text-dt-font-size-32 leading-dt-font-line-height-36 font-dt-font-weight-regular',
      ],
    },
    isDisabled: {
      true: undefined,
      false: undefined,
    },
    device: {
      tv: undefined,
      web: undefined,
    },
  },
  compoundVariants: [
    {
      variant: [
        ButtonIconVariant.Default,
        ButtonIconVariant.Video,
        ButtonIconVariant.MediaOverlay,
        ButtonIconVariant.Header,
      ],
      className: [
        'text-dt-theme-text-button-icon-button-icon mt-dt-spacing-100',
        'font-dt-font-family-system text-dt-font-size-12 leading-dt-font-line-height-16',
      ],
    },
    {
      isDisabled: false,
      variant: [
        ButtonIconVariant.Default,
        ButtonIconVariant.Video,
        ButtonIconVariant.MediaOverlay,
        ButtonIconVariant.Header,
      ],
      className: 'group-hover:text-dt-theme-text-button-icon-button-icon-hover',
    },
    {
      isDisabled: false,
      device: 'web',
      variant: ButtonIconVariant.Header,
      className: 'group-hover:text-dt-theme-icon-header-icon-active',
    },
    {
      isDisabled: true,
      variant: [
        ButtonIconVariant.Default,
        ButtonIconVariant.Video,
        ButtonIconVariant.Header,
        ButtonIconVariant.MediaOverlay,
      ],
      className: 'opacity-dt-opacity-40',
    },
    {
      variant: [
        ButtonIconVariant.Default,
        ButtonIconVariant.Video,
        ButtonIconVariant.MediaOverlay,
        ButtonIconVariant.Header,
      ],
      device: 'tv',
      className: 'w-100',
    },
    {
      variant: [
        ButtonIconVariant.Default,
        ButtonIconVariant.Video,
        ButtonIconVariant.MediaOverlay,
        ButtonIconVariant.Header,
      ],
      device: 'web',
      className: 'w-80',
    },
  ],
});
