import { MetasType } from '../types';

const Metas: MetasType = {
  app: {
    title: 'myCANAL',
    description:
      'Une nouvelle façon de regarder la télé. Retrouvez tous les meilleurs programmes sur myCANAL ! PC/MAC - Tablette - Smartphone.',
    head: {
      titleTemplate: '%s',
      twitter: {
        twitterAccount: '@myCANAL',
        urlImageDefault: 'https://www.canalplus.com/largetile.png',
      },
      meta: [
        {
          charset: 'utf-8',
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'myCANAL',
        },
        {
          name: 'application-name',
          content: 'myCANAL',
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black',
        },
        {
          name: 'theme-color',
          content: '#1A1A1A',
        },
        {
          name: 'msapplication-TileColor',
          content: '#1A1A1A',
        },
        {
          name: 'msapplication-square70x70logo',
          content: 'smalltile.png',
        },
        {
          name: 'msapplication-square150x150logo',
          content: 'mediumtile.png',
        },
        {
          name: 'msapplication-wide310x150logo',
          content: 'widetile.png',
        },
        {
          name: 'msapplication-square310x310logo',
          content: 'largetile.png',
        },
        {
          property: 'og:site_name',
          content: 'myCANAL',
        },
        {
          property: 'og:image',
          content: 'https://www.canalplus.com/largetile.png',
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:title',
          content: 'myCANAL',
        },
        {
          property: 'og:locale',
          content: 'fr-fr',
        },
        {
          property: 'og:description',
          content:
            'Une nouvelle façon de regarder la télé. Retrouvez tous les meilleurs programmes sur myCANAL ! PC/MAC - Tablette - Smartphone.',
        },
        {
          property: 'og:card',
          content: 'summary',
        },
        {
          property: 'og:site',
          content: '@myCANAL',
        },
        {
          property: 'og:creator',
          content: '@myCANAL',
        },
        {
          property: 'og:url',
          content: 'www.canalplus.com',
        },
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          href: '/apple-touch-icon.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: '/apple-touch-icon-120x120.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: '/apple-touch-icon-152x152.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon-180x180.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: '/apple-touch-icon-precomposed.png',
        },
        {
          rel: 'manifest',
          href: '/manifest.json',
        },
        {
          rel: 'icon',
          href: '/favicon-16x16.png',
          sizes: '16x16',
        },
        {
          rel: 'icon',
          href: '/favicon-32x32.png',
          sizes: '32x32',
        },
        {
          rel: 'apple-touch-startup-image',
          href: '/apple-touch-startup-image-1536x2008.png',
          media:
            '(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)',
        },
        {
          href: '/apple-touch-startup-image-1496x2048.png',
          media:
            '(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-768x1004.png',
          media:
            '(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-748x1024.png',
          media:
            '(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-1242x2148.png',
          media:
            '(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-1182x2208.png',
          media:
            '(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-750x1294.png',
          media:
            '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-640x1096.png',
          media:
            '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-640x920.png',
          media:
            '(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)',
          rel: 'apple-touch-startup-image',
        },
        {
          href: '/apple-touch-startup-image-320x640.png',
          media:
            '(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)',
          rel: 'apple-touch-startup-image',
        },
      ],
      defaultHostname: 'www.canalplus.com',
    },
  },
};

export default Metas;
