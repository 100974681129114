/**
 * Creates a random key for usage in react
 * @returns a react key
 */
export function randReactKey(): string {
  // collision is unlikely to occur (1 / 2^32) and will just throw a warning
  // uuid.v4() is 1 / 2^128 which is even more unlikely but weights 1.2kb and is slower
  // see: https://stackoverflow.com/a/31978831

  const [, tail] = Math.random().toString().split('.');

  if (typeof tail === 'string') {
    return tail;
  }

  throw new Error('randReactKey: random key is invalid');
}
