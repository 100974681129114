import {
  addBodyNoScroll,
  removeBodyNoScroll,
} from '@canalplus/mycanal-commons';
import { useEffect } from 'react';

type UseBodyNoScrollProps = {
  ignore?: boolean;
  suffix?: string;
};

/**
 * Add a noscroll class to the body to prevent scrolling. This is useful when a modal is open for example.
 * The same class is removed when the component unmounts.
 * @param ignore - If true, ignore the hook and do not add nor remove the noscroll class
 * @param suffix - A suffix to append to the noscroll classname (useful when multiple modals are open)
 */
export const useBodyNoScroll = ({
  ignore,
  suffix,
}: UseBodyNoScrollProps = {}): void => {
  useEffect(() => {
    // To be removed when tvod urba, creation of step TVOD
    if (ignore) {
      return;
    }

    addBodyNoScroll(suffix);
    return () => {
      removeBodyNoScroll(suffix);
    };
  }, [ignore, suffix]);
};
