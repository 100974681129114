import { IMyAccountIdpDataFormatted } from './types';

/**  `ApiV2PageMyAccount` is not exposed by hodor-types anymore */
const getFormaterMyAccountData = (data: any): IMyAccountIdpDataFormatted => {
  const { currentPage, strates, tracking } = data || {};

  return {
    displayName: currentPage?.displayName || '',
    strates,
    tracking,
  };
};

export default getFormaterMyAccountData;
