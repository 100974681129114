import { IUserStatus } from '@didomi/react';

export enum ActionType {
  HAS_DIDOMI_LOADED = 'HAS_DIDOMI_LOADED',
  HAS_DIDOMI_CONSENT_CHANGED = 'HAS_DIDOMI_CONSENT_CHANGED',
  SET_DIDOMI_CONSENT = 'SET_DIDOMI_CONSENT',
  SET_DIDOMI_IS_IN_IFRAME = 'SET_DIDOMI_IS_IN_IFRAME',
}

type ActionHasDidomiLoaded = {
  type: ActionType.HAS_DIDOMI_LOADED;
  payload: {
    hasLoaded: boolean;
  };
};

type ActionHasDidomiConsentChanged = {
  type: ActionType.HAS_DIDOMI_CONSENT_CHANGED;
  payload: {
    hasConsentChanged: boolean;
  };
};

type ActionSetDidomiConsent = {
  type: ActionType.SET_DIDOMI_CONSENT;
  payload: {
    consent: IUserStatus;
  };
};

type ActionSetDidomIsInIframe = {
  type: ActionType.SET_DIDOMI_IS_IN_IFRAME;
  payload: {
    isInIframe: boolean;
  };
};

export const hasDidomiLoaded = (hasLoaded: boolean): ActionHasDidomiLoaded => ({
  type: ActionType.HAS_DIDOMI_LOADED,
  payload: { hasLoaded },
});

export const hasDidomiConsentChanged = (
  hasConsentChanged: boolean
): ActionHasDidomiConsentChanged => ({
  type: ActionType.HAS_DIDOMI_CONSENT_CHANGED,
  payload: { hasConsentChanged },
});

export const setDidomiConsent = (
  consent: IUserStatus
): ActionSetDidomiConsent => ({
  type: ActionType.SET_DIDOMI_CONSENT,
  payload: { consent },
});

export const setDidomIsInIframe = (
  isInIframe: boolean
): ActionSetDidomIsInIframe => ({
  type: ActionType.SET_DIDOMI_IS_IN_IFRAME,
  payload: { isInIframe },
});

export type DidomiAction =
  | ActionHasDidomiLoaded
  | ActionHasDidomiConsentChanged
  | ActionSetDidomiConsent
  | ActionSetDidomIsInIframe;
