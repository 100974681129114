import { IMeta } from '@canalplus/mycanal-sdk';
import { Template } from '@canalplus/sdk-hodor';
import { AppKey } from '../../../config/application/types';
import type {
  I18nOneDiscoveryFunction,
  OneDiscoveryTranslationsStringPaths,
} from '../../lang';

export type BuildMetaParameter = {
  displayName?: string | null;
  displayTemplate?: string | null;
  routerPathname?: string;
  appKey?: AppKey;
  metaParams: IMeta;
  urlImage?: string;
};

export const buildMeta = ({
  displayName,
  displayTemplate,
  appKey,
  metaParams,
  urlImage,
}: BuildMetaParameter): IMeta => {
  const isDetailTemplate =
    displayTemplate === Template.DetailShow ||
    displayTemplate === Template.DetailPage ||
    displayTemplate === Template.DetailSeason;
  const isContentGrid = displayTemplate === Template.ContentGrid;

  // [CSR && SSR - case contentGrid]
  if (isContentGrid) {
    return { title: displayName || undefined, ...metaParams, urlImage };
  }

  // [CSR && SSR - case DetailPage, DetailSeason and DetailShow]
  /* /!\ see how work `metaTagsTranslator` function */
  if (isDetailTemplate) {
    const isTVod = appKey === 'canalvod';

    return {
      ...metaParams,
      title: `${metaParams?.title || displayName}${isTVod ? ` t(MetaTags.tvodTitle)` : ` t(MetaTags.title)`}`,
      urlImage,
    };
  }

  return { ...metaParams, urlImage };
};

/*
  `metaTagsTranslator` function must be called from a component,
   to be able to use the I18n => 't()' function
   - param => t: (title: string) => string
   - param => title: string => [Example of title: "The walking dead t(MetaTags.title)"]
*/
export const metaTagsTranslator = (
  t: I18nOneDiscoveryFunction,
  title: string
): string => {
  const metaTagsRegex = /t\((MetaTags\..*)\)/;

  // get first capturing group
  // cast metaTagsExtracted because we don't have any type guards
  const metaTagsExtracted = metaTagsRegex.exec(title)?.[1] as
    | OneDiscoveryTranslationsStringPaths
    | undefined;

  return metaTagsExtracted
    ? title.replace(metaTagsRegex, t(metaTagsExtracted))
    : title;
};

/** removes translated MetaTags.title in a string */
export const buildSharedTitle = (
  translatedTitle: string,
  t: I18nOneDiscoveryFunction
): string => {
  return !RegExp(t('MetaTags.title')).exec(translatedTitle)
    ? translatedTitle
    : translatedTitle.replace(`${t('MetaTags.title')} `, '');
};
