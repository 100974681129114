import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableSectionsListContainer = loadable(
  () => import('./components/SectionsListContainer'),
  {
    fallback: <Spinner />,
  }
);

export default LoadableSectionsListContainer;
