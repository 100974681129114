import { randReactKey } from './randKey';

export type IReactKey = {
  reactKey: string;
};

export type WithReactKey<T> = T & IReactKey;

/**
 * addUniqReactKey
 *
 * @description Add a `reactKey` property with a unique string to object in param
 * @param item An object
 * @returns A new object which contains reactKey property
 */
export function addUniqReactKey<T>(item: T): WithReactKey<T> {
  return {
    ...item,
    reactKey: randReactKey(),
  };
}
