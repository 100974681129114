import { useSelector } from 'react-redux';
import { HTML5_PLAYER } from '../../constants/playerTypes';
import { playerTypeSelector } from '../../store/slices/player-selectors';
import { HTML5Player } from './HTML5Player';

/**
 * Gets playerType in redux store and checks compatibility of HTML5 Player to display it
 * Be careful : only one PlayerContainer can be included in app each time !
 */
export function PlayerContainer(): JSX.Element | null {
  const playerType = useSelector(playerTypeSelector);
  return playerType === HTML5_PLAYER ? <HTML5Player /> : null;
}
